<template>
    <div
        v-if="item.users.length == 0 && item.watchers.length == 0"
    >
        <v-btn
          fab
          dark
          x-small
          color="red"
          @click="removeTask"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "taskPrivateRemove",
    props: {
        item: {}
    },
    inject: ["listState"],
    methods: {
        removeTask() {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .delete(`/api/tasks/${this.item.id}`)
                    .then(() => {
                        this.listState.reload();
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        }
    }
};
</script>
