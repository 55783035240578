<template>
    <responsible-list
        :users="item.users"
        :item="item"
        endpoint="api/user/responsibles/task"
    />
</template>

<script>
import ResponsibleList from "../resources/auctions/ResponsibleList";
export default {
    name: "taskResponsibles",
    components: { ResponsibleList },
    props: {
        item: {
            required: true
        }
    }
};
</script>
