<template>
    <va-edit-layout>
        <auction-statuses-form
            :id="id"
            :item="item"
            :title="title"
        ></auction-statuses-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
