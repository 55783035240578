<script>
export default {
    methods: {
        getResourceByStatusId(statusId) {
            let resourceName = "";
            switch (statusId) {
                case 1:
                    resourceName = "auction-new";
                    break;
                case 2:
                    resourceName = "auction-interest";
                    break;
                case 3:
                    resourceName = "auction-no-interest";
                    break;
                case 4:
                    resourceName = "auction-in-progress";
                    break;
                case 5:
                    resourceName = "auction-won";
                    break;
                case 6:
                    resourceName = "auction-lost";
                    break;
                case 7:
                    resourceName = "auction-processing";
                    break;
                case 8:
                    resourceName = "auction-canceled";
                    break;
                case 9:
                    resourceName = "auction-completed";
                    break;        
            }
            return resourceName;
        },
        getStatusIdByResource(statusId) {
            let resourceName = 0;
            switch (statusId) {
                case "auction-new":
                    resourceName = 1;
                    break;
                case "auction-interest":
                    resourceName = 2;
                    break;
                case "auction-no-interest":
                    resourceName = 3;
                    break;
                case "auction-in-progress":
                    resourceName = 4;
                    break;
                case "auction-won":
                    resourceName = 5;
                    break;
                case "auction-lost":
                    resourceName = 6;
                    break;
                case "auction-processing":
                    resourceName = 7;
                    break;
                case "auction-canceled":
                    resourceName = 8;
                    break;
                case "auction-completed":
                    resourceName = 9;
                    break;
            }
            return resourceName;
        },
        linkObject(item) {
            const text = item.category ? item.category.name : "";
            const resourceLink = this.$admin.getResourceLink({
                name: this.getResourceByStatusId(item.status_id),
                text: text,
                action: "show"
            });
            if (resourceLink) {
                return {
                    name: resourceLink.link.name,
                    params: {
                        id: item.id
                    }
                };
            }
            return false;
        }
    }
};
</script>
