<template>
    <div>
        <mb-field
            source="internal_number"
            v-if="item.internal_number"
            label="Numar intern"
        >
            <span class="text-body-2 font-weight-bold">
                {{ item.internal_number }}
            </span>
        </mb-field>
        <mb-field source="source">
            <span class="text-body-2 font-weight-bold">
                {{ item.source }}
            </span>
        </mb-field>
        <mb-field source="county"></mb-field>
        <mb-field source="original_created_at" type="date"></mb-field>
        <mb-field source="deadline_at" type="date"></mb-field>
        <mb-field source="estimated_value" class="green--text font-weight-bold">
            {{ item.estimated_value | formatNumber }}
            {{ item.currency }}
        </mb-field>
        <template v-if="item.external_url">
            <mb-field source="external_number">
                <a :href="item.external_url" target="_blank">
                    <span class="font-weight-bold pt-3 text-sm-body-2">
                        {{ item.external_number }}
                    </span>
                </a>
            </mb-field>
        </template>
        <mb-field source="interest">
            {{ item.interest.name }}
        </mb-field>
        <mb-field source="category">
            {{ item.category.name }}
        </mb-field>

        <v-divider class="mb-4" />

        <div v-for="(deadline, index) in item.deadlines" :key="index">
            <mb-field :label="deadline.name">
                <v-chip
                    :color="deadline.color"
                    small
                    :class="{
                        'text-decoration-line-through': deadline.deleted_at
                    }"
                >
                    {{ deadline.deadline_at | moment("L") }}
                    : {{ deadline.number_of_days }} zile
                </v-chip>
            </mb-field>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoDetails",
    props: {
        item: {}
    }
};
</script>
