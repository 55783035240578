<template>
    <v-dialog v-model="dialog" persistent width="300">
        <template v-slot:activator="{ on, attrs }">
            <div :style="swatchStyle" v-bind="attrs" v-on="on" />
        </template>

        <v-card>
            <v-toolbar color="primary" dark>
                Alege culoarea notitei
            </v-toolbar>
            <v-card-text>
                <v-color-picker
                    v-model="newColor"
                    flat
                    hide-mode-switch
                    mode="hexa"
                />

                <template v-if="errors">
                    <span
                        v-for="(error, index) in errors.body"
                        :key="index"
                        class="error pa-1 white--text rounded"
                        >{{ error }}
                    </span>
                </template>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn color="disabled" text @click="dialog = false">
                    Renunta
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    text
                    @click="saveColor"
                >
                    Salveaza
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "NoteColorIdentifier",
    props: {
        note: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            newColor: "",
            errors: {},
            loading: false
        };
    },
    computed: {
        swatchStyle() {
            return {
                backgroundColor: this.note.color,
                cursor: "pointer",
                height: "100%",
                width: "8px",
                borderRadius: "5px 0px 0px 5px",
                transition: "border-radius 200ms ease-in-out",
                position: "absolute",
                left: "0",
                top: "0",
                "z-index": "2"
            };
        }
    },
    methods: {
        saveColor() {
            this.loading = true;
            this.errors = {};
            this.$admin.http
                .patch(`/api/auction_notes/color/${this.note.id}`, {
                    color: this.newColor
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit("color-changed");
                    this.body = "";
                })
                .catch(error => {
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                this.newColor = this.note.color;
            }
        }
    },
    created() {
        this.newColor = this.note.color;
    }
};
</script>
