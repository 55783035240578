<template>
    <v-menu max-height="600" max-width="350" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on">
                <v-icon>mdi-bell</v-icon>
                <v-badge
                    :content="unreadNotifications.length"
                    :overlap="true"
                    :value="unreadNotifications.length"
                    color="pink"
                    icon="mdi-bell"
                ></v-badge>
            </v-btn>
        </template>
        <v-list dense nav>
            <template v-if="unreadNotifications.length > 0">
                <v-list-item
                    v-for="(item, index) in unreadNotifications"
                    :key="index"
                    @click="markAsReadNotification(item)"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            <div class="">
                                {{ item.data.message.split("By")[0] }}
                            </div>
                            <div
                                class="d-flex justify-space-between font-weight-light grey--text text-sm-caption mt-1"
                            >
                                <div>
                                    Autor:
                                    <strong>
                                        {{ item.data.message.split("By")[1] }}
                                    </strong>
                                </div>
                                <div>
                                    {{ item.created_at | moment("from") }}
                                </div>
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-else>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div>
                                Nu exista notificari noi
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-btn
                            :to="{ name: 'all-notification' }"
                            block
                            color="primary"
                            small
                            text
                        >
                            Vezi toate notificarile
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import Notifications from "@/mixins/Notifications";
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";

export default {
    name: "AppBarNotifications",

    mixins: [HasResourceSingleLink, Notifications],

    created() {
        this.getUnreadNotifications();
    },

    methods: {
        markAsReadNotification(item) {
            this.$admin.http
                .post(`/api/markAsRead-notification/${item.id}`)
                .then(({ data }) => {
                    if (data) {
                        this.$router.push(this.linkObject(data)).catch(() => {
                            this.getUnreadNotifications();
                        });
                    } else {
                        this.$router
                            .push({ name: "all-notification" })
                            .catch(() => {
                                this.getUnreadNotifications();
                            });
                    }
                });
        }
    }
};
</script>

<style scoped></style>
