<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" disable-export>
            <mb-data-table :fields="fields" disable-select></mb-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: ["vat", "county", "reg_number", "city"],
            fields: [
                { source: "vat", sortable: true },
                { source: "county", sortable: true },
                { source: "reg_number", sortable: true },
                { source: "web", sortable: true },
                { source: "phone", sortable: true },
                { source: "address", sortable: true },
                { source: "zip", sortable: true },
                { source: "fax", sortable: true },
                { source: "name", sortable: true },
                { source: "city", sortable: true },
                { source: "email", sortable: true }
            ]
        };
    }
};
</script>
