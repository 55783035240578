<template>
        <base-material-card
            class="my-0"
            height="100%"
            :color="selected.length ? 'grey darken-3' : 'primary'"
        >
            <template 
                v-slot:heading
            >
                <div class="row" style="min-height: 65px;">
                    <div class="col-1" v-if="selected.length">
                        <v-btn
                        icon
                        @click="selected = []"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-2 col-sm-11 col-xs-11">
                        <div 
                            class="text-h3 pt-1"
                            :class="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'text-center' : ''"
                        >
                            {{ selected.length ? `${selected.length} selectate` : 'Notificari' }}
                        </div>
                    </div>
                    <div 
                        class="col-md-9 col-sm-12"
                        :class="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'text-center' : 'text-right'"
                    >
                        <v-btn
                          :class="$vuetify.breakpoint.name !== 'xs' ? 'mx-2' : ''"
                          :block="$vuetify.breakpoint.name === 'xs'"
                          v-if="selected.length"
                          dark
                          color="success"
                          @click="markAsRead"
                        >
                          <v-icon dark>
                            mdi-email-open
                          </v-icon>
                          <span class="ml-2">
                              Marcheaza ca citite
                          </span>
                        </v-btn>
                        <v-btn
                          :class="$vuetify.breakpoint.name === 'xs' ? 'mt-2' : 'mx-2'"
                          :block="$vuetify.breakpoint.name === 'xs'"
                          v-if="selected.length"
                          dark
                          color="red"
                          @click="markAsUnread"
                        >
                          <v-icon dark>
                            mdi-email-mark-as-unread
                          </v-icon>
                          <span class="ml-2">
                              Marcheaza ca necitite
                          </span>
                        </v-btn>
                    </div>
                </div>
            </template>
            <v-card-text>
                <v-card :loading="loading" class="mx-auto ">
        <p
            v-if="notifications.length === 0"
            style="text-align:center;padding-top: 8px;"
        >
            Nici o notificare
        </p>
        <v-list two-line>
            <v-list-item-group>
                <template v-for="(item, index) in notifications">
                    <v-list-item
                        :key="item.title"
                        :class="{ 'blue lighten-5': !item.read_at }"
                    >
                        <template>
                            <v-list-item-action>
                              <v-checkbox
                                :value="item.id"
                                v-model="selected[index]"
                                @change="checkValue"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content
                                link
                                @click="markAsReadNotification(item)"
                            >
                                <div>
                                    <strong
                                        v-if="!item.read_at"
                                        class="primary--text"
                                        >NOU!</strong
                                    >
                                    {{ item.data.message.split("By")[0] }}
                                </div>
                                <div
                                    class="font-weight-light grey--text text-sm-caption mt-1"
                                >
                                    Autor
                                    <strong>
                                        {{ item.data.message.split("By")[1] }}
                                    </strong>
                                </div>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <p>
                                        {{ item.created_at | moment("from") }}
                                    </p></v-list-item-action-text
                                >
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                    <v-divider
                        v-if="index < notifications.length - 1"
                        :key="index"
                    ></v-divider>
                </template>
                <v-btn
                    v-if="next <= total"
                    block
                    color="primary"
                    class="mt-2"
                    :loading="moreLoading"
                    :disabled="moreLoading"
                    @click="moreNotifications"
                >
                    Mai multe
                </v-btn>
            </v-list-item-group>
        </v-list>
    </v-card>
            </v-card-text>
        </base-material-card>
</template>

<script>
import Notifications from "@/mixins/Notifications";
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";

export default {
    mixins: [HasResourceSingleLink, Notifications],
    data: () => ({
        loading: false,
        moreLoading: false,
        next: 10,
        total: 0,
        notifications: [],
        selected: []
    }),
    methods: {
        getNotification() {
            this.$admin.http
                .get(`/api/get-all-notification?next=${this.next}`)
                .then(({ data }) => {
                    this.notifications = data.data;
                    this.loading = false;
                    this.moreLoading = false;
                });
        },
        countNotifications() {
            this.$admin.http
                .get(`/api/get-all-notification?counting=true`)
                .then(({ data }) => {
                    this.total = data.count;
                });
        },  
        moreNotifications() {
            this.next += 10;
            this.moreLoading = true;

            this.getNotification();
        },
        markAsReadNotification(item) {
            this.$admin.http
                .post(`/api/markAsRead-notification/${item.id}`)
                .then(({ data }) => {
                    if (data) {
                        this.$router.push(this.linkObject(data)).catch(() => {
                            this.getUnreadNotifications();
                            this.getNotificationTree();
                        });
                    } else {
                        this.$router
                            .push({ name: "all-notification" })
                            .catch(() => {
                                this.getNotification();
                            });
                    }
                });
        },
        checkValue() {
            if(this.selected.every((currentValue) => currentValue === null)) {
                this.selected = []
            }
        },
        markAsRead() {
            this.$admin.http
                .post(`/api/markAsRead-notifications`, { notification_uuid: this.selected })
                .then(() => {
                    this.selected = []
                    this.loading = true
                    this.getNotification()
                    this.getUnreadNotifications()
                    this.getNotificationTree()
                });
        },
        markAsUnread() {
            this.$admin.http
                .post(`/api/markAsUnread-notifications`, { notification_uuid: this.selected })
                .then(() => {
                    this.selected = []
                    this.loading = true
                    this.getNotification()
                    this.getUnreadNotifications()
                    this.getNotificationTree()
                });
        }
    },
    created() {
        this.loading = true;
        this.countNotifications();
        this.getNotification();
    }
};
</script>
