<template>
    <div>
        <v-dialog v-model="confirmdialog" persistent max-width="290">
            <v-card>
                <v-card-text>
                    {{ $t("dashboard.deadline_dialog_question") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="confirmdialog = false"
                    >
                        {{ $t("dashboard.deadline_dialog_no") }}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteDeadline">
                        {{ $t("dashboard.deadline_dialog_yes") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tooltip bottom max-width="250px">
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    v-on="on"
                    :class="{ deleted: deadline.deleted_at }"
                    :close="!deadline.deleted_at"
                    :color="deadline.color"
                    @click="openDialog"
                    @click:close="openDialogConfirm"
                >
                    # {{ deadline.auction.internal_number }}
                </v-chip>
            </template>
            <span>
                {{ deadline.auction.title }}
            </span>
        </v-tooltip>
        <v-dialog v-model="dialog" scrollable width="800px">
            <v-card>
                <v-card-title class="font-weight-bold pb-3">
                    {{ auction.title }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-simple-table>
                                <tbody>
                                    <tr>
                                        <td>Numar intern</td>
                                        <td class="font-weight-bold">
                                            {{ auction.internal_number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Autoritate Contractanta</td>
                                        <td>
                                            {{ auction.customer.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sursa</td>
                                        <td>{{ auction.source }}</td>
                                    </tr>
                                    <tr>
                                        <td>Judet</td>
                                        <td>{{ auction.county }}</td>
                                    </tr>
                                    <tr>
                                        <td>Data aparitie</td>
                                        <td>
                                            {{
                                                auction.original_created_at
                                                    | moment("L")
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Data Limita</td>
                                        <td>
                                            {{
                                                auction.deadline_at
                                                    | moment("L")
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Valoare estimata</td>
                                        <td
                                            class="green--text font-weight-bold"
                                        >
                                            {{
                                                auction.estimated_value
                                                    | formatNumber
                                            }}
                                            {{ auction.currency }}
                                        </td>
                                    </tr>
                                    <tr v-if="auction.external_url">
                                        <td>Cod licitatie</td>
                                        <td class="">
                                            <a
                                                :href="auction.external_url"
                                                target="_blank"
                                            >
                                                <span
                                                    class="font-weight-bold pt-3 text-sm-body-2"
                                                >
                                                    {{
                                                        auction.external_number
                                                    }}
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Obiectul licitatiei</td>
                                        <td class="">
                                            {{ auction.interest.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Categorie</td>
                                        <td class="">
                                            {{ auction.category.name }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-tabs v-model="tab">
                                <v-tab
                                    v-for="(batch, index) in batches"
                                    :key="index"
                                    class="text-sm-body-2"
                                >
                                    <batch-tab-item
                                        :auction-id="auction.id"
                                        :batch="batch"
                                        @batch-renamed="loadBatches"
                                    />
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    v-for="(batch, index) in batches"
                                    :key="index"
                                >
                                    <Notes
                                        ref="notes"
                                        :auction-id="auction.id"
                                        :batch-id="batch.id"
                                    />
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false" small>
                        Inchide
                    </v-btn>
                    <v-spacer />
                    <v-btn :to="linkObject(auction)" small color="primary">
                        Vezi licitatia
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";
import BatchTabItem from "../resources/auctions/BatchTabItem";
import Notes from "@/components/resources/auctions/Notes/Notes";

export default {
    name: "SingleDeadlineItem",
    components: { Notes, BatchTabItem },
    mixins: [HasResourceSingleLink],
    props: {
        deadline: {
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            confirmdialog: false,
            batches: [],
            tab: 0
        };
    },
    computed: {
        auction() {
            return this.deadline.auction;
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        openDialogConfirm() {
            this.confirmdialog = true;
        },
        deleteDeadline() {
            this.confirmdialog = false;
            this.$admin.http
                .delete(`/api/auction-deadlines/${this.deadline.id}`)
                .then(() => {
                    this.$emit("reload");
                });
        },
        loadBatches() {
            return this.$admin.http
                .get(`/api/auction/${this.auction.id}/batches`)
                .then(({ data }) => {
                    this.batches = data;
                });
        }
    },
    mounted() {
        this.loadBatches();
    }
};
</script>

<style scoped>
.deleted {
    text-decoration: line-through;
}
.modal-close-button {
    background-color: transparent !important;
}
.modal-close-button:before {
    content: none;
}
.modal-close-button .v-icon {
    top: 0;
    right: 0;
}
</style>
