<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                    <template v-if="item.department">
                        <span class="text-body-2">
                            {{ item.department.name }}
                        </span>
                    </template>
                    <template v-else>
                        <span
                            class="text-decoration-underline text-body-2"
                            style="word-break: initial"
                            >Alege departament</span
                        >
                    </template>
                </span>
            </template>
            <v-list dense>
                <v-list-item-group>
                    <v-list-item
                        v-for="(department, index) in departments"
                        :key="index"
                        @click="setDepartment(department)"
                    >
                        <v-list-item-title>
                            {{ department.value }}
                        </v-list-item-title>
                        <v-list-item-iconz
                            v-if="department.key == 0"
                            class="mr-0"
                        >
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-list-item-iconz>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    inject: {
        listState: { default: undefined }
    },
    props: {
        item: {}
    },
    computed: {
        departments() {
            let departments = Object.values(this.$store.getters["departments"]);

            departments.unshift({
                key: 0,
                value: "Inlatura"
            });

            return departments;
        }
    },
    methods: {
        setDepartment(value) {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .patch(`/api/tasks/${this.item.id}`, {
                        department_id: value.key == 0 ? null : value.key
                    })
                    .then(() => {
                        this.listState.reload();
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        }
    }
};
</script>
