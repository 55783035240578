<template>
    <div @mouseleave="hover = false" @mouseover="hover = true">
        <router-link :to="getProperShowRoute(item)" class="auction-title">
            <div v-show="hover" class="mb-tooltip">
                {{ item.title }}
            </div>
            <div class="mb-2">
                Client:
                <span class="font-weight-bold" style="color:#555;">
                    {{ item.customer.name | capitalize }}
                </span>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "TitleTooltip",
    props: {
        item: {
            required: true
        }
    },
    data() {
        return {
            hover: false
        };
    },
    methods: {
        getProperShowRoute(item) {
            const resourceName = this.getResourceByStatusId(item.status_id);
            const name = `${resourceName}_show`;
            return {
                name: name,
                params: { id: item.id }
            };
        },
        getResourceByStatusId(statusId) {
            let resourceName = "";
            switch (statusId) {
                case 1:
                    resourceName = "auction-new";
                    break;
                case 2:
                    resourceName = "auction-interest";
                    break;
                case 3:
                    resourceName = "auction-no-interest";
                    break;
                case 4:
                    resourceName = "auction-in-progress";
                    break;
                case 5:
                    resourceName = "auction-won";
                    break;
                case 6:
                    resourceName = "auction-lost";
                    break;
                case 7:
                    resourceName = "auction-processing";
                    break;
            }
            return resourceName;
        }
    }
};
</script>

<style scoped>
.mb-tooltip {
    position: absolute;
    max-width: 350px;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transform: translatey(-50%);
    color: #333333;
}
</style>
