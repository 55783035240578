<template>
    <div>
        <v-list color="transparent" dense>
            <open-checklist-item
                v-for="(openedChecklistItem, index) in openedChecklistItems"
                :key="openedChecklistItem.id"
                :first="index === 0"
                :item="openedChecklistItem"
                :last="index === openedChecklistItems.length - 1"
                @update-checklist-item="loadChecklistItems"
                :editable="canBeEdited"
            />

            <create-checklist-item
                v-if="canBeEdited"
                :task="item"
                @created="loadChecklistItems"
            />
        </v-list>
        <div class="grey--text text-body-2 text-left pa-2">
            <span class="d-block" style="border-bottom: 1px solid #E9E9E9;">
                Subtask-uri finalizate
            </span>

            <v-list color="transparent" dense>
                <closed-checklist-item
                    v-for="closedChecklistItem in closedChecklistItems"
                    :key="closedChecklistItem.id"
                    :item="closedChecklistItem"
                    @update-checklist-item="loadChecklistItems"
                    :editable="canBeEdited"
                />
            </v-list>
        </div>
    </div>
</template>

<script>
import CreateChecklistItem from "@/components/tasks/Checklist/Create";
import OpenChecklistItem from "@/components/tasks/Checklist/OpenChecklistItem";
import ClosedChecklistItem from "@/components/tasks/Checklist/ClosedChecklistItem";

export default {
    name: "taskChecklist",
    components: {
        ClosedChecklistItem,
        OpenChecklistItem,
        CreateChecklistItem
    },
    props: {
        item: {}
    },
    data() {
        return {
            openedChecklistItems: [],
            closedChecklistItems: []
        };
    },
    methods: {
        loadChecklistItems() {
            this.loadOpenedChecklistItems();
            this.loadClosedChecklistItems();
        },
        loadOpenedChecklistItems() {
            this.$admin.http
                .get(
                    `api/task-checklist-items?filter[opened]=true&filter[task_id]=${this.item.id}&sort=display_order`
                )
                .then(({ data }) => {
                    this.openedChecklistItems = data.data;
                });
        },
        loadClosedChecklistItems() {
            this.$admin.http
                .get(
                    `api/task-checklist-items?filter[closed]=true&filter[task_id]=${this.item.id}&sort=-updated_at`
                )
                .then(({ data }) => {
                    this.closedChecklistItems = data.data;
                });
        }
    },
    computed: {
        canBeEdited() {
            return this.item.user_id === this.$store.getters.user.id;
        }
    },
    mounted() {
        this.loadChecklistItems();
    }
};
</script>
