<template>
    <v-dialog v-model="dialog" max-width="85%" scrollable>
        <template v-slot:activator="{ on, attrs }">
            <span class="title-column" v-bind="attrs" v-on="on">
                <span class="text-body-2 font-weight-bold mb-1 d-block">
                    {{ item.title }}
                </span>
                <template v-if="item.checklist_items_count > 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-progress-linear
                                background-color="#e8e8e8"
                                color="#4CAF50"
                                height="6"
                                rounded
                                style="border: 1px solid #909090;"
                                v-bind="attrs"
                                :value="checklistProgress"
                                v-on="on"
                            ></v-progress-linear>
                        </template>
                        <span>
                            Subtask-uri:
                            {{ item.completed_checklist_items_count }}/{{
                                item.checklist_items_count
                            }}
                        </span>
                    </v-tooltip>
                </template>
            </span>
        </template>

        <v-card class="overflow-hidden task-dialog" style="border: none;">
            <v-card-title class="pa-0">
                <div class="pa-3">
                    <v-row>
                        <v-card-title
                            class="primary white--text pa-3 font-weight-bold d-flex"
                        >
                            <v-textarea
                                v-model="task.title"
                                :auto-grow="true"
                                class="font-weight-bold no-border-bottom"
                                dark
                                dense
                                hide-details
                                rows="1"
                            ></v-textarea>
                            <v-spacer class="col-1" />
                            <v-btn dark icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-row>
                    <v-row>
                        <v-col class="left-col-header" cols="12" md="4">
                            <v-row>
                                <v-col class="d-flex">
                                    <task-priority
                                        :isIcon="false"
                                        :item="item"
                                    />
                                    <task-department
                                        :item="item"
                                        class="ml-3"
                                    />
                                </v-col>
                                <div
                                    class="d-flex justify-end align-center pa-3"
                                >
                                    <task-responsibles
                                        :item="item"
                                        class="ml-2"
                                    />
                                    <task-deadline
                                        :item="item"
                                        class="ml-3"
                                        style="position: relative"
                                    />
                                </div>
                            </v-row>
                        </v-col>
                        <v-col
                            class="center-col-header d-md-flex align-center hidden-sm-and-down"
                        >
                            <p class="text-left text-body-2 mb-0">
                                SUBTASK-URI:
                            </p>
                        </v-col>
                        <v-col
                            class="right-col-header d-md-flex align-center"
                            cols="12"
                            md="4"
                        >
                            <v-row>
                                <v-col class="grey--text text-body-2 text-left">
                                    Task creat la data <br />
                                    {{ item.created_at | moment("LLL") }}
                                </v-col>
                                <v-col
                                    class="d-flex justify-end align-items-center"
                                >
                                    <v-chip
                                        v-if="commentsLoaded"
                                        class="elevation-4 grey lighten-4"
                                    >{{ item.user_comments_count }}
                                        comentarii
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card-title>
            <v-card-text class="pa-0 task-content custom-scrollbar">
                <div class="pa-3">
                    <v-row>
                        <v-col
                            class="left-col d-flex flex-column pa-0"
                            cols="12"
                            md="4"
                        >
                            <v-textarea
                                v-model="task.description"
                                :auto-grow="true"
                                class="overflow-y-auto custom-scrollbar px-3 mt-3 pt-1"
                                dense
                                hide-details
                                label="Descriere"
                                outlined
                            />

                            <v-select
                                v-if="item.schedule"
                                v-model="schedule"
                                :items="schedules"
                                item-text="value"
                                item-value="key"
                                label="Selecteaza recurenta"
                                outlined
                                @input="scheduleTask"
                            ></v-select>

                            <div class="ma-3">
                                <v-btn
                                    :disabled="loading"
                                    :loading="loading"
                                    color="#4CAF50"
                                    width="100%"
                                    @click="updateTask"
                                >
                                    Salveaza modificarile
                                </v-btn>
                            </div>

                            <div class="hidden-md-and-up">
                                <task-checklist :item="item" />
                            </div>
                        </v-col>
                        <v-col
                            class="center-col pa-1 overflow-y-auto custom-scrollbar hidden-sm-and-down"
                        >
                            <task-checklist :item="item" />
                        </v-col>
                        <v-col
                            class="right-col pb-0 px-0 d-flex flex-column justify-space-between"
                            cols="12"
                            md="4"
                        >
                            <template v-if="!commentsLoaded">
                                <div class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    ref="commentsContainer"
                                    class="custom-scrollbar pa-3 comments-container"
                                >
                                    <div>
                                        <v-row
                                            v-for="(comment, index) in comments"
                                            :key="index"
                                        >
                                            <template v-if="comment.is_system">
                                                <v-col
                                                    class="d-flex grey--text text-body-2"
                                                >
                                                    {{ comment.body }}
                                                    <v-spacer></v-spacer>
                                                    {{
                                                        comment.created_at
                                                            | moment("LLL")
                                                    }}
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col
                                                    class="comment-item"
                                                    style="white-space: pre-line;"
                                                >
                                                    <strong
                                                        class="text-body-2 font-weight-bold"
                                                    >{{ comment.user.name }}
                                                    </strong>
                                                    <i class="text-body-2">
                                                        (
                                                        {{
                                                            comment.created_at
                                                                | moment("LLL")
                                                        }}
                                                        )
                                                    </i>
                                                    <v-btn
                                                        v-if="
                                                            comment.user
                                                                .email ===
                                                                user.email &&
                                                                !is_editing
                                                        "
                                                        class="mx-2"
                                                        color="secondary"
                                                        dark
                                                        fab
                                                        style="float: right;"
                                                        x-small
                                                        @click="
                                                            editComment(comment)
                                                        "
                                                    >
                                                        <v-icon dark>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="
                                                            comment.user
                                                                .email ===
                                                                user.email &&
                                                                is_editing
                                                        "
                                                        class="mx-2"
                                                        color="secondary"
                                                        dark
                                                        fab
                                                        style="float: right;"
                                                        x-small
                                                        @click="
                                                            closeEditComment(
                                                                comment
                                                            )
                                                        "
                                                    >
                                                        <v-icon dark>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                    <div class="mt-2">
                                                        {{ comment.body }}
                                                    </div>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </div>
                                </div>
                            </template>
                            <div class="right-col-footer pa-0">
                                <form
                                    class="d-flex"
                                    @submit.prevent="formComment"
                                >
                                    <div
                                        class="flex-grow-1"
                                        style="position: relative"
                                    >
                                        <textarea
                                            ref="commentBodyArea"
                                            v-model="newBody"
                                            class="grey--text"
                                            placeholder="Scrie comentariul aici..."
                                            rows="2"
                                            style="width: 100%"
                                        ></textarea>
                                        <error-message
                                            v-for="(error,
                                            index) in errors.body"
                                            :key="index"
                                            :message="error"
                                            style="position: absolute; bottom: 17px; width: 100%;"
                                        />
                                    </div>

                                    <v-btn
                                        :disabled="loadingComments"
                                        :loading="loadingComments"
                                        class="submit-bottom"
                                        color="#4CAF50"
                                        elevation="0"
                                        type="submit"
                                    >
                                        <v-icon>fas fa-paper-plane</v-icon>
                                    </v-btn>
                                </form>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TaskDepartment from "@/components/tasks/taskDepartment";
import TaskPriority from "@/components/tasks/taskPriority";
import TaskDeadline from "@/components/tasks/taskDeadline";
import TaskResponsibles from "@/components/tasks/taskResponsibles";
import ErrorMessage from "../base/ErrorMessage";
import TaskChecklist from "./Checklist/taskChecklist";

export default {
    components: {
        TaskChecklist,
        ErrorMessage,
        TaskResponsibles,
        TaskDeadline,
        TaskPriority,
        TaskDepartment
    },
    props: {
        item: {}
    },
    data() {
        return {
            task: {
                title: "",
                description: ""
            },
            schedule: this.item.scheduled_value,
            schedules: [
                {
                    key: false,
                    value: "Nici o recurenta"
                },
                {
                    key: "every_day",
                    value: "Zilnic"
                },
                {
                    key: "every_month",
                    value: "Lunar"
                },
                {
                    key: "every_year",
                    value: "Anual"
                }
            ],
            newBody: "",
            dialog: false,
            commentsLoaded: false,
            comments: {},
            loading: false,
            loadingComments: false,
            is_editing: false,
            editingComment: {},
            errors: {}
        };
    },
    computed: {
        ...mapGetters(["user"]),
        checklistProgress(){

            return this.item.completed_checklist_items_count * 100/ this.item.checklist_items_count;

        }
    },
    methods: {
        loadComments() {
            this.$admin.http
                .get(
                    `api/task-comments?filter[task]=${this.item.id}&include=user`
                )
                .then(({ data }) => {
                    this.comments = data.data;
                    this.commentsLoaded = true;
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.scrollToEnd();
                    });
                });
        },
        formComment() {
            if (this.is_editing) {
                this.updateComment();
            } else {
                this.createNewComment();
            }
        },
        createNewComment() {
            this.loadingComments = true;
            this.errors = {};
            this.$admin.http
                .post(`api/task-comments`, {
                    task_id: this.item.id,
                    body: this.newBody
                })
                .then(() => {
                    this.newBody = "";
                    this.$store.dispatch(
                        "api/refresh",
                        this.$route.meta.resource
                    );
                    this.loadComments();
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.errors = error.errors;
                    } else {
                        console.log(error.message);
                    }
                })
                .finally(() => {
                    this.loadingComments = false;
                });
        },
        updateComment() {
            this.loadingComments = true;
            this.errors = {};
            this.$admin.http
                .put(`api/task-comments/${this.editingComment.id}`, {
                    task_id: this.item.id,
                    body: this.newBody
                })
                .then(() => {
                    this.is_editing = false;
                    this.editingComment = {};
                    this.newBody = "";
                    this.$store.dispatch(
                        "api/refresh",
                        this.$route.meta.resource
                    );
                    this.loadComments();
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.errors = error.errors;
                    } else {
                        console.log(error.message);
                    }
                })
                .finally(() => {
                    this.loadingComments = false;
                });
        },
        editComment(comment) {
            if (!this.is_editing) {
                this.is_editing = true;
                this.$refs.commentBodyArea.focus();
                this.editingComment = comment;
                this.newBody = comment.body;
            }
        },
        closeEditComment() {
            if (this.is_editing) {
                this.is_editing = false;
                this.editingComment = {};
                this.newBody = "";
            }
        },
        updateTask() {
            this.loading = true;
            new Promise((resolve, reject) => {
                this.$admin.http
                    .patch(`/api/tasks/${this.item.id}`, this.task)
                    .then(() => {
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        scheduleTask() {
            this.loading = true;
            new Promise((resolve, reject) => {
                this.$admin.http
                    .patch(`/api/tasks/schedule-task/${this.item.id}`, {
                        schedule: this.schedule
                    })
                    .then(() => {
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        scrollToEnd() {
            var commentsContainer = this.$refs.commentsContainer;
            commentsContainer.scrollTop = commentsContainer.scrollHeight;
        },
        openDialog() {
            this.dialog = true;
        }
    },
    watch: {
        dialog(newValue) {
            this.task.title = this.item.title;
            this.task.description = this.item.description;
            if (newValue && !this.commentsLoaded) {
                this.loadComments();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.task-dialog {
    border-bottom: 1px solid #e9e9e9;

    ::v-deep .v-icon {
        position: inherit !important;
        top: inherit !important;
        left: inherit !important;
        right: inherit !important;
        opacity: 1 !important;
        color: inherit !important;
    }
}

.task-content {
    @media screen and (min-width: 960px) {
        overflow-y: hidden !important;
    }
}

.comments-container {
    margin-bottom: 10px;
    @media screen and (min-width: 960px) {
        height: calc(100% - 75px);
        overflow-y: auto;
        margin-bottom: 0;
    }
}

.right-col-header {
    background: rgba(#bdbdbd, 0.07);
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    .comment-item {
        background: #efefef;
        border-radius: 5px;
        margin: 5px 12px;
    }
}

.right-col {
    background: rgba(#bdbdbd, 0.07);
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    .comment-item {
        background: #efefef;
        border-radius: 5px;
        margin: 5px 12px;
    }

    @media screen and (min-width: 960px) {
        height: 500px;
    }
}

.right-col-footer {
    //background: rgba(#bdbdbd, 0.07);
    border-left: 1px solid #e9e9e9;
    border-top: 1px solid #e9e9e9;
    height: 72px;

    textarea {
        padding: 12px;
        height: 72px;

        &:focus {
            outline: none;
        }

        &::-webkit-input-placeholder {
            color: #cecece;
            line-height: 48px;
        }

        &:-moz-placeholder {
            color: #cecece;
            line-height: 48px;
        }

        &::-moz-placeholder {
            color: #cecece;
            line-height: 48px;
        }

        &:-ms-input-placeholder {
            color: #cecece;
            line-height: 48px;
        }
    }

    .submit-bottom {
        border-radius: 0 0 4px 0;
        height: 72px;
    }
}

.left-col-header {
    border-bottom: 1px solid #e9e9e9;
}

.left-col {
    border-bottom: 1px solid #e9e9e9;
    @media screen and (min-width: 960px) {
        height: 500px;
    }
}

.center-col-header {
    background: rgba(#bdbdbd, 0.07);
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}

.center-col {
    background: rgba(#bdbdbd, 0.07);
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    @media screen and (min-width: 960px) {
        max-height: 500px;
    }
}

.left-col-footer {
    background: #fff;
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<style lang="scss">
.no-border-bottom {
    .v-input__slot {
        &:before {
            content: none !important;
        }
    }
}

.custom-scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }
}

.checkbox-item {
    &__textarea {
        margin-top: 0;

        textarea {
            line-height: 20px;
            min-height: 24px;

            &::-webkit-input-placeholder {
                color: #616161 !important;
                //text-decoration: underline;
            }

            &:-ms-input-placeholder {
                color: #616161 !important;
                //text-decoration: underline;
            }

            &::placeholder {
                color: #616161 !important;
                //text-decoration: underline;
            }
        }
    }
}
</style>
