<template>
    <v-row>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionNewResourceLink.link"
                :title="`${stats.new.all} total`"
                :value="`${stats.new.today} azi`"
                color="primary"
                icon="fal fa-star-exclamation"
                sub-text="Licitatii noi"
            />
        </v-col>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionInterestResourceLink.link"
                :title="`${stats.interest.all} total`"
                :value="`${stats.interest.today} azi`"
                color="warning"
                icon="fal fa-sparkles"
                sub-text="Licitatii de interes"
            />
        </v-col>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionProcessingResourceLink.link"
                :title="`${stats.processing.all} total`"
                :value="`${stats.processing.today} azi`"
                color="success"
                icon="fal fa-users-cog"
                sub-text="Licitatii in procesare"
            />
        </v-col>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionInProgressResourceLink.link"
                :title="`${stats.inProgress.all} total`"
                :value="`${stats.inProgress.today} azi`"
                color="warning"
                icon="fal fa-play"
                sub-text="Licitatii depuse"
            />
        </v-col>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionWonResourceLink.link"
                :title="`${stats.won.all} total`"
                :value="`${stats.won.today} azi`"
                color="success"
                icon="fal fa-trophy-alt"
                sub-text="Licitatii Castigate"
            />
        </v-col>
        <v-col cols="6" lg="2" md="4" sm="4">
            <base-material-stats-card
                :loading="loading"
                :sub-link="auctionLostResourceLink.link"
                :title="`${stats.lost.all} total`"
                :value="`${stats.lost.today} azi`"
                color="error"
                icon="fal fa-skull-crossbones"
                sub-text="Licitatii Pierdute"
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "AllAuctionStatsByStatus",
    data() {
        return {
            loading: false,
            stats: {
                new: {
                    today: 0,
                    all: 0
                },
                interest: {
                    today: 0,
                    all: 0
                },
                inProgress: {
                    today: 0,
                    all: 0
                },
                won: {
                    today: 0,
                    all: 0
                },
                lost: {
                    today: 0,
                    all: 0
                },
                processing: {
                    today: 0,
                    all: 0
                }
            }
        };
    },
    computed: {
        auctionNewResourceLink() {
            return this.$admin.getResourceLink("auction-new");
        },
        auctionInterestResourceLink() {
            return this.$admin.getResourceLink("auction-interest");
        },
        auctionInProgressResourceLink() {
            return this.$admin.getResourceLink("auction-in-progress");
        },
        auctionWonResourceLink() {
            return this.$admin.getResourceLink("auction-won");
        },
        auctionLostResourceLink() {
            return this.$admin.getResourceLink("auction-lost");
        },
        auctionProcessingResourceLink() {
            return this.$admin.getResourceLink("auction-processing");
        }
    },
    created() {
        this.loading = true;
        this.$admin.http.get("api/auction/stats-by-status").then(({ data }) => {
            this.stats = data;
            this.loading = false;
        });
    }
};
</script>
