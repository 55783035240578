<template>
    <v-select
        style="max-width: 250px"
        v-model="sort"
        :items="items"
        item-text="name"
        item-value="id"
        label="Alege Sortare dupa Termen"
        hide-details
    >
    </v-select>
</template>

<script>
export default {
    name: "DeadlineTypeSelect",
    inject: {
        listState: { default: undefined }
    },
    data() {
        return {
            sort: "",
            items: [],
            defaultItems: [
                {
                    name: "Nesortat",
                    id: 0
                }
            ]
        };
    },
    watch: {
        sort(value) {
            if (value === 0) {
                this.listState.options.sortBy = [];
            } else {
                const sort = "deadline-type-" + value;
                this.listState.options.sortBy = [sort];
            }
            this.listState.reload();
        }
    },
    mounted() {
        this.$admin.http.get("api/deadlines-schema").then(({ data }) => {
            const items = data.data;
            this.items = [...this.defaultItems, ...items];
        });
    }
};
</script>

<style scoped></style>
