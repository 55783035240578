<template>
    <v-row>
        <v-col>
            <base-material-card
                title="Licitatii cu deadline in urmatoarele 7 zile"
            >
                <va-list
                    :filters="filters"
                    :include="[
                        'category',
                        'status',
                        'interest',
                        'customer',
                        'deadlines'
                    ]"
                    resource="auction-for-dashboard"
                >
                    <mb-data-table
                        :dense="false"
                        :fields="fields"
                        :item-class="itemRowBackground"
                        class="overflow-auto"
                        disable-select
                    >
                        <template v-slot:[`field.estimated_value`]="{ item }">
                            <template v-if="item.currency === 'EUR'">
                                <div class="font-weight-bold success--text">
                                    {{ item.estimated_value | formatNumber }}
                                    {{ item.currency }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="font-weight-bold success--text">
                                    {{
                                        item.estimated_value_euro | formatNumber
                                    }}
                                    {{ item.estimated_value_currency }}
                                </div>
                                <div>
                                    {{ item.estimated_value | formatNumber }}
                                    {{ item.currency }}
                                </div>
                            </template>
                        </template>

                        <template v-slot:[`field.deadlines`]="{ item }">
                            <div v-if="item.deadlines">
                                <deadline-list :item="item"/>
                            </div>
                        </template>

                        <template v-slot:[`field.title`]="{ item }">
                            <title-tooltip :item="item"/>
                            <div>
                                <a :href="item.provider_link" target="_blank">
                                    <span
                                        class="font-weight-bold pt-3 text-sm-body-2"
                                    >
                                        Cod licitatie:
                                        {{ item.external_number }}
                                    </span>
                                </a>
                            </div>
                        </template>

                        <template v-slot:[`item.actions`]="{ resource, item }">
                            <deadline-select :item="item"/>
                            <va-show-button
                                :item="item"
                                :resource="
                                    getResourceByStatusId(item.status_id)
                                "
                                icon
                            ></va-show-button>
                            <toggle-featured-auction :item="item"/>
                        </template>

                    </mb-data-table>
                </va-list>
            </base-material-card>
        </v-col>
    </v-row>
</template>

<script>
import DeadlineList from "@/components/resources/auctions/DeadlineList";
import DeadlineSelect from "@/components/auctions/deadlineSelect";
import TitleTooltip from "@/components/auctions/TitleTooltip";
import ToggleFeaturedAuction from "@/components/auctions/ToggleFeaturedAuction";

export default {
    name: "DashboardQuickList",
    components: {
        ToggleFeaturedAuction,
        TitleTooltip,
        DeadlineSelect,
        DeadlineList
    },
    data() {
        return {
            filters: [
                {
                    source: "status",
                    type: "autocomplete",
                    attributes: {
                        reference: "auction_statuses",
                        multiple: true
                    }
                },
                {
                    source: "category",
                    type: "autocomplete",
                    attributes: {
                        reference: "auction_category",
                        multiple: true
                    }
                }
            ],
            fields: [
                "internal_number",
                {
                    source: "category",
                    type: "reference",
                    attributes: {
                        reference: "auction_category",
                        text: "name",
                        chip: true,
                        color: item => item.color
                    },
                    align: "center",
                    width: "140px"
                },
                {
                    source: "title",
                    sortable: true,
                    width: "350px"
                },
                {
                    source: "county",
                    width: "140px"
                },
                {
                    source: "estimated_value",
                    sortable: true,
                    width: "170px"
                },
                {
                    source: "status_id",
                    type: "simple-select",
                    attributes: {
                        reference: "auction_statuses",
                        itemsPerPage: 1000
                    },
                    editable: true,
                    width: "140px"
                },
                {
                    source: "deadlines",
                    width: "150px",
                    align: "center"
                }
            ],
        };
    },
    methods: {
        itemRowBackground(item) {
            if (!item.deadlines) {
                return "";
            }
            const nextDeadline = item.deadlines.find(Boolean);
            if (!nextDeadline) {
                return "";
            }
            return nextDeadline.number_of_days < 3
                ? "red lighten-5"
                : nextDeadline.number_of_days < 7
                    ? "yellow lighten-5"
                    : "green lighten-5";
        },
        getProperShowRoute(item) {
            const name = "auction-new_show";
            return {
                name: name,
                params: {id: item.id}
            };
        },
        getResourceByStatusId(statusId) {
            let resourceName = "";
            switch (statusId) {
                case 1:
                    resourceName = "auction-new";
                    break;
                case 2:
                    resourceName = "auction-interest";
                    break;
                case 3:
                    resourceName = "auction-no-interest";
                    break;
                case 4:
                    resourceName = "auction-in-progress";
                    break;
                case 5:
                    resourceName = "auction-won";
                    break;
                case 6:
                    resourceName = "auction-lost";
                    break;
                case 7:
                    resourceName = "auction-processing";
                    break;
            }
            return resourceName;
        }
    }
};
</script>
