<template>
    <va-create-layout>
        <auction-statuses-form
            :item="item"
            :title="title"
        ></auction-statuses-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"]
};
</script>
