<template>
    <div>
        <div class="mb-4">
            <v-btn x-small color="success" @click="markAsInterested">
                E de interes
            </v-btn>
        </div>
        <div>
            <v-btn x-small color="error" @click="markAsNotInterested">
                Nu e de interes
            </v-btn>
        </div>
    </div>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Editable from "vuetify-admin/src/mixins/editable";
import ReferenceInput from "vuetify-admin/src/mixins/reference-input";
/**
 * Value editing from a fixed choices. Support multiple and references.
 * If no choices, by default, takes localized enums with source as value from your VueI18n resources locales.
 */
export default {
    mixins: [Input, Editable, ReferenceInput],
    inject: {
        listState: { default: undefined }
    },
    methods: {
        change(value) {
            /**
             * Triggered on any user input interaction.
             */
            this.$emit("change", value);

            if (this.editable) {
                /**
                 * Quick update model on server
                 */
                this.$store
                    .dispatch(`${this.resource}/update`, {
                        id: this.item.id,
                        data: {
                            [this.uniqueFormId]: value
                        }
                    })
                    .then(() => {
                        this.listState.reload();
                    });
            }
        },
        markAsInterested() {
            this.change(2);
        },
        markAsNotInterested() {
            this.change(3);
        }
    }
};
</script>
