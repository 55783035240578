<template>
    <div
        class="task-note d-md-flex align-center flex-column flex-md-row"
        @click="$refs.taskView.openDialog()"
    >
        <div class="px-8 flex-shrink-0">
            <div class="black--text text-body-2 font-weight-medium my-3">
                {{ task.created_at | moment("dddd, Do MMMM YYYY, HH:mm") }}
            </div>
            <div class="black--text text-body-2">
                Task creat de:
            </div>
            <div class="black--text text-h4 font-weight-bold mb-3">
                {{ task.user.name }}
            </div>
        </div>
        <div class="overflow-x-auto task-note__table">
            <table>
                <thead class="task-note__table-header">
                    <tr>
                        <th style="min-width: 280px">Titlu</th>
                        <th style="min-width: 120px">Departament</th>
                        <th style="min-width: 120px">Responsabil</th>
                        <th style="min-width: 100px">Termen</th>
                        <th style="min-width: 150px">Prioritate</th>
                        <th style="min-width: 120px">Status</th>
                    </tr>
                </thead>
                <tbody class="task-note__table-body">
                    <tr>
                        <td>
                            <span class="font-weight-bold">
                                <task-view
                                    :item="task"
                                    ref="taskView"
                                ></task-view>
                            </span>
                        </td>
                        <td>
                            <span v-if="task.department">
                                {{ task.department.name }}
                            </span>
                        </td>
                        <td>
                            <template v-if="task.users && task.users.length">
                                <v-tooltip
                                    v-for="(resp, index) in task.users"
                                    :key="index"
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-avatar
                                            color="primary"
                                            size="34"
                                            style="margin-left: -10px;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span
                                                class="text-body-2 white--text"
                                            >
                                                {{
                                                    resp.name
                                                        .match(/\b(\w)/g)
                                                        .join("")
                                                }}
                                            </span>
                                        </v-avatar>
                                    </template>
                                    <span>{{ resp.name }}</span>
                                </v-tooltip>
                            </template>
                        </td>
                        <td>
                            <span class="">
                                {{ task.deadline | moment("DD.MM.YYYY") }}
                            </span>
                        </td>
                        <td>
                            <!-- Urgent priority-->
                            <v-icon
                                v-if="task.priority === 1"
                                color="#C53232"
                                size="30"
                            >
                                mdi mdi-chevron-double-up
                            </v-icon>
                            <!-- High priority-->
                            <v-icon
                                v-if="task.priority === 2"
                                color="#FF9D00"
                                size="30"
                            >
                                mdi mdi-chevron-double-up
                            </v-icon>
                            <!-- Medium priority-->
                            <v-icon
                                v-if="task.priority === 3"
                                color="#FF9D00"
                                size="30"
                            >
                                mdi mdi-chevron-up
                            </v-icon>
                            <!-- Low priority-->
                            <v-icon
                                v-if="task.priority === 4"
                                color="#BDBDBD"
                                size="30"
                            >
                                mdi mdi-minus-thick
                            </v-icon>
                        </td>
                        <td>
                            <span v-if="task.closed_at">Inchis</span>
                            <span v-else>Deschis</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import TaskView from "@/components/tasks/taskView";
export default {
    name: "TaskNote",
    components: { TaskView },
    props: {
        task: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.task-note {
    border: 2px solid #ff0000;
    border-left-width: 13px;
    border-radius: 5px;

    &__table {
        border-left: 2px solid #efefef;
        width: 100%;

        table {
            border-collapse: collapse;
            width: 100%;
            height: 100%;
        }

        &-header {
            background-color: #f7f7f7;
            border-bottom: 2px solid #efefef;

            th {
                font-size: 14px;
                text-align: left;
                padding: 17px;

                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
        }

        &-body {
            td {
                padding: 20px 17px;
            }
        }
    }
}
</style>
