import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Profile from "@/views/Profile";
import Error from "@/views/Error";
import i18n from "@/i18n";
import Notification from "@/views/Notifications";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
    path: "",
    component: AdminLayout,
    meta: {
        title: i18n.t("routes.home")
    },
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
            meta: {
                title: i18n.t("routes.dashboard")
            }
        },
        {
            path: "/auction/stats",
            name: "auction-stats",
            component: () => import("@/views/AuctionStats"),
            meta: {
                title: i18n.t("routes.auctionStats")
            }
        },
        {
            path: "/notifications",
            name: "all-notification",
            component: Notification,
            meta: {
                title: i18n.t("routes.notifications")
            }
        },
        {
            path: "/profile",
            name: "profile",
            component: Profile,
            meta: {
                title: i18n.t("routes.profile")
            }
        },
        {
            path: "*",
            component: Error,
            meta: {
                title: i18n.t("routes.not_found")
            }
        }
    ]
};
