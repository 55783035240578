<template>
    <div class="inline-block">
        <v-btn color="success" @click="markAsInterested" class="mx-4">
            E de interes
        </v-btn>

        <v-btn color="error" @click="markAsNotInterested">
            Nu e de interes
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "InitialStatusSelector",
    props: {
        item: {
            required: true
        }
    },
    methods: {
        change(value) {
            /**
             * Triggered on any user input interaction.
             */
            this.$emit("change", value);

            /**
             * Quick update model on server
             */
            this.$store
                .dispatch(`auction-new/update`, {
                    id: this.item.id,
                    data: {
                        status_id: value
                    }
                })
                .then(() => {
                    this.$router.push({ name: "auction-new_list" });
                });
        },
        markAsInterested() {
            this.change(2);
        },
        markAsNotInterested() {
            this.change(3);
        }
    }
};
</script>

<style scoped></style>
