<template>
    <div>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(product, index) in products"
                :key="index"
            >
                <v-expansion-panel-header>
                    <v-row class="align-center">
                        <v-col cols="4">{{ product.name }}</v-col>
                        <v-col class="pr-10">
                            <apexchart
                                type="area"
                                height="50"
                                :options="chartOptions"
                                :series="[
                                    {
                                        data: product.quotations.map(item => ({
                                            x: item.created_at,
                                            created: item.created_at_verbose,
                                            y: item.price,
                                            currency: item.currency
                                        }))
                                    }
                                ]"
                            ></apexchart>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row
                        v-for="(quotation, indexQ) in product.quotations"
                        :key="indexQ"
                        class="attachments-list__item grey lighten-3 d-flex flex-wrap justify-space-between align-center mb-3 mx-0 pa-3 rounded"
                    >
                        <v-col
                            class="attachments-list__item-title text--grey darken-2 pa-0 pa-md-3"
                            cols="12"
                            md="4"
                            sm="3"
                        >
                            {{ quotation.price | formatNumber }}
                            {{ quotation.currency }}
                        </v-col>
                        <v-col
                            class="attachments-list__item-price-evolution text--grey darken-2 pa-0 pa-md-3 d-flex items-center"
                            cols="12"
                            sm="2"
                        >
                            {{ quotation.created_at | moment("L") }}
                        </v-col>

                        <v-col
                            align="right"
                            class="attachments-list__item-actions"
                            cols="12"
                            md="2"
                            sm="3"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :href="quotation.full_file_path"
                                        class="grey lighten-3"
                                        icon
                                        large
                                        target="_blank"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon color="grey darken-2"
                                            >mdi-file-download-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Descarca oferta</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    name: "ProductQuotationsList",
    props: {
        supplier: {}
    },
    data() {
        return {
            products: [],
            chartOptions: {
                chart: {
                    type: "area",
                    height: 160,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    curve: "straight"
                },
                fill: {
                    opacity: 0.3
                },
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    custom({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            '<div class="pa-1"><strong>' +
                            w.globals.initialSeries[seriesIndex].data[
                                dataPointIndex
                            ].created +
                            "</strong><span>: " +
                            series[seriesIndex][dataPointIndex] +
                            " " +
                            w.globals.initialSeries[seriesIndex].data[
                                dataPointIndex
                            ].currency +
                            "</span>" +
                            "</div>"
                        );
                    }
                }
            }
        };
    },
    methods: {
        loadSuppliers() {
            this.$admin.http
                .get(
                    `/api/products?filter[supplier]=${this.supplier.id}&include=quotations`
                )
                .then(({ data }) => {
                    this.products = data.data;
                });
        }
    },
    mounted() {
        this.loadSuppliers();
    }
};
</script>
