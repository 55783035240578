<template>
    <v-list-item class="px-2 checkbox-item">
        <v-list-item-action class="mr-2 my-2" @click="checkItem">
            <v-btn class="" color="#949494" dark icon x-small>
                <v-icon>
                    fal fa-square
                </v-icon>
            </v-btn>
        </v-list-item-action>
        <v-list-item-content>
            <v-textarea
                v-model="content"
                :auto-grow="true"
                class="no-border-bottom text-body-2 checkbox-item__textarea"
                dense
                hide-details
                :disabled="!editable"
                rows="1"
                value="Vix at paulo diceret ferri ver"
            ></v-textarea>
        </v-list-item-content>
        <v-list-item-action
            class="d-flex flex-row align-self-center"
            v-if="editable"
        >
            <!--            <task-responsibles :item="item" />-->
            <div class="ml-2">
                <span v-if="!first">
                    <v-btn
                        class=""
                        color="#949494"
                        dark
                        icon
                        x-small
                        @click="moveUp"
                        :loading="loadingMoveUp"
                    >
                        <v-icon>
                            fal fa-arrow-square-up
                        </v-icon>
                    </v-btn>
                </span>
                <span
                    v-if="!last"
                    class="v-btn--icon v-size--x-small d-inline-block"
                >
                    <v-btn
                        class=""
                        color="#949494"
                        dark
                        icon
                        x-small
                        @click="moveDown"
                        :loading="loadingMoveDown"
                    >
                        <v-icon>
                            fal fa-arrow-square-down
                        </v-icon>
                    </v-btn>
                </span>
                <v-btn
                    :loading="loadingDelete"
                    color="red"
                    dark
                    icon
                    x-small
                    @click="deleteItem"
                >
                    <v-icon>
                        fal fa-minus-circle
                    </v-icon>
                </v-btn>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: "OpenChecklistItem",
    props: {
        item: {
            type: Object,
            required: true
        },
        last: {
            type: Boolean,
            default: false
        },
        first: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: "",
            loadingDelete: false,
            loadingCheckbox: false,
            loadingMoveUp: false,
            loadingMoveDown: false
        };
    },
    methods: {
        checkItem() {
            if (this.editable) {
                this.loadingCheckbox = true;
                this.$admin.http
                    .patch(
                        "api/task-checklist-items/" + this.item.id + "/check"
                    )
                    .then(() => {
                        this.$emit("update-checklist-item");
                        this.loadingCheckbox = false;
                    });
            }
        },
        deleteItem() {
            if (this.editable) {
                this.loadingDelete = true;
                this.$admin.http
                    .delete("api/task-checklist-items/" + this.item.id)
                    .then(() => {
                        this.$emit("update-checklist-item");
                        this.loadingDelete = false;
                    });
            }
        },
        moveUp() {
            if (this.editable) {
                this.loadingMoveUp = true;
                this.$admin.http
                    .patch(
                        "api/task-checklist-items/" + this.item.id + "/move-up"
                    )
                    .then(() => {
                        this.$emit("update-checklist-item");
                        this.loadingMoveUp = false;
                    });
            }
        },
        moveDown() {
            if (this.editable) {
                this.loadingMoveDown = true;
                this.$admin.http
                    .patch(
                        "api/task-checklist-items/" +
                            this.item.id +
                            "/move-down"
                    )
                    .then(() => {
                        this.$emit("update-checklist-item");
                        this.loadingMoveDown = false;
                    });
            }
        }
    },
    watch: {
        content(newVal, oldVal) {
            if (
                this.editable &&
                newVal !== oldVal &&
                newVal !== this.item.content
            ) {
                this.$admin.http.patch(
                    "api/task-checklist-items/" + this.item.id,
                    {
                        task_content: newVal
                    }
                );
            }
        }
    },
    mounted() {
        this.content = this.item.content;
    }
};
</script>
