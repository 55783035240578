<template>
    <v-card id="auction-fab">
        <v-speed-dial
            v-model="active"
            :top="true"
            :right="true"
            direction="left"
            transition="scale-transition"
            fixed
        >
            <template v-slot:activator>
                <v-btn v-model="active" color="red" dark fab>
                    <v-icon v-if="active">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-cog
                    </v-icon>
                </v-btn>
            </template>

            <va-list-button
                :resource="resource"
                :item="item"
                icon
            ></va-list-button>

            <v-divider vertical></v-divider>

            <va-edit-button
                :resource="resource"
                :item="item"
                icon
            ></va-edit-button>

            <v-divider vertical></v-divider>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dark
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="$refs.deadlineDialog.dialog = true"
                    >
                        <v-icon>mdi-clock-outline</v-icon>
                    </v-btn>
                </template>
                <span>Seteaza deadline</span>
            </v-tooltip>

            <status-select-input :item="item" />

            <responsible-list :item="item" :users="item.users" />
        </v-speed-dial>
        <deadline-select
            :item="item"
            ref="deadlineDialog"
            :show-activator="false"
        />
    </v-card>
</template>

<script>
import StatusSelectInput from "../../../inputs/StatusSelectInput";
import DeadlineSelect from "../../../auctions/deadlineSelect";
import ResponsibleList from "../ResponsibleList";
export default {
    components: { ResponsibleList, DeadlineSelect, StatusSelectInput },
    props: {
        item: {
            required: true
        }
    },
    data: () => ({
        active: false
    }),
    computed: {
        resource() {
            return this.$route.meta.resource;
        }
    }
};
</script>

<style lang="scss">
#auction-fab {
    .v-speed-dial {
        top: 60px;
        z-index: 9;
        &--is-active {
            .v-speed-dial__list {
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
                    0 2px 2px 0px rgb(0 0 0 / 14%),
                    0 1px 5px 0px rgb(0 0 0 / 12%);
                border-radius: 4px;
                background: #ffffff;
            }
        }
    }
}
</style>
