export default (i18n, admin, store) => {
    const userDepartments = store.getters["userDepartments"].map(
        item => item.name
    );

    const userRole = store.getters["userRole"];

    let menu = [
        {
            icon: "fal fa-columns",
            text: i18n.t("menu.dashboard"),
            link: "/"
        }
    ];

    if (
        userRole === "admin" ||
        userDepartments.includes("Licitatii Publice") ||
        userDepartments.includes("Juridic")
    ) {
        menu.push({
            heading: i18n.t("menu.auctions")
        });

        menu.push({
            divider: true
        });
        menu.push({
            icon: "fal fa-hands-usd",
            text: i18n.t("menu.auctions"),
            id: "auctions",
            expanded: false,
            children: [
                admin.getResourceLink("auction-new"),
                admin.getResourceLink("auction-interest"),
                admin.getResourceLink("auction-processing"),
                admin.getResourceLink("auction-in-progress"),
                admin.getResourceLink("auction-won")
            ]
        });
        menu.push({
            icon: "fal fa-vote-nay",
            text: i18n.t("menu.auctions-no-interest"),
            id: "archive",
            expanded: false,
            children: [
                admin.getResourceLink("auction-canceled"),
                admin.getResourceLink("auction-completed"),
                admin.getResourceLink("auction-lost"),
                admin.getResourceLink("auction-no-interest")
            ]
        });
        menu.push(admin.getResourceLink("customers"));
        menu.push({
            icon: "fal fa-chart-line",
            text: i18n.t("menu.auctionStats"),
            link: "/auction/stats"
        });

        menu.push({ heading: i18n.t("menu.products") });
        menu.push({ divider: true });
        menu.push(admin.getResourceLink("supplier_types"));
        menu.push(admin.getResourceLink("suppliers"));
        menu.push(admin.getResourceLink("product_type"));
    }

    if (userRole === "admin") {
        menu.push({ heading: i18n.t("menu.settings") });
        menu.push({ divider: true });
        menu.push({
            icon: "fal fa-cogs",
            text: i18n.t("menu.general_settings"),
            expanded: false,
            children: [admin.getResourceLink("users")]
        });
        menu.push({
            icon: "fal fa-cog",
            text: i18n.t("menu.auction_settings"),
            expanded: false,
            children: [
                admin.getResourceLink("auction_statuses"),
                admin.getResourceLink("auction_category"),
                admin.getResourceLink("auction_interests")
            ]
        });
    }

    menu.push({ heading: i18n.t("menu.tasks") });
    menu.push({ divider: true });
    menu.push(admin.getResourceLink("my-tasks"));
    return menu;
};
