<template>
    <v-select
        v-model="selectedFilters"
        :items="items"
        chips
        deletable-chips
        dense
        hide-details
        item-text="value"
        item-value="key"
        label="Limiteaza taskurile"
        multiple
        outlined
        small-chips
    >
        <template v-slot:selection="{ item, index }">
            <template v-if="index === 0">
                {{ value.length }} Optiuni selectate
            </template>
        </template>
    </v-select>
</template>

<script>
/**
 * @todo
 */
export default {
    props: {
        model: {
            // @todo check if still needed
            type: Array,
            default() {
                return [];
            }
        },
        value: {}
    },
    data() {
        return {
            isLoading: false,
            items: [
                {
                    key: "mine",
                    value: "Create de mine"
                },
                {
                    key: "responsible",
                    value: "La care sunt responsabil"
                },
                {
                    key: "private",
                    value: "Taskuri Private"
                },
                {
                    key: "watcher",
                    value: "La care sunt urmaritor"
                },
                {
                    key: "department",
                    value: "Departamentul meu"
                }
            ]
        };
    },
    computed: {
        selectedFilters: {
            get() {
                return this.value;
            },
            set(values) {
                if (values.length === 0) {
                    values = [
                        'mine',
                        'responsible',
                        'private',
                        'watcher',
                        'department'
                    ];
                }
                this.$emit("input", values);
            }
        }
    }
};
</script>
