<template>
    <va-edit-layout>
        <product-form :id="id" :title="title" :item="item"></product-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
