<template>
    <base-material-card
        class="pb-8"
        icon="fal fa-check-square"
        title="Taskurile mele"
    >
        <v-row class="align-center">
            <!--<v-col cols="12" sm="6" md="3">
                <task-search />
                <task-user-select-filter
                    v-model="taskFilter.onlyUsers.values"
                />
            </v-col>-->
            <v-spacer/>

            <v-col class="text-right" cols="12" sm="6" md="3" v-if="$store.getters.userRole === 'director'">
                <task-select-filter-departments
                    v-model="taskFilter.taskFiltersDepartments.values"
                />
            </v-col>

            <v-col class="text-right" cols="12" sm="3">
                <task-select-filter v-model="taskFilter.taskFilters.values"/>
                <router-link :to="routeToAllTasks">Vezi si taskurile inchise</router-link>
            </v-col>

        </v-row>
        <tasks-accordion-panel
            :filter="taskFilter"
            :showAddTaskButton="false"
            accordionTitle="In Trecut"
            color="197, 50, 50"
            resource="tasks/past"
        />
        <tasks-accordion-panel
            :filter="taskFilter"
            accordionTitle="Astazi"
            color="0, 168, 7"
            resource="tasks/today"
        />
        <tasks-accordion-panel
            :filter="taskFilter"
            accordionTitle="Saptamana Curenta"
            color="76, 175, 80"
            resource="tasks/week"
        />
        <tasks-accordion-panel
            :filter="taskFilter"
            accordionTitle="Saptamana Viitoare"
            color="255, 157, 0"
            resource="tasks/next-week"
        />
        <tasks-accordion-panel
            :filter="taskFilter"
            accordionTitle="In Viitor"
            color="189, 189, 189"
            resource="tasks/upcomming"
        />
    </base-material-card>
</template>

<script>
import TaskSelectFilter from "@/components/tasks/taskSelectFilter";
import TaskSelectFilterDepartments from "@/components/tasks/taskSelectFilterDepartments";
import TasksAccordionPanel from "@/components/tasks/tasksAccordionPanel";
import ReferenceInput from "vuetify-admin/src/mixins/reference-input";

export default {
    mixins: [ReferenceInput],
    components: {TasksAccordionPanel, TaskSelectFilter, TaskSelectFilterDepartments},
    props: ["resource"],
    data() {
        return {
            taskFilter: {
                incomplete: true,
                onlyUsers: {
                    values: []
                },
                taskFilters: {
                    values: ["mine", "responsible", "private", "watcher", "department"]
                },
                taskFiltersDepartments: {
                    values: []
                }
            }
        };
    },
    computed: {
        routeToAllTasks() {
            return this.$admin.getResourceLink("tasks").link;
        }
    },
    mounted() {
        this.$store.dispatch("getAllDepartments", {vm: this});
    }
};
</script>
