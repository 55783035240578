<template>
    <v-dialog v-model="dialog" width="500" scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" depressed v-bind="attrs" v-on="on" v-if="false">
                <strong>{{ buttonText }}</strong>
                <v-icon left class="ml-2">
                    mdi-arrow-right
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-toolbar color="primary" dark
                >Completează următoarele rubrici pentru a putea trimite către
                juridic.</v-toolbar
            >

            <v-card-text>
                <div class="mb-3">
                    <label>Adaugă un titlu:</label>
                    <v-text-field
                        v-model="formData.title"
                        placeholder="Ex: Verificare Judiciară Licitație #145"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                    <template v-if="errors">
                        <span
                            v-for="(error, index) in errors.title"
                            :key="index"
                            class="error pa-1 white--text rounded"
                            >{{ error }}
                        </span>
                    </template>
                </div>
                <div class="mb-3">
                    <label>Selectează un departament:</label>
                    <v-select
                        v-model="formData.department"
                        :items="departments"
                        item-text="label"
                        item-value="value"
                        outlined
                        dense
                        hide-details
                        placeholder="Ex: Juridic"
                    ></v-select>
                </div>
                <div class="mb-3">
                    <label>Selectează un responsabil:</label>
                    <v-select
                        v-model="formData.responsible"
                        :items="responsibles"
                        item-text="label"
                        item-value="value"
                        outlined
                        dense
                        hide-details
                        placeholder="Ex: Valentin T."
                    ></v-select>
                </div>
                <div class="mb-3">
                    <label>Selectează termenul:</label>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="mb-3">
                    <label>Conținut:</label>
                    <v-textarea
                        v-model="formData.content"
                        outlined
                        dense
                        hide-details
                        placeholder="Scrie textul aici..."
                    ></v-textarea>
                </div>
                <div class="mb-3">
                    <label>Selectează tipul de proprietate:</label>
                    <v-select
                        v-model="formData.property"
                        :items="propertiesTypes"
                        item-text="label"
                        item-value="value"
                        outlined
                        dense
                        hide-details
                        placeholder="Ex: Normal"
                    ></v-select>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="disabled" text @click="dialog = false">
                    Renunta
                </v-btn>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    text
                    @click="send"
                >
                    Adauga
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "TaskDialog",
    props: {
        buttonText: {
            type: String,
            default: "Trimite către juridic"
        }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            errors: {},
            formData: {
                title: "",
                department: "",
                responsible: "",
                date: "",
                content: ""
            },
            departments: [
                {
                    value: 1,
                    label: "Juridic"
                },
                {
                    value: 2,
                    label: "Logistica"
                }
            ],
            responsibles: [
                {
                    value: 1,
                    label: "Valentin T."
                },
                {
                    value: 2,
                    label: "Ion V."
                }
            ],
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            menu: false,
            propertiesTypes: [
                {
                    value: 1,
                    label: "Normal"
                },
                {
                    value: 2,
                    label: "Fgg"
                }
            ]
        };
    },
    methods: {
        send() {
            console.log("send");
        }
    }
};
</script>

<style scoped></style>
