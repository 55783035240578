var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-data-table',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],class:{ 'clickable-rows': _vm.rowClick || !!_vm.$listeners['row-click'] },attrs:{"disable-sort":_vm.disableSort,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.listState.options.itemsPerPage,"loading":_vm.listState.loading,"multi-sort":_vm.multiSort,"show-expand":_vm.showExpand,"show-select":!_vm.disableSelect,"single-expand":_vm.singleExpand,"sort-by":_vm.listState.options.sortBy,"sort-desc":_vm.listState.options.sortDesc,"value":_vm.listState.selected,"hide-default-footer":"","item-class":_vm.itemClass},on:{"update:sortBy":function($event){return _vm.$set(_vm.listState.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.listState.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.listState.options, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.listState.options, "sortDesc", $event)},_vm.listState.reload],"input":function (s) { return (_vm.listState.selected = s); },"click:row":_vm.onRowClick},scopedSlots:_vm._u([_vm._l((_vm.getFields),function(field){return {key:("item." + (field.source)),fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item._new || item.id === _vm.editRowId)?[_c(("va-" + (field.input || field.type || 'text') + "-input"),_vm._b({key:field.source,tag:"component",staticClass:"mt-6",attrs:{"error-messages":_vm.errors[field.source],"item":item,"resource":_vm.listState.resource,"source":field.source,"dense":"","label":""},model:{value:(_vm.form[field.source]),callback:function ($$v) {_vm.$set(_vm.form, field.source, $$v)},expression:"form[field.source]"}},'component',field.attributes,false))]:[(field.editable)?_c(("va-" + (field.input || field.type || 'text') + "-input"),_vm._b({key:field.source,tag:"component",attrs:{"item":item,"resource":_vm.listState.resource,"source":field.source,"value":value,"dense":"","editable":"","label":""}},'component',field.attributes,false)):(field.link)?_c('router-link',{key:field.source,attrs:{"to":{
                        name: ((_vm.listState.resource) + "_" + (field.link)),
                        params: { id: item.id }
                    }}},[(field.type)?_c(("va-" + (field.type) + "-field"),_vm._b({key:field.source,tag:"component",attrs:{"item":item,"resource":_vm.listState.resource,"source":field.source},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t(("field." + (field.source)),null,{"item":props.item || item},props)]}}],null,true)},'component',field.attributes,false)):_vm._t(("field." + (field.source)),[_vm._v(" "+_vm._s(value)+" ")],null,{ item: item, value: value })],2):(field.type)?_c(("va-" + (field.type) + "-field"),_vm._b({key:field.source,tag:"component",attrs:{"item":item,"resource":_vm.listState.resource,"source":field.source},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t(("field." + (field.source)),null,{"item":props.item || item},props)]}}],null,true)},'component',field.attributes,false)):_vm._t(("field." + (field.source)),[_vm._v(" "+_vm._s(value)+" ")],null,{ item: item, value: value })]]}}}),{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"item-actions"},[_vm._t("cell.actions",[(item._new || item.id === _vm.editRowId)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.saving,"color":"success","icon":"","type":"submit"}},on),[_c('v-icon',[_vm._v("mdi-floppy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("va.actions.save")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","icon":""},on:{"click":function($event){_vm.editRowId = null;
                                        _vm.form = null;
                                        _vm.errors = {};}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("va.actions.cancel")))])])]:[(!_vm.disableShow)?_c('va-show-button',{attrs:{"disable-redirect":_vm.disableShowRedirect,"item":item,"resource":_vm.listState.resource,"icon":""},on:{"click":function (item) { return _vm.onAction('show', item); }}}):_vm._e(),(!_vm.disableEdit)?_c('va-edit-button',{attrs:{"disable-redirect":_vm.disableEditRedirect || _vm.rowEdit,"item":item,"resource":_vm.listState.resource,"icon":""},on:{"click":function (item) { return _vm.onAction('edit', item); }}}):_vm._e(),_vm._t("item.actions",null,null,{ item: item }),(!_vm.disableClone)?_c('va-clone-button',{attrs:{"disable-redirect":_vm.disableCreateRedirect,"item":item,"resource":_vm.listState.resource,"icon":""},on:{"click":function (item) { return _vm.onAction('create', item); }}}):_vm._e(),(_vm.association)?_c('va-dissociate-button',{attrs:{"item":item,"resource":_vm.listState.resource,"source":_vm.association.source,"source-id":_vm.association.id,"source-resource":_vm.association.resource,"icon":""},on:{"dissociated":function($event){return _vm.$emit('dissociated', item)}}}):_vm._e(),(!_vm.disableDelete)?_c('va-delete-button',{attrs:{"item":item,"resource":_vm.listState.resource,"icon":""},on:{"deleted":function($event){return _vm.$emit('deleted', item)}}}):_vm._e()]],null,{ item: item })],2)]}},{key:"expanded-item",fn:function(ref){
                                        var headers = ref.headers;
                                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._t("expanded-item",null,null,{ item: item })],2)]}}],null,true)},[(_vm.rowCreate)?_c('v-tooltip',{attrs:{"slot":"header.actions","bottom":""},slot:"header.actions",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.createRowForm()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,524210446)},[_c('span',[_vm._v(_vm._s(_vm.$t("va.actions.create")))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }