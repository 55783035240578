import Vue from "vue";
import Vuex from "vuex";
import price_analysis from "./price_analysis";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        price_analysis
    },
    state: {
        notificationTree: {},
        unreadNotifications: {},
        countByAuction: null,
        countByBatch: null,
        countByStatus: null,
        countAuctions: null,
        countArchive: null,
        departments: {}
    },
    mutations: {
        setSidebarState(state, payload) {
            state.auth.user.settings.sidebar_state = payload;
        },
        setSidebarListExpansion(state, payload) {
            state.auth.user.settings.sidebar_list_expanded = payload;
        },
        setTaskPanelExpansion(state, payload) {
            state.auth.user.settings[payload.key] = payload.value;
        },
        setCountNotifications(state, payload) {
            state.notificationTree = payload.notificationTree;
            state.countByAuction = payload.countByAuction;
            state.countByBatch = payload.countByBatch;
            state.countByStatus = payload.countByStatus;
            state.countAuctions = payload.countAuctions;
            state.countArchive = payload.countArchive;
        },
        setUnreadNotifications(state, payload) {
            state.unreadNotifications = payload.unreadNotifications;
        },
        setDepartments(state, payload) {
            state.departments = payload;
        }
    },
    actions: {
        getAllDepartments({ commit }, { vm }) {
            return vm.$admin.http
                .get(`/api/departments/all`)
                .then(({ data }) => {
                    commit("setDepartments", data);
                });
        }
    },
    getters: {
        user: state => {
            return state.auth.user;
        },
        userRole: state => {
            if (state.auth.user.roles[0]) {
                return state.auth.user.roles[0].name;
            }
            return null;
        },
        userSettings: state => {
            return state.auth.user.settings;
        },
        notificationTree: state => {
            return state.notificationTree;
        },
        countByAuction: state => {
            return state.countByAuction;
        },
        countByBatch: state => {
            return state.countByBatch;
        },
        countByStatus: state => {
            return state.countByStatus;
        },
        countAuctions: state => {
            return state.countAuctions;
        },
        countArchive: state => {
            return state.countArchive;
        },
        unreadNotifications: state => {
            return state.unreadNotifications;
        },
        departments: state => {
            return state.departments;
        },
        userDepartments: state => {
            return state.auth.user.departments;
        }
    }
});
