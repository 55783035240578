<template>
    <v-select
        v-bind="commonProps"
        :chips="chips"
        :filled="filled"
        :hide-details="true"
        :item-text="getItemText"
        :item-value="getItemValue"
        :items="items || choices"
        :multiple="multiple"
        :small-chips="smallChips"
        label=""
        outlined
        @change="change"
        @input="update"
    >
    </v-select>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Editable from "vuetify-admin/src/mixins/editable";
import Multiple from "vuetify-admin/src/mixins/multiple";
import ReferenceInput from "vuetify-admin/src/mixins/reference-input";

/**
 * Value editing from a fixed choices. Support multiple and references.
 * If no choices, by default, takes localized enums with source as value from your VueI18n resources locales.
 */
export default {
    mixins: [Input, Multiple, Editable, ReferenceInput],
    inject: {
        listState: { default: undefined }
    },
    async created() {
        this.items = await this.fetchChoices();
    },
    methods: {
        /**
         * Interaction event
         */
        change(value) {
            /**
             * Triggered on any user input interaction.
             */
            this.$emit("change", value);

            if (this.editable) {
                /**
                 * Quick update model on server
                 */
                this.$store
                    .dispatch(`${this.resource}/update`, {
                        id: this.item.id,
                        data: {
                            [this.uniqueFormId]: value
                        }
                    })
                    .then(() => {
                        this.listState.reload();
                    });
            }
        }
    }
};
</script>
