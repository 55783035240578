<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <div v-if="item.priority === 1">
                        <template v-if="isIcon">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#C53232"
                                        icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                    >
                                        <v-icon size="30">
                                            mdi mdi-chevron-double-up
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Urgent</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-btn
                                color="#C53232"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="task-dialog__priority-button"
                            >
                                <v-icon left size="18">
                                    mdi mdi-chevron-double-up
                                </v-icon>
                                Urgent
                            </v-btn>
                        </template>
                    </div>
                    <div v-else-if="item.priority === 2">
                        <template v-if="isIcon">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#FF9D00"
                                        icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                    >
                                        <v-icon size="30">
                                            mdi mdi-chevron-double-up
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>High</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-btn
                                color="#FF9D00"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="task-dialog__priority-button"
                            >
                                <v-icon left size="18">
                                    mdi mdi-chevron-double-up
                                </v-icon>
                                High
                            </v-btn>
                        </template>
                    </div>
                    <div v-else-if="item.priority === 3">
                        <template v-if="isIcon">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#FF9D00"
                                        icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                    >
                                        <v-icon size="30">
                                            mdi mdi-chevron-up
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Medium</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-btn
                                color="#FF9D00"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="task-dialog__priority-button"
                            >
                                <v-icon left size="18">
                                    mdi mdi-chevron-up
                                </v-icon>
                                Medium
                            </v-btn>
                        </template>
                    </div>
                    <div v-else-if="item.priority === 4">
                        <template v-if="isIcon">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#BDBDBD"
                                        icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                    >
                                        <v-icon size="30">
                                            mdi mdi-minus-thick
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Low</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-btn
                                color="#BDBDBD"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="task-dialog__priority-button"
                            >
                                <v-icon left size="18">
                                    mdi mdi-minus-thick
                                </v-icon>
                                Low
                            </v-btn>
                        </template>
                    </div>
                    <div v-else>
                        <span class="text-decoration-underline"
                            >Selecteaza</span
                        >
                    </div>
                </div>
            </template>
            <v-list dense>
                <v-list-item-group>
                    <v-list-item
                        v-for="(item, index) in priorities"
                        :key="index"
                        @click="addPriority(item)"
                    >
                        <v-list-item-title>
                            <v-icon :color="item.color" size="25">{{
                                item.icon
                            }}</v-icon>
                            <span class="ml-5">{{ item.label }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    inject: {
        listState: { default: undefined }
    },
    props: {
        item: {},
        isIcon: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            priorities: [
                {
                    value: 1,
                    label: "Urgent",
                    icon: "mdi mdi-chevron-double-up",
                    color: "#FF0000"
                },
                {
                    value: 2,
                    label: "High",
                    icon: "mdi mdi-chevron-double-up",
                    color: "#FF9D00"
                },
                {
                    value: 3,
                    label: "Medium",
                    icon: "mdi mdi-chevron-up",
                    color: "#FF9D00"
                },
                {
                    value: 4,
                    label: "Low",
                    icon: "mdi mdi-minus-thick",
                    color: "#BDBDBD"
                }
            ]
        };
    },
    methods: {
        addPriority(item) {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .patch(`/api/tasks/${this.item.id}`, {
                        priority: item.value
                    })
                    .then(() => {
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        }
    }
};
</script>

<style lang="scss">
.v-dialog {
    .v-card {
        &__title {
            .task-dialog__priority-button {
                .v-btn {
                    &__content {
                        text-transform: initial;
                        .v-icon {
                            position: inherit;
                            top: inherit;
                            left: inherit;
                            right: inherit;
                            color: inherit;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>
