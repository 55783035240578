var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.item.priority === 1)?_c('div',[(_vm.isIcon)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#C53232","icon":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi mdi-chevron-double-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Urgent")])])]:[_c('v-btn',_vm._g(_vm._b({staticClass:"task-dialog__priority-button",attrs:{"color":"#C53232","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","size":"18"}},[_vm._v(" mdi mdi-chevron-double-up ")]),_vm._v(" Urgent ")],1)]],2):(_vm.item.priority === 2)?_c('div',[(_vm.isIcon)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#FF9D00","icon":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi mdi-chevron-double-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("High")])])]:[_c('v-btn',_vm._g(_vm._b({staticClass:"task-dialog__priority-button",attrs:{"color":"#FF9D00","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","size":"18"}},[_vm._v(" mdi mdi-chevron-double-up ")]),_vm._v(" High ")],1)]],2):(_vm.item.priority === 3)?_c('div',[(_vm.isIcon)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#FF9D00","icon":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi mdi-chevron-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Medium")])])]:[_c('v-btn',_vm._g(_vm._b({staticClass:"task-dialog__priority-button",attrs:{"color":"#FF9D00","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","size":"18"}},[_vm._v(" mdi mdi-chevron-up ")]),_vm._v(" Medium ")],1)]],2):(_vm.item.priority === 4)?_c('div',[(_vm.isIcon)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#BDBDBD","icon":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi mdi-minus-thick ")])],1)]}}],null,true)},[_c('span',[_vm._v("Low")])])]:[_c('v-btn',_vm._g(_vm._b({staticClass:"task-dialog__priority-button",attrs:{"color":"#BDBDBD","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","size":"18"}},[_vm._v(" mdi mdi-minus-thick ")]),_vm._v(" Low ")],1)]],2):_c('div',[_c('span',{staticClass:"text-decoration-underline"},[_vm._v("Selecteaza")])])])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.priorities),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.addPriority(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":item.color,"size":"25"}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(item.label))])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }