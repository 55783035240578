<template>
    <v-col cols="12" lg="5" class="mt-4 mt-lg-0 position-relative">
        <div
            :class="{
                'locked-wrapper': !batch.price_analysis.can_be_edited,
                'locked-wrapper locked':
                    batch.price_analysis.has_sale_option_selected
            }"
        ></div>
        <v-row class="price-analysis-exchange-date mx-0 ml-0 pa-1 pb-4">
            <v-col cols="12" class="pl-2">
                <v-row>
                    <v-col class="pb-0 pl-2">
                        <span class="font-weight-bold">
                            ACHIZITIE CURS BT LA DATA
                            <template v-if="exchangeRateOnDateActive">
                                {{ formatDate() }}
                            </template>
                            <template v-else>
                                <span>-</span>
                            </template>
                        </span>
                    </v-col>
                </v-row>

                <v-row class="mt-xl-7">
                    <v-col cols="9" class="pl-2">
                        <v-row align="center">
                            <v-col cols="12" xl="4" class="pb-0">
                                <span class="text-body-2 font-weight-bold">
                                    ACHIZITIE EURO
                                </span>
                            </v-col>
                            <v-col cols="6" xl="4" class="pr-1 py-0">
                                <span class="text-body-2 grey--text"
                                    >Suma totala</span
                                >
                                <span class="price-input">
                                    <template v-if="exchangeRateOnDateActive">
                                        {{
                                            Number(
                                                estimatedValueEuro
                                            ).toLocaleString("ro-RO", {
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </span>
                            </v-col>
                            <v-col cols="6" xl="4" class="pl-1 py-0">
                                <span class="text-body-2 grey--text"
                                    >Curs BT zi</span
                                >
                                <span class="price-input">
                                    <template v-if="exchangeRateOnDateActive">
                                        {{
                                            Number(
                                                acquisitionRate.rate
                                            ).toLocaleString("ro-RO", {
                                                minimumFractionDigits: 4
                                            })
                                        }}
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </span>
                            </v-col>
                        </v-row>

                        <v-row class="mt-3" align="center">
                            <v-col cols="12" xl="4" class="pb-0">
                                <span class="text-body-2 font-weight-bold">
                                    ACHIZITIE RON
                                </span>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="8"
                                class="pt-0 pb-xl-0 pt-xl-3"
                            >
                                <span class="price-input">
                                    <template v-if="exchangeRateOnDateActive">
                                        {{
                                            Number(
                                                estimatedValue
                                            ).toLocaleString("ro-RO", {
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="3"
                        class="pl-0 mt-6 mt-xl-0 pr-2 pt-xl-0 mb-6 mb-xl-3"
                    >
                        <span class="text-body-2 grey--text">% Buget</span>
                        <span
                            class="price-input d-flex align-center"
                            style="max-height: 100%; height: 100%;"
                            :class="acquisitionBuget > 100 ? 'error--text' : ''"
                        >
                            <template v-if="exchangeRateOnDateActive">
                                {{
                                    Number(acquisitionBuget).toLocaleString(
                                        "ro-RO",
                                        {
                                            maximumFractionDigits: 2
                                        }
                                    )
                                }}
                            </template>
                            <template v-else>
                                <span>-</span>
                            </template>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import dateFormat from "dateformat";
import { mapGetters } from "vuex";

export default {
    name: "PriceAnalysisExchangeDate",
    props: {
        batch: {
            type: Object,
            required: true,
            default: () => {}
        },
        item: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            acquisitionRate: "price_analysis/acquisition_rate",
            priceAnalysis: "price_analysis/price_analysis",
            productTotal: "price_analysis/product_total",
            currencies: "price_analysis/currencies",
            BNRAuctionRate: "price_analysis/bnr_auction_rate",
            RONBuget: "price_analysis/ron_buget",
            EURBuget: "price_analysis/eur_buget"
        }),
        totalPenaltyPercentage: {
            get() {
                return this.priceAnalysis.penalty &&
                    this.priceAnalysis.penalty.active
                    ? this.priceAnalysis.penalty.total_percentage
                    : 0;
            }
        },
        estimatedValueEuro: {
            get() {
                return Number(
                    this.productTotal -
                        this.productTotal * (this.totalPenaltyPercentage / 100)
                ).toFixed(2);
            }
        },
        estimatedValue: {
            get() {
                if (this.acquisitionRate) {
                    return Number(
                        this.productTotal * this.acquisitionRate.rate -
                            this.productTotal *
                                this.acquisitionRate.rate *
                                (this.totalPenaltyPercentage / 100)
                    ).toFixed(2);
                } else {
                    return 0;
                }
            }
        },
        eurBugetValue: {
            get() {
                return this.EURBuget;
            }
        },
        bnrAuctionRate: {
            get() {
                if (this.BNRAuctionRate < 1) {
                    return Number(this.item.bnr_currency_value).toFixed(4);
                } else {
                    return Number(this.BNRAuctionRate).toFixed(4);
                }
            }
        },
        acquisitionBuget: {
            get() {
                const buget = Number(
                    (this.estimatedValue * 100) / this.RONBuget
                ).toFixed(2);

                if (isFinite(buget)) {
                    return buget;
                } else {
                    return 0;
                }
            }
        },
        exchangeRateOnDateActive() {
            return !!this.acquisitionRate;
        }
    },
    methods: {
        formatDate() {
            return this.acquisitionRate
                ? dateFormat(this.acquisitionRate.created_at, "dd-mm-yyyy")
                : "";
        }
    }
};
</script>

<style scoped>
.price-analysis-exchange-date {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
}
</style>
