<template>
    <base-material-card :icon="resource.icon" class="pb-8" title="Liste To-Do">
        <v-row class="align-center">
            <v-spacer/>

            <v-col class="text-right" cols="12" sm="3">
                <router-link :to="openTaskRoute">Vezi doar taskurile deschise</router-link>
            </v-col>
        </v-row>

        <tasks-accordion-panel
            :filter="{}"
            :showAddTaskButton="false"
            accordionTitle="Toate Taskurile"
            activate-pagination
            color="189, 189, 189"
            resource="tasks"
            show-header
        />
    </base-material-card>
</template>

<script>
import TasksAccordionPanel from "../../components/tasks/tasksAccordionPanel";

export default {
    components: {TasksAccordionPanel},
    props: ["resource"],
    computed: {
        openTaskRoute() {
            return this.$admin.getResourceLink("my-tasks").link;
        }
    },
    mounted() {
        this.$store.dispatch("getAllDepartments", {vm: this});
    }
};
</script>
