<template>
    <v-select
        v-bind:value="model"
        v-on:input="addFilter($event)"
        :items="departments"
        chips
        small-chips
        deletable-chips
        dense
        label="Task Filter Departments"
        item-text="value"
        item-value="key"
        multiple
        outlined
    >
    </v-select>
</template>

<script>
/**
 * @todo
 */
export default {
    props: {
        model: {
            type: Array,
            default() {
                return []
            }
        },
    },    
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        departments() {
            return Object.values(this.$store.getters.departments)
        }
    },
    methods: {
        addFilter(values) {
            this.$emit('input', values);
        }
    }
};
</script>

<style scoped></style>
