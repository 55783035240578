<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" disable-export>
            <mb-data-table :fields="fields" disable-select>
                <template v-slot:[`field.color`]="{ value }">
                    <v-chip class="w" :color="value">
                        {{ value }}
                    </v-chip>
                </template>
            </mb-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: ["name"],
            fields: [
                {
                    source: "name",
                    sortable: true,
                    width: "20%"
                },
                {
                    source: "description"
                },
                {
                    source: "color",
                    width: "10%"
                }
            ]
        };
    }
};
</script>
