<template>
    <div>
        <v-text-field
            v-model="searchKey"
            :loading="loading"
            dense
            label="Cauta licitatia"
            outlined
            prepend-inner-icon="mdi-magnify"
            @change="initialize"
            @input="initialize"
        />
        <div style="height: 300px;overflow: auto">
            <div v-if="items.length > 0">
                <template>
                    <v-list-group
                        v-for="(item, index) in items"
                        :key="index"
                        :value="true"
                        style="max-width: 400px;"
                    >
                        <template v-slot:activator>
                            <v-list-item
                                class="pl-0"
                                style="max-width: 350px"
                                two-line
                            >
                                <v-list-item-avatar
                                    class="text-white"
                                    color="primary"
                                    left
                                    rounded
                                    size="32"
                                >
                                    <template v-if="item.internal_number">
                                        {{ item.internal_number }}
                                    </template>
                                    <template v-else>
                                        <v-icon color="#fff" size="sm">
                                            fal fa-star-exclamation
                                        </v-icon>
                                    </template>
                                </v-list-item-avatar>
                                <v-list-item-content style="max-width: 380px">
                                    <v-list-item-title
                                        style="color: #000!important;"
                                    >
                                        Licitatie
                                        <strong>{{ item.status.name }}</strong>
                                        din categoria
                                        <strong>{{
                                            item.category.name
                                        }}</strong>
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        style="color: #999!important;"
                                    >
                                        <strong v-show="item.internal_number">
                                        </strong>
                                        {{ item.title }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-list-item
                            v-for="batch in item.batches"
                            :key="batch.id"
                            dense
                            style="width: 350px"
                            @click="selectedItem(batch)"
                        >
                            <v-list-item-title class="pl-6">
                                -> Alege: {{ batch.name }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider :key="index"></v-divider>
                    </v-list-group>
                </template>
                <template>
                    <infinite-loading
                        spinner="spiral"
                        variant="primary"
                        @infinite="infiniteHandler"
                    ></infinite-loading>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: "taskTagSelectorAuctions",
    components: {
        InfiniteLoading
    },
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            searchKey: "",
            loading: false,
            items: []
        };
    },
    methods: {
        selectedItem(item) {
            this.$admin.http
                .post(`api/tasks/tags/`, {
                    task_id: this.task.id,
                    tag_id: item.id,
                    tag_type: "auction_batch"
                })
                .then(() => {
                    this.$emit("selected", item);
                });
        },
        infiniteHandler(state) {
            this.$admin.http
                .get("api/general-search", {
                    params: {
                        query: this.searchKey,
                        page: this.page
                    }
                })
                .then(({ data }) => {
                    if (data.data.length) {
                        this.page += 1;
                        this.items = [...this.items, ...data.data];
                        state.loaded();
                    } else {
                        this.loading = false;
                        state.complete();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => (this.loading = false));
        },
        initialize(val) {
            // Items have already been requested
            if (this.loading) return;
            if (!val) {
                this.items = [];
                return;
            }

            this.loading = true;
            this.searchKey = val;

            // Lazily load input items
            this.$admin.http
                .get("api/general-search", {
                    params: {
                        query: val
                    }
                })
                .then(({ data }) => {
                    this.page = 1;
                    if (data.data.length) {
                        this.items = data.data;
                    } else {
                        this.loading = false;
                        this.items = [];
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>
