<template>
    <v-list-item class="checkbox-item px-0">
        <v-list-item-action class="mr-2 my-2">
            <v-btn
                class=""
                color="#949494"
                dark
                icon
                x-small
                @click="uncheck"
                :loading="loadingCheckbox"
            >
                <v-icon>
                    fal fa-check-square
                </v-icon>
            </v-btn>
        </v-list-item-action>
        <v-list-item-content>
            <v-textarea
                :auto-grow="true"
                class="no-border-bottom text-body-2 checkbox-item__textarea"
                dense
                v-model="item.content"
                disabled
                hide-details
                rows="1"
                value="Vix at paulo diceret ferri ver"
            ></v-textarea>
        </v-list-item-content>
        <!--        <v-list-item-action class="d-flex flex-row">-->
        <!--            <task-responsibles :item="item" />-->
        <!--        </v-list-item-action>-->
    </v-list-item>
</template>

<script>
export default {
    name: "ClosedChecklistItem",
    props: {
        item: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingCheckbox: false
        };
    },
    methods: {
        uncheck() {
            if (this.editable) {
                this.loadingCheckbox = true;
                this.$admin.http
                    .patch(
                        "api/task-checklist-items/" + this.item.id + "/uncheck"
                    )
                    .then(() => {
                        this.$emit("update-checklist-item");
                        this.loadingCheckbox = false;
                    });
            }
        }
    }
};
</script>
