<template>
    <div>
        <v-autocomplete
            v-model="model"
            :disabled="disabled"
            :hide-details="!hint"
            :hint="hint"
            :item-text="itemText"
            :item-value="itemValue"
            :items="data"
            :label="label"
            :persistent-hint="true"
            :search-input.sync="searchValue"
            clearable
            dense
            no-filter
            outlined
            @input="$emit('changing', { items: data, id: $event })"
        >
            <template v-slot:no-data>
                <div class="py-1 px-4 text-body-2 d-flex align-center">
                    <span class="mt-1 mr-2">{{ noResultMessage }}</span>
                    <template v-if="!hideAddButton">
                        <v-btn
                            v-if="searchValue"
                            :disabled="loading"
                            :loading="loading"
                            class="text-none text-decoration-underline"
                            color="primary"
                            small
                            text
                            @click="addItem"
                        >
                            Apasă aici pentru a-l adăuga
                        </v-btn>
                    </template>
                </div>
            </template>
        </v-autocomplete>
        <template v-if="errors">
            <span
                v-for="(error, index) in errors"
                :key="index"
                class="error pa-1 white--text rounded"
            >
                {{ error }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    name: "LiveAutocomplete",
    inheritAttrs: false,
    props: {
        value: {},
        api: {
            type: String,
            required: true
        },
        apiAdditional: {
            type: String,
            default: "",
            required: false
        },
        searchField: {
            type: String,
            default: "q"
        },
        itemText: {
            type: String,
            default: "name"
        },
        itemValue: {
            type: String,
            default: "id"
        },
        label: {
            type: String,
            default: "Selecteaza"
        },
        noResultMessage: {
            type: String,
            default: "Nu există în baza de date."
        },
        errors: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledNote: {
            type: String,
            default: ""
        },
        fieldNameToCreate: {
            type: String,
            default: "name"
        },
        additionalFieldsToCreate: {
            type: Object,
            default() {
                return {};
            }
        },
        hideAddButton: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                value = value || "";
                this.$emit("input", value);
            }
        },
        hint() {
            let hint = "";
            if (this.disabledNote && this.disabled) {
                hint += this.disabledNote;
            }
            return hint;
        }
    },
    data() {
        return {
            searchValue: "",
            data: [],
            loading: false
        };
    },
    methods: {
        loadData() {
            const searchValue = this.searchValue || "";
            return this.$admin.http
                .get(
                    `${this.api}?filter[${this.searchField}]=${searchValue}${this.apiAdditional}`
                )
                .then(({ data }) => {
                    this.data = data.data;
                });
        },
        addItem() {
            this.loading = true;
            this.$admin.http
                .post(`${this.api}`, {
                    [this.fieldNameToCreate]: this.searchValue,
                    ...this.additionalFieldsToCreate
                })
                .then(({ data }) => {
                    this.loadData().then(() => {
                        this.model = data.id;
                        this.loading = false;
                    });
                });
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        searchValue() {
            this.loadData();
        },
        apiAdditional() {
            this.loadData();
        }
    }
};
</script>
