<template>
    <v-btn
        v-if="note.has_children"
        class="text-capitalize font-weight-medium"
        color="#616161"
        small
        text
        @click="toggleDisplayChildren"
    >
        <template v-if="note.children.length > 0">
            <v-icon dark left>mdi-chevron-down</v-icon>
            Ascunde raspunsuri
        </template>
        <template v-else>
            <v-icon dark left>mdi-chevron-up</v-icon>
            Afiseaza raspunsuri
        </template>
    </v-btn>
</template>

<script>
export default {
    name: "ShowHideChildrenButton",
    props: {
        note: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        toggleDisplayChildren() {
            this.loading = true;
            this.$admin.http
                .patch(`/api/auction_notes/show-hide-children/${this.note.id}`)
                .then(() => {
                    this.$emit("saved");
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
