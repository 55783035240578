<template>
    <div>
        <iframe
            ref="iframe"
            :src="iframeUrl"
            class="d-block"
            style="width: 100%; height: 420px"
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "MbFileManager",
    props: {
        auctionId: {
            required: true
        }
    },
    computed: {
        iframeUrl() {
            return `${this.$admin.http.defaults.baseURL}/api/el-finder?base-folder=${this.auctionId}`;
        }
    },
    watch: {
        auctionId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$refs.iframe.contentWindow.location.reload();
            }
        }
    }
};
</script>
