<template>
    <div>
        <v-dialog v-model="dialog" persistent scrollable width="500">
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="select-quotation-container"
                    v-bind="attrs"
                    v-on="on"
                >
                    <div class="text-center pa-3">
                        <v-icon x-large>mdi-cloud-download-outline</v-icon>
                        <div class="mt-3">
                            Sau alege din ofertele existente
                        </div>
                    </div>
                </div>
            </template>
            <v-card>
                <v-toolbar color="primary" dark>
                    Completează următoarele rubrici pentru a putea atașa oferta.
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.supplier_type_id"
                                :errors="errors.product"
                                :hideAddButton="true"
                                api="api/supplier_types"
                                label="Selectează tipul de furnizor:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error,
                                    index) in errors.supplier_type_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.supplier_id"
                                :api-additional="
                                    `&filter[type_id]=${attachmentItemInfo.supplier_type_id}`
                                "
                                :disabled="!attachmentItemInfo.supplier_type_id"
                                :errors="errors.product"
                                :hideAddButton="true"
                                api="api/suppliers"
                                label="Selectează un furnizor:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.supplier_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row v-if="attachmentItemInfo.supplier_type_id === 1">
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.type_id"
                                :disabled="
                                    !(
                                        attachmentItemInfo.supplier_type_id &&
                                        attachmentItemInfo.supplier_id
                                    )
                                "
                                :errors="errors.product"
                                :hideAddButton="true"
                                api="api/product-types"
                                label="Selecteaza tipul de produs:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.type_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.product_id"
                                :additionalFieldsToCreate="{
                                    supplier_id: attachmentItemInfo.supplier_id,
                                    type_id: attachmentItemInfo.type_id
                                }"
                                :apiAdditional="
                                    productAutocompleteApiAdditional
                                "
                                :disabled="productAutocompleteDisabled"
                                :errors="errors.product"
                                :hideAddButton="true"
                                api="api/products"
                                disabled-note="Selectati un Furnizor si un Tip de produs"
                                label="Selectează un model:"
                                noResultMessage="Nu exista oferta pt modelul ales"
                                @changing="addQuotation"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.product_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            Object.keys(attachmentItemInfo.quotation).length > 0
                        "
                    >
                        <v-col class="pa-2" cols="9">
                            <v-text-field
                                :value="attachmentItemInfo.quotation.price"
                                dense
                                disabled
                                hide-details
                                label="Pret model"
                                outlined
                            >
                            </v-text-field>
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.price"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>

                        <v-col class="pa-2">
                            <v-select
                                :items="currencyList"
                                :value="attachmentItemInfo.quotation.currency"
                                dense
                                disabled
                                hide-details
                                item-text="label"
                                item-value="value"
                                label="Moneda"
                                outlined
                            ></v-select>
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.currency"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            Object.keys(attachmentItemInfo.quotation).length > 0
                        "
                    >
                        <v-col class="px-0">
                            <v-list rounded>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        @click="fileLink"
                                        class="v-list-item v-item--active v-list-item--active v-list-item--link theme--light"
                                    >
                                        <v-list-item-icon class="mr-2">
                                            <v-icon
                                                >mdi-clipboard-file-outline
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                >Vezi oferta din
                                                <strong>
                                                    {{
                                                        formatDate(
                                                            attachmentItemInfo
                                                                .quotation
                                                                .created_at
                                                        )
                                                    }}
                                                </strong>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-row>
                        <v-col class="px-4">
                            <v-btn block @click="close">
                                Inchide
                            </v-btn>
                        </v-col>
                        <v-col class="px-4">
                            <v-btn
                                :disabled="
                                    loading ||
                                        !(
                                            attachmentItemInfo.supplier_type_id &&
                                            attachmentItemInfo.supplier_id &&
                                            attachmentItemInfo.type_id &&
                                            attachmentItemInfo.product_id
                                        )
                                "
                                :loading="loading"
                                block
                                color="success"
                                @click="saveQuotation"
                            >
                                ATASEAZA ULTIMA OFERTA
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import dateFormat from "dateformat";
import LiveAutocomplete from "@/components/inputs/LiveAutocomplete";
import ErrorMessage from "../../base/ErrorMessage";

export default {
    name: "SelectQuotation",
    components: { ErrorMessage, LiveAutocomplete },
    props: {
        attachments: {},
        batchId: {
            default: null
        }
    },
    data() {
        return {
            dialog: false,
            errors: {},
            loading: false,
            activeAttachment: 0,
            attachmentItemInfo: {
                supplier_type_id: "",
                supplier_id: "",
                type_id: "",
                product_id: "",
                quotation: {}
            },
            currencyList: [
                {
                    label: "RON",
                    value: "RON"
                },
                {
                    label: "EUR",
                    value: "EUR"
                },
                {
                    label: "USD",
                    value: "USD"
                }
            ]
        };
    },
    computed: {
        productAutocompleteDisabled() {
            return !(
                this.attachmentItemInfo.supplier_id &&
                (this.attachmentItemInfo.type_id ||
                    this.attachmentItemInfo.supplier_type_id !== 1)
            );
        },
        productAutocompleteApiAdditional() {
            const typeFilter = this.attachmentItemInfo.type_id
                ? `&filter[type]=${this.attachmentItemInfo.type_id}`
                : "";
            return `&filter[supplier]=${this.attachmentItemInfo.supplier_id}&include=latest_quotation${typeFilter}`;
        }
    },
    methods: {
        close() {
            this.resetFormData();
            this.dialog = false;
        },
        formatDate(date) {
            return date ? dateFormat(date, "dd-mm-yyyy") : "";
        },
        fileLink() {
            window
                .open(
                    this.attachmentItemInfo.quotation.full_file_path,
                    "_blank"
                )
                .focus();
        },
        resetFormData() {
            this.attachmentItemInfo.supplier_type_id = "";
            this.attachmentItemInfo.supplier_id = "";
            this.attachmentItemInfo.type_id = "";
            this.attachmentItemInfo.product_id = "";
            this.attachmentItemInfo.quotation = {};
        },
        addQuotation(e) {
            if (Number.isInteger(e.id)) {
                this.attachmentItemInfo.quotation = Object.values(e.items).find(
                    element => element.id === e.id
                ).latest_quotation;
            } else {
                this.attachmentItemInfo.quotation = {};
            }
        },
        saveQuotation() {
            this.loading = true;
            const formData = new FormData();

            formData.append(
                "supplier_type_id",
                this.attachmentItemInfo.supplier_type_id
            );
            formData.append("supplier_id", this.attachmentItemInfo.supplier_id);
            formData.append("type_id", this.attachmentItemInfo.type_id);
            formData.append("product_id", this.attachmentItemInfo.product_id);
            formData.append("price", this.attachmentItemInfo.quotation.price);
            formData.append(
                "currency",
                this.attachmentItemInfo.quotation.currency
            );
            formData.append(
                "file_path",
                this.attachmentItemInfo.quotation.file_path
            );

            if (this.batchId) {
                formData.append("batch_id", this.batchId);
            }

            this.$admin.http
                .post("/api/supplier-product-quotation", formData)
                .then(() => {
                    this.$emit("supplier-added");
                })
                .catch(response => {
                    this.errors = response.errors;
                })
                .finally(() => {
                    this.loading = false;

                    this.close();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.select-quotation-container {
    background: #f6f6f6;
    border: 1px dashed #707070;
    border-radius: 5px;
    cursor: pointer;
}
</style>
