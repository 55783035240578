<template>
  <div>
    <v-dialog v-model="confirmdialog" persistent max-width="290">
      <v-card>
        <v-card-text>Are you sure you want to mark this as solved?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> No </v-btn>
          <v-btn color="green darken-1" text @click="deleteDeadline">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-for="(deadline, index) in deadlines" :key="index">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            class="mb-2"
            :close="
              deadline.deadline_at !== item.deadline_at && !deadline.deleted_at
            "
            @click:close="openDialogConfirm(deadline)"
            :color="deadline.color"
            :class="{
              'text-decoration-line-through': deadline.deleted_at,
            }"
          >
            {{ deadline.deadline_at | moment("L") }}
            : {{ deadline.number_of_days }} zile
          </v-chip>
        </template>
        <span>
          {{ deadline.name }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeadlineList",
  inject: {
    listState: { default: undefined },
  },
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      confirmdialog: false,
      deadline_: [],
    };
  },
  computed: {
    deadlines() {
      return this.item.deadlines;
    },
  },
  methods: {
    openDialogConfirm(deadline) {
      this.confirmdialog = true;
      this.deadline_ = deadline;
    },
    close() {
      this.confirmdialog = false;
      this.deadline_ = [];
    },
    deleteDeadline() {
      this.confirmdialog = false;
      this.$admin.http
        .delete(`/api/auction-deadlines/${this.deadline_.id}`)
        .then(() => {
          this.listState.reload();
          this.deadline_ = [];
        })
        .catch(({ response }) => {
          this.$snackbar.error(response.data.message);
          this.deadline_ = [];
        })
        .finally(() => {
          this.loading = false;
          this.deadline_ = [];
        });
    },
  },
};
</script>
