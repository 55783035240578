<template>
    <va-edit-layout>
        <auction-form :id="id" :item="item" :title="title"></auction-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
