<template>
    <va-create-layout>
        <deadlines-schema-form
            :item="item"
            :title="title"
        ></deadlines-schema-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"]
};
</script>
