<template>
    <div>
        <v-card class="pa-3 mb-5">
            <v-tabs v-model="tab">
                <v-tab
                    v-for="(batch, index) in batches"
                    :key="index"
                    class="text-sm-body-2"
                >
                    <batch-tab-item
                        :auction-id="item.id"
                        :batch="batch"
                        @batch-renamed="loadBatches"
                    />
                    <!--                    <v-chip-->
                    <!--                        v-if="countByBatch !== null && countByBatch[item.id][batch.id]"-->
                    <!--                        class="ml-2"-->
                    <!--                        color="red"-->
                    <!--                        text-color="white"-->
                    <!--                    >-->
                    <!--                      {{ Object.values(countByBatch[item.id][batch.id]).length }}-->
                    <!--                    </v-chip>-->
                </v-tab>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :disabled="loading"
                            :loading="loading"
                            class="align-self-center ml-5"
                            color="primary"
                            fab
                            outlined
                            v-bind="attrs"
                            x-small
                            @click="addBatch"
                            v-on="on"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Click aici pentru a crea un lot nou</span>
                </v-tooltip>
            </v-tabs>
        </v-card>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(batch, index) in batches" :key="index">
                <batch-note :batch="batch" :item="item" />
                <batch-offer :batch="batch" />
                <batch-price-analysis
                    v-if="batch.price_analysis"
                    :batch="batch"
                    :item="item"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BatchNote from "./BatchNote";
import BatchOffer from "./BatchOffer";
import BatchPriceAnalysis from "./BatchPriceAnalysis";
import BatchTabItem from "./BatchTabItem";

export default {
    name: "Batches",
    components: { BatchTabItem, BatchOffer, BatchNote, BatchPriceAnalysis },
    props: {
        item: {}
    },
    data() {
        return {
            tab: 0,
            batches: [],
            loading: false
        };
    },
    computed: {
        batchLength() {
            return this.batches.length;
        },
        ...mapGetters(["countByBatch"])
    },
    methods: {
        addBatch() {
            let nextBatchIndex = this.batches.length + 1;
            this.loading = true;

            this.$admin.http
                .post(`/api/auction/${this.item.id}/batches`, {
                    name: "Lot " + nextBatchIndex
                })
                .then(() => {
                    this.loadBatches().then(() => {
                        this.tab = this.batches.length - 1;
                    });
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadBatches() {
            return this.$admin.http
                .get(`/api/auction/${this.item.id}/batches`)
                .then(({ data }) => {
                    this.batches = data;
                });
        }
    },
    mounted() {
        this.loadBatches();
    }
};
</script>
