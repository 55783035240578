var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"position-relative",attrs:{"cols":"12","md":"8"}},[_c('div',{class:{
            'locked-wrapper': !_vm.batch.price_analysis.can_be_edited,
            'locked-wrapper special-height locked':
                _vm.batch.price_analysis.has_sale_option_selected
        }}),_c('v-data-table',{staticClass:"price-analysis-table",attrs:{"headers":_vm.headers,"items":_vm.prices,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"price-analysis-table__input",attrs:{"value":_vm.supplierNameById(item),"hide-details":"","outlined":"","dense":"","disabled":""}})]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{attrs:{"placeholder":"Adauga Pret","number":"","disabled":item.supplier_type_id === 1,"outlined":"","dense":"","hide-details":""},on:{"input":function($event){return _vm.productTotal(item)}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"item.product_item",fn:function(ref){
        var item = ref.item;
return [(item !== undefined && item.supplier_type_id === 1)?_c('v-select',{staticStyle:{"max-width":"335px"},attrs:{"items":_vm.products,"item-text":"name","item-value":"product_id","placeholder":"Selecteaza Produs","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.productSelected($event, item)}},model:{value:(item.product_id),callback:function ($$v) {_vm.$set(item, "product_id", $$v)},expression:"item.product_id"}}):_c('v-text-field',{staticClass:"price-analysis-table__input",attrs:{"hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.changeSelect($event, item)}},model:{value:(item.product_name),callback:function ($$v) {_vm.$set(item, "product_name", $$v)},expression:"item.product_name"}})]}},{key:"item.qty",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{attrs:{"placeholder":"Adauga Cantitate","type":"number","hide-details":"","outlined":"","dense":""},on:{"input":function($event){return _vm.productTotal(item)}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"price-input"},[_vm._v(" "+_vm._s(Number(item.total).toLocaleString("ro-RO", { maximumFractionDigits: 2 }))+" ")])]}},{key:"item.supplier",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.suppliers,"item-text":"name","item-value":"id","placeholder":"Selecteaza Furnizor","outlined":"","dense":"","disabled":item.supplier_type_id === 1,"hide-details":""},on:{"change":_vm.updateProducts},model:{value:(item.supplier_id),callback:function ($$v) {_vm.$set(item, "supplier_id", $$v)},expression:"item.supplier_id"}})]}},{key:"item.actions",fn:function(ref){
        var index = ref.index;
return [_c('span',[(index > 0)?_c('v-btn',{attrs:{"icon":"","x-small":"","dark":"","color":"#949494"},on:{"click":function($event){return _vm.moveItemUp(index)}}},[_c('v-icon',[_vm._v(" fal fa-arrow-square-up ")])],1):_vm._e()],1),_c('span',{staticClass:"v-btn--icon v-size--x-small d-inline-block"},[(index + 1 !== Object.values(_vm.prices).length)?_c('v-btn',{attrs:{"icon":"","x-small":"","dark":"","color":"#949494"},on:{"click":function($event){return _vm.moveItemDown(index)}}},[_c('v-icon',[_vm._v(" fal fa-arrow-square-down ")])],1):_vm._e()],1),_c('v-btn',{attrs:{"icon":"","x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem(index)}}},[_c('v-icon',[_vm._v(" fal fa-minus-circle ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }