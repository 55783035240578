import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ro from "vuetify/es5/locale/ro";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { ro, en },
        current: "ro"
    },
    theme: {
        themes: {
            light: {
                primary: "#4278fc"
            }
        }
    }
});
