<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="statusModel.color" dark v-bind="attrs" v-on="on">
                {{ statusModel.value }}
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-list dense>
                <v-list-item-group
                    v-model="statusModel.key"
                    :color="statusModel.color"
                >
                    <v-list-item
                        v-for="(item, index) in statuses"
                        :key="index"
                        :value="item.key"
                        @click="changeStatus(item.key)"
                    >
                        <v-list-item-title>{{ item.value }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";

export default {
    name: "StatusSelectInput",
    mixins: [HasResourceSingleLink],
    data() {
        return {
            loading: false,
            statusModel: {
                key: this.item.status.id,
                value: this.item.status.name,
                color: this.item.status.color
            },
            statuses: []
        };
    },
    props: {
        item: {
            required: true
        }
    },
    methods: {
        getStatuses() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$admin.http
                    .get(`api/auction_statuses`)
                    .then(({ data }) => {
                        Object.values(data.data).forEach(item => {
                            this.statuses.push({
                                key: item.id,
                                value: item.name
                            });
                        });
                        this.loading = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        async changeStatus(status) {
            if (status !== this.item.status_id) {
                try {
                    await this.$admin.http
                        .put(
                            "/api/" +
                                this.getResourceByStatusId(
                                    this.item.status_id
                                ) +
                                "/" +
                                this.item.id,
                            {
                                status_id: status
                            }
                        )
                        .then(() => {
                            this.$router.push({
                                path:
                                    "/" +
                                    this.getResourceByStatusId(status) +
                                    "/" +
                                    this.item.id
                            });
                        });
                } catch ({ response }) {
                    this.$snackbar.error(response.data.message);
                }
            }
        }
    },
    created() {
        this.getStatuses();
    }
};
</script>
<style lang="scss" scoped>
.selectStatus {
    height: 39px;
}
</style>
