<template>
    <div>
        <update-notification-subscription-button
            v-if="item"
            :item="item"
            @close="closeDialog"
        />

        <base-material-card :icon="resource.icon" :title="title">
            <va-list ref="list" disable-export>
                <mb-data-table :fields="fields" disable-select>
                    <template v-slot:[`item.actions`]="{ resource, item }">
                        <impersonate-button
                            :item="item"
                            :resource="resource"
                            icon
                        ></impersonate-button>

                        <va-action-button
                            :label="$t('users.subscribe_notifications')"
                            color="deep-orange lighten-1"
                            hide-label
                            icon="mdi-bell-outline"
                            text
                            @click="openDialog(item)"
                        ></va-action-button>
                    </template>
                </mb-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
import ImpersonateButton from "@/components/buttons/ImpersonateButton";
import UpdateNotificationSubscriptionButton from "@/components/buttons/UpdateNotificationSubscriptionButton";

export default {
    components: {
        UpdateNotificationSubscriptionButton,
        ImpersonateButton
    },
    props: ["resource", "title"],
    data() {
        return {
            fields: [
                { source: "name", sortable: true },
                { source: "email", type: "email" },
                { source: "departments", type: "array" },
                { source: "roles", type: "array" }
            ],
            item: null
        };
    },
    methods: {
        openDialog(item) {
            this.item = item;
        },
        closeDialog() {
            this.item = null;
        }
    }
};
</script>
