<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" :include="['type', 'supplier']">
            <va-data-table :fields="fields"> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [
                {
                    source: "type",
                    type: "autocomplete",
                    attributes: { reference: "product_types", multiple: true }
                },
                {
                    source: "supplier",
                    type: "autocomplete",
                    attributes: { reference: "suppliers", multiple: true }
                }
            ],
            fields: [
                { source: "name", sortable: true },
                {
                    source: "type",
                    type: "reference",
                    attributes: { reference: "product_types" }
                },
                {
                    source: "supplier",
                    type: "reference",
                    attributes: { reference: "suppliers" }
                }
            ]
        };
    }
};
</script>
