<template>
    <v-dialog v-model="dialog" persistent scrollable width="500">
        <v-card>
            <v-toolbar color="primary" dark>{{ title }} </v-toolbar>
            <v-card-text>
                <div class="flex flex-wrap">
                    <div
                        v-for="categoy in categories"
                        :key="categoy.id"
                        class="w-auto"
                    >
                        <v-checkbox
                            v-model="selected"
                            :label="categoy.name"
                            :value="categoy.id"
                            dense
                            hide-details
                        ></v-checkbox>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">{{ $t("va.close") }}</v-btn>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    @click="saveCategories"
                >
                    {{ $t("va.actions.save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
            loading: false,
            categories: [],
            selected: []
        };
    },
    computed: {
        title() {
            if (this.item) {
                return `Actualizeaza Notificarile pentru ${this.item.name}`;
            } else {
                return "";
            }
        }
    },
    props: {
        item: {
            default() {
                return {};
            }
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        loadCategories() {
            this.$admin.http.get("api/auction_category").then(({ data }) => {
                this.categories = data.data;
            });
        },
        loadCurrentUserSubscribedCategories() {
            this.$admin.http
                .get(`api/users/${this.item.id}/subscribed-categories`)
                .then(({ data }) => {
                    this.selected = data;
                });
        },
        saveCategories() {
            this.loading = true;
            this.$admin.http
                .post(`api/users/${this.item.id}/subscribed-categories`, {
                    categories: this.selected
                })
                .then(() => {
                    this.$admin.toast.success("Categorii salvate cu succes!");
                    this.close();
                })
                .catch(() => {
                    this.$admin.toast.error(
                        "Eroare la salvarea categoriilor. Reincercati!"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        item: {
            deep: true,
            handler() {
                this.loadCurrentUserSubscribedCategories();
            },
            immediate: true
        }
    },
    created() {
        this.loadCategories();
    }
};
</script>
