import { mapState } from "vuex";

export default {

    computed: mapState([
        "notificationTree",
        "unreadNotifications", 
        "countUnreadNotifications",
        "countByStatus", 
        "countByAuction",
        "countByBatch",
        "countAuctions",
        "countArchive",
    ]),

    methods: {
        getUnreadNotifications() {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .get(`/api/get-unread-notification`)
                    .then(({ data }) => {
                        this.$store.commit("setUnreadNotifications", {
                            unreadNotifications: data.data
                        });
                    })
                    .catch(e => reject(e));
            });
        },

        getNotificationTree() {
            this.$admin.http
                .get(`/api/get-notification-tree`)
                .then(({ data }) => {
                    this.$store.commit("setCountNotifications", {
                        notificationTree: data.notificationTree,
                        countByAuction: data.countByAuction,
                        countByBatch: data.countByBatch,
                        countByStatus: data.countByStatus,
                        countAuctions: data.countAuctions,
                        countArchive: data.countArchive
                    });
                });
        }
    }
};