<template>
    <li
        :class="note.children ? 'tree-item' : ''"
        :style="`color: ${note.color}`"
    >
        <div
            class="root-node-line"
            :style="{ height: rootNodeLine }"
            v-if="note.children.length > 0"
        ></div>
        <div
            class="my-3 py-2 pl-6 pr-4 rounded grey lighten-3 root-node"
            style="min-width: 360px; position: relative"
        >
            <slot></slot>
            <div class="curve-path">
                <svg
                    height="12.572"
                    viewBox="0 0 19.979 12.572"
                    width="19.979"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="Path_48"
                        d="M294.554-5086.331h0s-.2,6.24,1.6,9.1,5.6,2.355,5.6,2.355h11.758"
                        fill="none"
                        stroke-width="2"
                        transform="translate(-293.534 5086.362)"
                    />
                </svg>
            </div>
            <div
                class="align-center d-flex font-weight-bold v-list-item__title black--text flex-wrap"
                style="border-top-color: red;"
            >
                {{ note.user.name }}
                <span class="font-italic font-weight-light ml-2">
                    ({{
                        note.created_at | moment("dddd, Do MMMM YYYY, HH:mm")
                    }})
                </span>
                <span
                    v-if="note.is_trashed"
                    class="font-italic font-weight-light text-caption ml-2"
                >
                    <v-icon small>mdi-minus</v-icon> stearsa
                </span>
                <span
                    v-else-if="note.is_edited"
                    class="font-italic font-weight-light text-caption ml-2"
                >
                    <v-icon small>mdi-minus</v-icon> editata
                </span>

                <v-spacer></v-spacer>

                <edit-note
                    v-if="user.id === note.user_id && !note.is_trashed"
                    :auction-id="note.auction_id"
                    :note="note"
                />
                <delete-note-button
                    v-if="user.id === note.user_id && !note.is_trashed"
                    :note="note"
                    @deleted="$emit('note-saved')"
                />
            </div>
            <div class="text-body-1 grey--text text--darken-2">
                {{ note.body }}
            </div>
            <div class="my-3">
                <reply-to-note-button
                    v-if="!note.is_trashed"
                    :note="note"
                    @note-added="$emit('note-saved')"
                />
                <show-hide-children-button
                    :note="note"
                    @saved="$emit('note-saved')"
                />
            </div>
        </div>
        <ul
            v-if="note.children"
            style="list-style-type: none"
            v-observer:subtree.childList="handler"
        >
            <note
                v-for="(children, index) in note.children"
                :key="index"
                :note="children"
                @note-saved="$emit('note-saved')"
            />
        </ul>
    </li>
</template>

<script>
import EditNote from "@/components/resources/auctions/Notes/EditNote";
import { mapState } from "vuex";
import ReplyToNoteButton from "@/components/resources/auctions/Notes/ReplyToNoteButton";
const { observer } = require("vue-mutation-observer");
import DeleteNoteButton from "@/components/resources/auctions/Notes/DeleteNoteButton";
import ShowHideChildrenButton from "@/components/resources/auctions/Notes/ShowHideChildrenButton";

export default {
    name: "Note",
    directives: { observer },
    components: {
        ShowHideChildrenButton,
        DeleteNoteButton,
        ReplyToNoteButton,
        EditNote
    },
    props: {
        note: {}
    },
    data() {
        return {
            elHeight: "0px",
            rootNodeLine: "10px"
        };
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            countByBatch: state => state.countByBatch
        }),
        listItemTreeStyle() {
            if (this.note.children) {
                return {
                    "border-left": "2px solid",
                    color: `${this.note.color}`
                };
            } else {
                return {};
            }
        }
    },
    methods: {
        handler() {
            this.$nextTick(() => {
                if (this.$el !== undefined || this.$el !== null) {
                    var lastChildHeight = "0";
                    var lastChildRootNodeHeight = "0";

                    if (this.$el.querySelector("ul").lastChild !== null) {
                        lastChildHeight = this.$el.querySelector("ul").lastChild
                            .clientHeight;
                    } else {
                        lastChildHeight = "0";
                    }

                    if (this.$el.querySelector("ul").lastChild !== null) {
                        lastChildRootNodeHeight = this.$el
                            .querySelector("ul")
                            .lastChild.querySelector(".root-node").clientHeight;
                    } else {
                        lastChildRootNodeHeight = "0";
                    }

                    this.rootNodeLine =
                        this.$el.clientHeight -
                        lastChildHeight +
                        lastChildRootNodeHeight / 2 +
                        4 +
                        "px";
                }
            });
        }
    },
    created() {
        window.addEventListener("resize", this.handler);
    },
    mounted() {
        this.handler();
    },
    destroyed() {
        window.removeEventListener("resize", this.handler);
    }
};
</script>
