<template>
    <div>
        <template v-if="item.tags.length">
            <v-chip
                v-for="(tag, index) in item.tags"
                :key="index"
                :to="tag.tag_route_object"
                class="my-1"
                close
                link
                :disabled="loading"
                x-small
                @click:close="removeTag(tag)"
            >
                {{ tag.tag_name }}
            </v-chip>
        </template>
        <task-tag-select :item="item" />
    </div>
</template>

<script>
import TaskTagSelect from "@/components/tasks/TaskTagSelector/taskTagSelect";

export default {
    name: "taskTags",
    components: { TaskTagSelect },
    inject: {
        listState: { default: undefined }
    },
    props: {
        item: {}
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        removeTag(tag) {
            this.loading = true;
            this.$admin.http
                .delete(`api/tasks/tags/${tag.id}`)
                .then(() => {
                    this.loading = false;
                    this.listState.reload();
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
