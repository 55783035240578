<template>
    <v-row justify="space-between">
        <v-col cols="12" md="7">
            <v-row>
                <v-col class="pb-0" cols="12">
                    <div class="d-flex justify-space-between align-end">
                        <div class="font-weight-bold text-uppercase">
                            Tabel produse
                        </div>
                        <v-btn
                            class="text-capitalize"
                            color="success"
                            small
                            @click="addProduct"
                        >
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Adauga produs nou
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="price-package-table py-3 px-2 md-px-6">
                        <v-row
                            v-for="(pack, packIndex) in packages"
                            :key="packIndex"
                            class="price-package-table__row"
                        >
                            <v-col class="pr-1 pb-0 pl-0 pr-md-0" cols="7">
                                <span class="text-body-2">
                                    Denumire
                                </span>
                                <v-text-field
                                    v-model="pack.name"
                                    class="price-analysis-table__input mt-2"
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="Introdu denumire produs..."
                                />
                            </v-col>
                            <v-col class="pl-1 pb-0 px-0 pl-md-3" cols="2">
                                <span class="text-body-2 font-weight-bold">
                                    TOTAL RON
                                </span>
                                <span class="price-input mt-2">
                                    {{
                                        Number(
                                            calculateTotalRon(packIndex)
                                        ).toLocaleString("ro-RO", {
                                            maximumFractionDigits: 2
                                        })
                                    }}
                                </span>
                            </v-col>
                            <v-col class="pl-1 pb-0 px-0 pl-md-3" cols="3">
                                <span class="text-body-2 font-weight-bold">
                                    TOTAL EURO Curs Licitatie
                                </span>
                                <span class="price-input mt-2">
                                    {{
                                        Number(
                                            calculateTotalEuro(packIndex)
                                        ).toLocaleString("ro-RO", {
                                            maximumFractionDigits: 2
                                        })
                                    }}
                                </span>
                            </v-col>
                            <v-col class="px-0 pt-0" cols="12">
                                <v-row
                                    v-for="(product,
                                    productIndex) in pack.products"
                                    :key="productIndex"
                                    class="package-wrapper"
                                >
                                    <v-col class="py-0 pr-0" cols="12" md="5">
                                        <span class="text-body-2">
                                            Produs / Serviciu
                                        </span>
                                        <v-select
                                            v-model="product.product"
                                            :items="availableProducts"
                                            dense
                                            hide-details
                                            item-text="product_name"
                                            outlined
                                            placeholder="Selecteaza Produs / Serviciu"
                                            return-object
                                        >
                                            <template
                                                v-slot:item="{ on, item }"
                                            >
                                                <v-list-item
                                                    :disabled="item.disabled"
                                                    v-on="on"
                                                >
                                                    {{ item.product_name }}
                                                </v-list-item>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col
                                        class="pb-0 pr-0 pt-md-0"
                                        cols="12"
                                        md="3"
                                        sm="5"
                                    >
                                        <span class="text-body-2">
                                            Cantitate
                                        </span>
                                        <v-text-field
                                            v-model="product.product.qty"
                                            class="price-analysis-table__input"
                                            dense
                                            disabled
                                            hide-details
                                            outlined
                                            placeholder="Adauga Cantitate"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        class="pb-0 pr-0 pt-md-0"
                                        cols="12"
                                        md="3"
                                        sm="5"
                                    >
                                        <span class="text-body-2">
                                            Total Achizitie
                                        </span>
                                        <span
                                            class="price-input d-flex justify-space-between"
                                        >
                                            {{
                                                Number(
                                                    product.product.total
                                                ).toLocaleString("ro-RO", {
                                                    maximumFractionDigits: 2
                                                })
                                            }}
                                            <strong>EUR</strong>
                                        </span>
                                    </v-col>
                                    <v-col
                                        align-self="end"
                                        cols="1"
                                        md="1"
                                        sm="2"
                                    >
                                        <v-btn
                                            class="float-right"
                                            color="red"
                                            icon
                                            outlined
                                            x-small
                                            @click="
                                                removeProduct(
                                                    packIndex,
                                                    productIndex
                                                )
                                            "
                                        >
                                            <v-icon x-small>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <button
                                            class="primary--text text-body-2"
                                            @click="
                                                pack.products.push({
                                                    product: {
                                                        price: 0,
                                                        qty: 0,
                                                        total: 0,
                                                        product_id: null,
                                                        product_name: '',
                                                        supplier_id: null,
                                                        supplier_type_id: null
                                                    }
                                                })
                                            "
                                        >
                                            + Adauga Produs / Serviciu nou
                                        </button>
                                    </v-col>
                                    <v-col class="text-right">
                                        <button
                                            class="red--text text-body-2"
                                            @click="removePack(packIndex)"
                                        >
                                            - Sterge Produsul
                                        </button>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="5" xl="4">
            <v-row>
                <v-col class="pb-0" cols="12" md="8" sm="6">
                    <div class="font-weight-bold text-uppercase">
                        Preturi TOTALE
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-4">
                <v-col>
                    <div class="price-package-table py-5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <span class="text-body-2 font-weight-bold">
                                    PRET ACHIZITIE
                                </span>
                                <span
                                    class="price-input d-flex justify-space-between mt-3"
                                >
                                    {{
                                        Number(cost).toLocaleString("ro-RO", {
                                            maximumFractionDigits: 2
                                        })
                                    }}
                                    <strong>EUR</strong>
                                </span>
                                <span
                                    class="price-input d-flex justify-space-between mt-3"
                                >
                                    {{
                                        Number(costRon).toLocaleString(
                                            "ro-RO",
                                            {
                                                maximumFractionDigits: 2
                                            }
                                        )
                                    }}
                                    <strong>RON</strong>
                                </span>
                            </v-col>
                            <v-col align="center" cols="12" sm="1">
                                <v-divider
                                    class="hidden-sm-and-up my-2"
                                ></v-divider>
                                <v-divider
                                    class="hidden-xs-only"
                                    vertical
                                ></v-divider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <span class="text-body-2 font-weight-bold">
                                    PRET VANZARE
                                </span>
                                <span
                                    class="price-input d-flex justify-space-between mt-3"
                                >
                                    {{
                                        Number(saleEur).toLocaleString(
                                            "ro-RO",
                                            {
                                                maximumFractionDigits: 2
                                            }
                                        )
                                    }}
                                    <strong>EUR Curs Licitatie</strong>
                                </span>
                                <span
                                    class="price-input d-flex justify-space-between mt-3"
                                >
                                    {{
                                        Number(sale).toLocaleString("ro-RO", {
                                            maximumFractionDigits: 2
                                        })
                                    }}
                                    <strong>RON</strong>
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { findIndex } from "lodash";

export default {
    name: "PriceAnalysisOfferGenerator",
    props: {
        batch: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cost: 0,
            sale: 0,
            packages: [],
            productsFromCalculation: [],
            budget_analysis_bnr_auction_rate: 0,
            budget_analysis_ron_budget: 0
        };
    },
    computed: {
        costRon() {
            return this.cost * this.budget_analysis_bnr_auction_rate;
        },
        saleEur() {
            return this.sale / this.budget_analysis_bnr_auction_rate;
        },
        availableProducts() {
            const callProducts = this.productsFromCalculation.map(item => {
                const newItem = JSON.parse(JSON.stringify(item));
                newItem.disabled = false;
                return newItem;
            });
            const packages = this.packages;

            for (let i = 0; i < packages.length; i++) {
                for (let j = 0; j < packages[i].products.length; j++) {
                    var index = findIndex(
                        callProducts,
                        packages[i].products[j].product
                    );
                    if (index >= 0) {
                        callProducts[index].disabled = true;
                    }
                }
            }

            return callProducts;
        }
    },
    methods: {
        addProduct() {
            if (!this.packages) {
                this.packages = [];
            }
            this.packages.push({
                name: "",
                products: [
                    {
                        product: {
                            price: 0,
                            qty: 0,
                            total: 0,
                            product_id: null,
                            product_name: "",
                            supplier_id: null,
                            supplier_type_id: null
                        }
                    }
                ]
            });
        },
        loadBudgetAnalysis() {
            this.$admin.http
                .get(
                    `/api/price-analysis/product-table/buget-analysis/${this.batch.id}`
                )
                .then(({ data }) => {
                    this.budget_analysis_bnr_auction_rate =
                        data.bnr_auction_rate;
                    this.budget_analysis_ron_budget = data.ron_buget;
                });
        },
        loadAvailableProducts() {
            this.$admin.http
                .get(`/api/price-analysis/product-table/${this.batch.id}`)
                .then(({ data }) => {
                    this.productsFromCalculation = data.data.product_records;
                });
        },
        removeProduct(packageIndex, productIndex) {
            this.packages[packageIndex].products.splice(productIndex, 1);
        },
        removePack(packageIndex) {
            this.packages.splice(packageIndex, 1);
        },
        calculateTotalRon(packageIndex) {
            let total = 0;
            const totalSellPrice = this.sale;
            const totalBuyPrice = this.cost;

            this.packages[packageIndex].products.forEach(product => {
                let currentProductTotal = product.product.total;

                let percentage = (currentProductTotal / totalBuyPrice) * 100;

                let sellPrice = (percentage / 100) * totalSellPrice;

                total += sellPrice;
            });
            return Math.round((total + Number.EPSILON) * 100) / 100;
        },
        calculateTotalEuro(packageIndex) {
            const calculateTotalRon = this.calculateTotalRon(packageIndex);
            const totalEuro =
                calculateTotalRon / this.budget_analysis_bnr_auction_rate;
            return Math.round(totalEuro + Number.EPSILON);
        },
        loadPrices() {
            this.$admin.http
                .get(
                    `/api/price-analysis/price-generator/${this.batch.id}/totals`
                )
                .then(({ data }) => {
                    this.cost = data.cost;
                    this.sale = data.sale;
                });
        },
        save() {
            this.$admin.http
                .post(`/api/price-analysis/price-generator/${this.batch.id}`, {
                    packages: this.packages
                })
                .then(() => {});
        },
        load() {
            this.$admin.http
                .get(`/api/price-analysis/price-generator/${this.batch.id}`)
                .then(({ data }) => {
                    this.packages = data;
                });
        }
    },
    mounted() {
        this.loadBudgetAnalysis();
        this.loadAvailableProducts();
        this.loadPrices();
        this.load();
    }
};
</script>

<style lang="scss" scoped>
.price-package-table {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    padding: 5px 10px;

    &__row {
        margin: 0;

        &:not(:last-child) {
            border-bottom: 1px solid #eeeeee;
        }
    }

    .package-wrapper {
        border-left: 8px solid #f8f8f8;
        border-radius: 5px;
        margin: 10px 0;
    }
}
</style>
