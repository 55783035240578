<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>

        <v-card>
            <v-toolbar color="primary" dark>
                <slot name="title">
                    Adauga notita noua
                </slot>
            </v-toolbar>

            <v-card-text>
                <v-textarea
                    v-model="body"
                    label="Notita"
                    name="input-7-4"
                    outlined
                ></v-textarea>
                <template v-if="errors">
                    <span
                        v-for="(error, index) in errors.body"
                        :key="index"
                        class="error pa-1 white--text rounded"
                        >{{ error }}
                    </span>
                </template>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="disabled" text @click="dialog = false">
                    Renunta
                </v-btn>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    text
                    @click="addNote"
                >
                    Adauga
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "AddNoteDialog",
    props: {
        auctionId: {},
        batchId: {},
        noteId: {}
    },
    data() {
        return {
            dialog: false,
            body: "",
            errors: {},
            loading: false
        };
    },
    methods: {
        addNote() {
            this.loading = true;
            this.errors = {};
            this.$admin.http
                .post(`/api/auction_notes`, {
                    body: this.body,
                    auction_id: this.auctionId,
                    batch_id: this.batchId,
                    parent_id: this.noteId
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit("note-added");
                    this.body = "";
                })
                .catch(error => {
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
