<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" disable-export>
            <mb-data-table :fields="fields" disable-select></mb-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: ["name"],
            fields: [{ source: "name", sortable: true }]
        };
    }
};
</script>
