<template>
    <va-edit-layout>
        <suppliers-form :id="id" :title="title" :item="item"></suppliers-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
