<template>
    <div>
        <v-list dense>
            <template v-if="files.length">
                <file-item
                    v-for="(item, index) in files"
                    :key="index"
                    :item="item"
                    @file-downloaded="loadFiles"
                />
            </template>
            <template v-else>
                <template v-if="loaded">
                    Nu exista fisiere atasate.
                </template>
                <template v-else>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </template>
            </template>
        </v-list>
    </div>
</template>
<script>
import FileItem from "@/components/resources/auctions/FileItem";
export default {
    name: "FileList",
    components: { FileItem },
    props: {
        auctionId: {
            required: true
        }
    },
    data() {
        return {
            files: {},
            loaded: false
        };
    },
    methods: {
        loadFiles() {
            this.$admin.http
                .get(`/api/auction_files?filter[auction]=${this.auctionId}`)
                .then(({ data }) => {
                    this.files = data.data;
                })
                .finally(() => {
                    this.loaded = true;
                });
        }
    },
    mounted() {
        this.loadFiles();
    }
};
</script>
