<template>
    <base-material-card
        class="v-card--material-chart"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot slot="heading" name="heading" />

        <slot slot="reveal-actions" name="reveal-actions" />

        <div :style="{ maxHeight: height }">
            <apexchart
                ref="chart"
                :type="type"
                :options="options"
                :series="series"
                :height="height"
            ></apexchart>
        </div>

        <slot slot="actions" name="actions" />
    </base-material-card>
</template>

<script>
export default {
    name: "MaterialChartCard",
    inheritAttrs: false,
    props: {
        series: {
            required: true
        },
        options: {
            required: true
        },
        type: {
            type: String,
            required: true
        },
        height: {
            type: String,
            default: "200px"
        }
    },
    methods: {
        refresh() {
            this.$refs.chart.refresh();
        }
    },
    watch: {
        options: {
            deep: true,
            handler() {
                this.refresh();
            }
        }
    }
};
</script>

<style lang="sass">
.v-card--material-chart
  p
    color: #999

  .v-card--material__heading


    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(255,255,255,.4)
</style>
