<template>
    <base-material-card>
        <template v-slot:heading>
            <div class="display-2">
                <div class="d-flex justify-space-between align-center">
                    Notite
                    <AddNoteButton
                        :auction-id="item.id"
                        :batch-id="batch.id"
                        @note-added="reloadNotes"
                    />
                </div>
            </div>
        </template>
        <v-card-text>
            <Notes ref="notes" :auction-id="item.id" :batch-id="batch.id" />
        </v-card-text>
    </base-material-card>
</template>

<script>
import Notes from "@/components/resources/auctions/Notes/Notes";
import AddNoteButton from "@/components/resources/auctions/Notes/AddNoteButton";
export default {
    name: "BatchNote",
    components: { AddNoteButton, Notes },
    props: {
        item: {},
        batch: {}
    },
    methods: {
        reloadNotes() {
            this.$refs.notes.getNotes();
        }
    }
};
</script>

<style scoped></style>
