<template>
    <div v-if="showNewTaskRow" class="new-task-wrapper">
        <div class="new-task-wrapper__row">
            <div class="new-task-wrapper__row-title">
                <v-text-field
                    v-model="newTaskTitle"
                    dense
                    hide-details
                    @keyup.enter="addTask"
                    label="Introdu denumire task"
                ></v-text-field>
                <template v-if="errors">
                    <error-message
                        v-for="(error, index) in errors.title"
                        :key="index"
                        :message="error"
                    />
                </template>
            </div>
            <div class="new-task-wrapper__row-actions">
                <v-btn color="#4CAF50" small @click="addTask">Salveaza</v-btn>
                <v-btn class="white ml-3" icon @click="cancel">
                    <v-icon color="#666666" fab>
                        fa fa-times
                    </v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorMessage from "../base/ErrorMessage";

export default {
    name: "addNewTask",
    inject: {
        listState: {
            default: undefined
        }
    },
    components: { ErrorMessage },
    props: {
        resource: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showNewTaskRow: false,
            newTaskTitle: "",
            errors: {}
        };
    },
    methods: {
        cancel() {
            this.newTaskTitle = "";
            this.showNewTaskRow = !this.showNewTaskRow;
        },
        addTask() {
            this.$admin.http
                .post(`api/${this.resource}`, {
                    title: this.newTaskTitle
                })
                .then(() => {
                    this.listState.reload();
                    this.showNewTaskRow = false;
                    this.newTaskTitle = "";
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.errors = error.errors;
                    } else {
                        console.log(error.message);
                    }
                });
        }
    }
};
</script>
