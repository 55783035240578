<template>
    <div>
        <div v-if="suppliers.length > 0" class="attachments-list mt-8">
            <div
                v-for="(supplier, index) in suppliers"
                :key="index"
                class="py-4 d-block"
            >
                <v-row class="mb-1 mx-0 d-inline-block">
                    <div
                        class="grey lighten-3 rounded py-3 px-6 primary--text font-weight-bold text-h4"
                    >
                        {{ supplier.name }}
                    </div>
                </v-row>
                <v-row
                    v-for="(quotation, index) in supplier.quotations"
                    :key="index"
                    class="attachments-list__item grey lighten-3 d-flex flex-wrap justify-space-between align-center mb-3 mx-0 pa-3 rounded"
                >
                    <v-col
                        class="attachments-list__item-title text--grey darken-2 pa-0 pa-md-3"
                        cols="12"
                        md="4"
                        sm="3"
                    >
                        {{ quotation.product.name }}
                    </v-col>
                    <v-col
                        class="attachments-list__item-price text--grey darken-2 pa-0 pa-md-3"
                        cols="12"
                        sm="2"
                    >
                        {{ quotation.price }} {{ quotation.currency }}
                    </v-col>
                    <v-col
                        class="attachments-list__item-date text--grey darken-2 pa-0 pa-md-3"
                        cols="12"
                        sm="2"
                    >
                        {{ quotation.created_at | moment("L") }}
                    </v-col>
                    <v-col
                        class="attachments-list__item-price-evolution text--grey darken-2 pa-0 pa-md-3 d-flex items-center"
                        cols="12"
                        sm="2"
                    >
                        <apexchart
                            type="area"
                            height="50"
                            :options="chartOptions"
                            :series="[
                                {
                                    data: quotation.product.quotations.map(
                                        item => ({
                                            x: item.created_at,
                                            created: item.created_at_verbose,
                                            y: item.price,
                                            currency: item.currency
                                        })
                                    )
                                }
                            ]"
                        ></apexchart>
                    </v-col>
                    <v-col
                        align="right"
                        class="attachments-list__item-actions"
                        cols="12"
                        md="2"
                        sm="3"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="grey lighten-3"
                                    icon
                                    large
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="sendOffer(quotation)"
                                >
                                    <v-icon color="grey darken-2"
                                    >mdi-calculator</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>Analiza pret</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="grey lighten-3"
                                    icon
                                    large
                                    v-bind="attrs"
                                    :href="quotation.full_file_path"
                                    v-on="on"
                                >
                                    <v-icon color="grey darken-2"
                                        >mdi-file-download-outline</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>Descarca oferta</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="grey lighten-3"
                                    icon
                                    large
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="removeQuotation(quotation.id)"
                                    :loading="loading"
                                    :disabled="loading"
                                >
                                    <v-icon color="grey darken-2">
                                        mdi-window-close
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Elimina oferta din licitatie. <br />
                                <strong>Atentie:</strong> oferta va ramane in
                                lista de oferte.
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import OfferAnalysis from "../../../events/OfferAnalysis";

export default {
    props: {
        suppliers: {
            required: true,
            default() {
                return [];
            }
        },
        batch: {
            required: true
        }
    },
    data() {
        return {
            loading: false,
            chartOptions: {
                chart: {
                    type: "area",
                    height: 160,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    curve: "straight"
                },
                fill: {
                    opacity: 0.3
                },
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    custom({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            '<div class="px-1"><strong>' +
                            w.globals.initialSeries[seriesIndex].data[
                                dataPointIndex
                            ].created +
                            "</strong><span>: " +
                            series[seriesIndex][dataPointIndex] +
                            " " +
                            w.globals.initialSeries[seriesIndex].data[
                                dataPointIndex
                            ].currency +
                            "</span>" +
                            "</div>"
                        );
                    }
                }
            }
        };
    },
    methods: {
        sendOffer(quotation) {
            OfferAnalysis.$emit('sendOfferToAnalysis', quotation);
        },
        localeDate(date) {
            return this.$d(new Date(date), "long");
        },
        removeQuotation(quotationId) {
            this.loading = true;

            this.$admin.http
                .delete(
                    `/api/auction/${this.batch.auction_id}/batches/${this.batch.id}/quotations/${quotationId}`,
                    {
                        name: this.batchName
                    }
                )
                .then(() => {
                    this.$emit("quotation-deleted");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
