var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"tasks-wrapper flex-column",style:(("background-color: rgba(" + _vm.color + ", 0.07); border-color: rgb(" + _vm.color + ")"))},[_c('div',{staticClass:"accordion-panel"},[_c('div',{staticClass:"accordion-panel__header",style:(("background: rgb(" + _vm.color + ")")),on:{"click":_vm.toggleState}},[_vm._v(" "+_vm._s(_vm.accordionTitle)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentState),expression:"currentState"}]},[_c('v-icon',{staticClass:"white--text ml-3",attrs:{"small":""}},[_vm._v(" fal fa-chevron-up ")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentState),expression:"!currentState"}]},[_c('v-icon',{staticClass:"white--text ml-3",attrs:{"small":""}},[_vm._v(" fal fa-chevron-down ")])],1)]),_c('div',{staticClass:"accordion-panel__content",class:_vm.currentState ? 'open' : ''},[_c('va-list',{ref:"taskDataTable",attrs:{"disable-pagination":!_vm.activatePagination,"filter":_vm.filter,"hide-header":!_vm.showHeader,"include":[
                    'userCommentsCount',
                    'checklistItemsCount',
                    'completedChecklistItemsCount'
                ],"resource":_vm.resource,"disable-create":"","disable-export":"","disable-query-string":"","items-per-page":_vm.activatePagination ? 15 : -1}},[(_vm.currentState)?_c('va-data-table',{staticClass:"accordion-panel__content-table",attrs:{"disable-pagination":true,"fields":_vm.fields,"disable-actions":"","disable-delete":"","disable-select":""},scopedSlots:_vm._u([{key:"field.is_complete",fn:function(ref){
                var item = ref.item;
return [_c('task-status',{attrs:{"item":item}})]}},{key:"field.title",fn:function(ref){
                var item = ref.item;
return [_c('task-view',{attrs:{"item":item}})]}},{key:"field.department",fn:function(ref){
                var item = ref.item;
return [_c('task-department',{attrs:{"item":item}})]}},{key:"field.responsibles",fn:function(ref){
                var item = ref.item;
return [_c('task-responsibles',{attrs:{"item":item}})]}},{key:"field.created_by",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(item.user.name)+" ")])]}},{key:"field.watchers",fn:function(ref){
                var item = ref.item;
return [_c('task-watchers',{attrs:{"item":item}})]}},{key:"field.deadline",fn:function(ref){
                var item = ref.item;
return [_c('task-deadline',{attrs:{"item":item}})]}},{key:"field.tags",fn:function(ref){
                var item = ref.item;
return [_c('task-tags',{attrs:{"item":item}})]}},{key:"field.priority",fn:function(ref){
                var item = ref.item;
return [_c('task-priority',{attrs:{"item":item}})]}},{key:"field.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"L, h:mm"))+" ")])]}},{key:"field.remove",fn:function(ref){
                var item = ref.item;
return [_c('task-private-remove',{attrs:{"item":item}})]}}],null,true)}):_vm._e(),_c('add-new-task',{ref:"showTaskWrapper",class:!_vm.currentState ? 'open' : '',attrs:{"resource":_vm.resource,"endpoint":"resource"}})],1)],1),(_vm.showAddTaskButton)?_c('div',{staticClass:"new-task-wrapper__activator"},[_c('span',{on:{"click":function($event){_vm.$refs.showTaskWrapper.showNewTaskRow = true}}},[_vm._v(" + Adauga Task Nou ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }