<template>
    <v-col cols="12" lg="7" class="position-relative">
        <div
            :class="{
                'locked-wrapper': !batch.price_analysis.can_be_edited,
                'locked-wrapper locked':
                    batch.price_analysis.has_sale_option_selected
            }"
        ></div>
        <v-row class="price-analysis-exchange-wrapper mx-0 ml-0 pa-1 py-4">
            <v-col class="pl-2" cols="12" xl="7">
                <v-row>
                    <v-col class="py-0 pl-2">
                        <span class="font-weight-bold">
                            ACHIZITIE CURS BT LA ZI
                        </span>
                    </v-col>
                </v-row>

                <v-row class="mt-xl-7">
                    <v-col class="pl-2" cols="9">
                        <v-row align="center">
                            <v-col class="pb-0" cols="12" xl="4">
                                <span class="text-body-2 font-weight-bold">
                                    ACHIZITIE EURO
                                </span>
                            </v-col>
                            <v-col class="pr-1 py-0" cols="6" xl="4">
                                <span class="text-body-2 grey--text">
                                    Suma totala
                                </span>
                                <span class="price-input">
                                    {{
                                        Number(
                                            estimatedValueEuro
                                        ).toLocaleString("ro-RO", {
                                            maximumFractionDigits: 3
                                        })
                                    }}
                                </span>
                            </v-col>
                            <v-col class="pl-1 py-0" cols="6" xl="4">
                                <span class="text-body-2 grey--text">
                                    Curs BT zi
                                </span>
                                <v-text-field
                                    :append-icon="
                                        !acquisitionRate
                                            ? 'mdi-lock-open-variant'
                                            : 'mdi-lock'
                                    "
                                    :value="
                                        currencies.BT !== undefined
                                            ? currencies.BT.rate
                                            : 0
                                    "
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="0,0000"
                                    readonly
                                    @click:append="toggleLockPrice"
                                    class="price-analysis-table__input with-icon"
                                />
                            </v-col>
                        </v-row>

                        <v-row align="center" class="mt-3">
                            <v-col class="pb-0" cols="12" xl="4">
                                <span class="text-body-2 font-weight-bold">
                                    ACHIZITIE RON
                                </span>
                            </v-col>
                            <v-col
                                class="pt-0 pb-xl-0 pt-xl-3"
                                cols="12"
                                xl="8"
                            >
                                <span class="price-input">
                                    {{
                                        Number(estimatedValue).toLocaleString(
                                            "ro-RO",
                                            {
                                                maximumFractionDigits: 2
                                            }
                                        )
                                    }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        class="pl-0 mt-6 mt-xl-0 pr-2 pt-xl-0 mb-6 mb-xl-3"
                        cols="3"
                    >
                        <span class="text-body-2 grey--text">% Buget</span>
                        <span
                            class="price-input d-flex align-center"
                            style="max-height: 100%; height: 100%;"
                            :class="
                                eurBugetPercentage > 100 ? 'error--text' : ''
                            "
                        >
                            {{
                                Number(eurBugetPercentage).toLocaleString(
                                    "ro-RO",
                                    {
                                        maximumFractionDigits: 2
                                    }
                                )
                            }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="text-center px-2 py-xl-2" cols="12" xl="1">
                <v-divider class="hidden-xl-only my-2"></v-divider>
                <v-divider class="hidden-lg-and-down" vertical></v-divider>
            </v-col>
            <v-col class="" cols="12" xl="4">
                <v-row>
                    <v-col class="py-0 pl-2">
                        <span class="font-weight-bold">
                            BUGET LICITATIE / LOT
                        </span>
                    </v-col>
                </v-row>
                <v-row align="center" class="mt-xl-7">
                    <v-col class="pb-0 pl-2 pl-xl-0" cols="3" xl="2">
                        <span class="text-body-2 font-weight-bold">EURO</span>
                    </v-col>
                    <v-col class="py-0 pr-1 pl-0" cols="4">
                        <span class="text-body-2 grey--text">Buget</span>
                        <span class="price-input">
                            {{
                                Number(eurBugetValue).toLocaleString("ro-RO", {
                                    maximumFractionDigits: 2
                                })
                            }}
                        </span>
                    </v-col>
                    <v-col class="py-0 pl-1 pr-2" cols="5" xl="6">
                        <span class="text-body-2 grey--text">
                            Curs BNR licitatie
                        </span>
                        <v-text-field
                            v-model="bnrAuctionRate"
                            class="price-analysis-table__input"
                            dense
                            hide-details
                            outlined
                            placeholder="0,0000"
                        />
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col class="pb-0 pl-2 pl-xl-0" cols="3" xl="2">
                        <span class="text-body-2 font-weight-bold">RON</span>
                    </v-col>
                    <v-col class="pb-0 pl-0 pr-2" cols="9" xl="10">
                        <v-text-field
                            v-model="ronBugetValue"
                            class="price-analysis-table__input"
                            dense
                            hide-details
                            outlined
                            placeholder="0,0000"
                            type="number"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import OfferAnalysis from "../../../events/OfferAnalysis";

export default {
    name: "PriceAnalysisExchange",

    props: {
        batch: {
            type: Object,
            required: true,
            default: () => {}
        },
        item: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            acquisition_buget: 0
        };
    },
    computed: {
        ...mapGetters({
            acquisitionRate: "price_analysis/acquisition_rate",
            priceAnalysis: "price_analysis/price_analysis",
            productTotal: "price_analysis/product_total",
            currencies: "price_analysis/currencies",
            BNRAuctionRate: "price_analysis/bnr_auction_rate",
            RONBuget: "price_analysis/ron_buget",
            EURBuget: "price_analysis/eur_buget"
        }),
        totalPenaltyPercentage: {
            get() {
                return this.priceAnalysis.penalty &&
                    this.priceAnalysis.penalty.active
                    ? this.priceAnalysis.penalty.total_percentage
                    : 0;
            }
        },
        bnrAuctionRate: {
            get() {
                if (this.BNRAuctionRate < 1) {
                    return Number(this.item.bnr_currency_value).toFixed(4);
                } else {
                    return Number(this.BNRAuctionRate).toFixed(4);
                }
            },
            set(value) {
                if (parseInt(value) !== 0) {
                    setTimeout(() => {
                        this.$store.dispatch(
                            "price_analysis/updateAuctionBuget",
                            {
                                vm: this,
                                payload: {
                                    bnr_auction_rate: value,
                                    ron_buget: this.RONBuget
                                }
                            }
                        );
                    }, 1000);
                }
            }
        },
        ronBugetValue: {
            get() {
                return this.RONBuget;
            },
            set(value) {
                if (parseInt(value) !== 0) {
                    setTimeout(() => {
                        this.$store
                            .dispatch("price_analysis/updateAuctionBuget", {
                                vm: this,
                                payload: {
                                    bnr_auction_rate: this.bnrAuctionRate,
                                    ron_buget: value
                                }
                            })
                            .then(() => {
                                // reload Sale Options
                                OfferAnalysis.$emit("reloadOptions");
                            });
                    }, 1000);
                }
            }
        },
        eurBugetPercentage: {
            get() {
                return (
                    (this.estimatedValue * 100) /
                    this.ronBugetValue
                ).toFixed(2);
            }
        },
        eurBugetValue: {
            get() {
                return this.EURBuget;
            }
        },
        estimatedValue: {
            get() {
                return this.currencies.BT !== undefined
                    ? Number(
                          this.productTotal * this.currencies.BT.rate -
                              this.productTotal *
                                  this.currencies.BT.rate *
                                  (this.totalPenaltyPercentage / 100)
                      ).toFixed(2)
                    : 0;
            }
        },
        estimatedValueEuro: {
            get() {
                return Number(
                    this.productTotal -
                        this.productTotal * (this.totalPenaltyPercentage / 100)
                ).toFixed(2);
            }
        },
        acquisitionBugetPrice: {
            get() {
                return this.acquisition_buget.toFixed(2) + "%";
            },
            set(value) {
                this.acquisition_buget = value;
            }
        }
    },
    methods: {
        toggleLockPrice() {
            if (!this.acquisitionRate) {
                this.$admin.http
                    .post(`/api/price-analysis/course-acquisition`, {
                        price_analysis_id: this.priceAnalysis.id,
                        currency_rate_id: this.currencies.BT.id
                    })
                    .then(({ data }) => {
                        this.$store.commit(
                            "price_analysis/updateAcquisitionRate",
                            data.data
                        );

                        OfferAnalysis.$emit("reloadOptions");
                    });
            } else {
                this.$admin.http
                    .delete(
                        `/api/price-analysis/course-acquisition/${this.acquisitionRate.id}`
                    )
                    .then(({ data }) => {
                        this.$store.commit(
                            "price_analysis/updateAcquisitionRate",
                            data.data
                        );

                        OfferAnalysis.$emit("reloadOptions");
                    });
            }
        }
    }
};
</script>

<style scoped>
.price-analysis-exchange-wrapper {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
}

.currency-lock {
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    user-select: none;
}

.currency-lock input:focus {
    color: red;
}
</style>
