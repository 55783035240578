<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list
            :filters="filters"
            :include="[
                'category',
                'status',
                'interest',
                'customer',
                'deadlines'
            ]"
            disable-export
        >
            <template v-slot:actions>
                <deadline-type-select />
            </template>
            <mb-data-table
                :dense="false"
                :fields="fields"
                class="overflow-auto"
                disable-select
            >
                <template v-slot:[`field.estimated_value`]="{ item }">
                    <template v-if="item.currency === 'EUR'">
                        <div class="font-weight-bold success--text">
                            {{ item.estimated_value | formatNumber }}
                            {{ item.currency }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="font-weight-bold success--text">
                            {{ item.estimated_value_euro | formatNumber }}
                            {{ item.estimated_value_currency }}
                        </div>
                        <div>
                            {{ item.estimated_value | formatNumber }}
                            {{ item.currency }}
                        </div>
                    </template>
                </template>

                <template v-slot:[`field.title`]="{ item }">
                    <div class="mb-2">
                        <router-link
                            :to="{
                                name: 'auction-new_show',
                                params: { id: item.id }
                            }"
                            class="auction-title"
                        >
                            {{ item.title }}
                        </router-link>
                    </div>
                    <div class="mb-2">
                        Client:
                        <template v-if="item.customer">
                            <span class="font-weight-bold" style="color:#555;">
                                {{ item.customer.name | capitalize }}
                            </span>
                        </template>
                        <template v-else>-</template>
                    </div>
                    <div>
                        <a :href="item.provider_link" target="_blank">
                            <span class="font-weight-bold pt-3 text-sm-body-2">
                                Cod licitatie: {{ item.external_number }}
                            </span>
                        </a>
                    </div>
                </template>

                <template v-slot:[`field.source`]="{ item }">
                    <v-chip
                        :href="item.external_url"
                        :link="!!item.external_url"
                        class="w"
                        dark
                        target="_blank"
                    >
                        {{ item.source }}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <deadline-select :item="item" />
                    <toggle-featured-auction :item="item" />
                </template>
            </mb-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import DeadlineSelect from "../../components/auctions/deadlineSelect";
import DeadlineTypeSelect from "@/components/auctions/DeadlineTypeSelect";
import ToggleFeaturedAuction from "@/components/auctions/ToggleFeaturedAuction";

export default {
    components: { ToggleFeaturedAuction, DeadlineTypeSelect, DeadlineSelect },
    props: ["resource", "title"],
    data() {
        return {
            filters: [
                {
                    source: "status",
                    type: "autocomplete",
                    attributes: {
                        reference: "auction_statuses",
                        multiple: true
                    }
                },
                {
                    source: "category",
                    type: "autocomplete",
                    attributes: {
                        reference: "auction_category",
                        multiple: true
                    }
                },
                {
                    source: "interest",
                    type: "autocomplete",
                    attributes: {
                        reference: "auction_interests",
                        multiple: true,
                        sortBy: ["name"]
                    }
                }
            ],
            fields: [
                {
                    source: "category",
                    type: "reference",
                    attributes: {
                        reference: "auction_category",
                        text: "name",
                        chip: true,
                        color: item => item.color
                    },
                    align: "center",
                    width: "140px"
                },

                {
                    source: "source",
                    sortable: true,
                    align: "center",
                    width: "140px"
                },
                {
                    source: "title",
                    sortable: true,
                    width: "350px"
                },
                {
                    source: "interest_id",
                    type: "simple-select",
                    attributes: {
                        reference: "auction_interests",
                        itemsPerPage: 1000,
                        sortBy: ["name"]
                    },
                    editable: true,
                    width: "140px"
                },
                {
                    source: "county",
                    width: "140px"
                },
                {
                    source: "estimated_value",
                    sortable: true,
                    width: "170px"
                },
                {
                    source: "status_id",
                    label: "Status",
                    type: "status",
                    editable: true,
                    align: "center"
                },
                {
                    source: "deadline_at",
                    type: "date",
                    sortable: true,
                    attributes: {
                        format: "shortWithTime"
                    },
                    width: "120px"
                },
                {
                    source: "original_created_at",
                    type: "date",
                    sortable: true,
                    attributes: {
                        format: "short"
                    },
                    width: "120px"
                }
            ]
        };
    }
};
</script>
<style lang="scss" scoped>
a.auction-title {
    font-size: 0.875rem;
    color: #333;
    text-decoration: none;

    &:hover {
        color: #4278fc;
        text-decoration: underline;
    }
}
</style>
