var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('base-material-card',{attrs:{"title":"Licitatii cu deadline in urmatoarele 7 zile"}},[_c('va-list',{attrs:{"filters":_vm.filters,"include":[
                    'category',
                    'status',
                    'interest',
                    'customer',
                    'deadlines'
                ],"resource":"auction-for-dashboard"}},[_c('mb-data-table',{staticClass:"overflow-auto",attrs:{"dense":false,"fields":_vm.fields,"item-class":_vm.itemRowBackground,"disable-select":""},scopedSlots:_vm._u([{key:"field.estimated_value",fn:function(ref){
                var item = ref.item;
return [(item.currency === 'EUR')?[_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value))+" "+_vm._s(item.currency)+" ")])]:[_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value_euro))+" "+_vm._s(item.estimated_value_currency)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value))+" "+_vm._s(item.currency)+" ")])]]}},{key:"field.deadlines",fn:function(ref){
                var item = ref.item;
return [(item.deadlines)?_c('div',[_c('deadline-list',{attrs:{"item":item}})],1):_vm._e()]}},{key:"field.title",fn:function(ref){
                var item = ref.item;
return [_c('title-tooltip',{attrs:{"item":item}}),_c('div',[_c('a',{attrs:{"href":item.provider_link,"target":"_blank"}},[_c('span',{staticClass:"font-weight-bold pt-3 text-sm-body-2"},[_vm._v(" Cod licitatie: "+_vm._s(item.external_number)+" ")])])])]}},{key:"item.actions",fn:function(ref){
                var resource = ref.resource;
                var item = ref.item;
return [_c('deadline-select',{attrs:{"item":item}}),_c('va-show-button',{attrs:{"item":item,"resource":_vm.getResourceByStatusId(item.status_id),"icon":""}}),_c('toggle-featured-auction',{attrs:{"item":item}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }