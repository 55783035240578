<template>
    <v-tooltip bottom class="pa-0">
        <template v-slot:activator="{ on: onTooltip }">
            <v-btn
                class="activator-button ml-n2"
                dark
                icon
                small
                v-on="{ ...onTooltip }"
            >
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    class="remove-button"
                    color="primary"
                    fab
                    x-small
                    @click.stop="removeUser(responsible)"
                >
                    <v-icon dark>mdi-close-circle</v-icon>
                </v-btn>

                <v-avatar color="primary" size="30">
                    <span>
                        {{ responsible.name.match(/\b(\w)/g).join("") }}
                    </span>
                </v-avatar>
            </v-btn>
        </template>
        <span class="text-caption">Elimina pe {{ responsible.name }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "responsible-item",
    inject: {
        listState: { default: undefined }
    },
    props: {
        item: {
            required: true
        },
        responsible: {
            required: true
        },
        endpoint: {
            type: String,
            default: "api/user/responsibles"
        },
        payloadName: {
            type: String,
            default: "responsibles"
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        removeUser(user) {
            this.loading = true;
            new Promise((resolve, reject) => {
                let payload = {}
                payload[this.payloadName] = user.id
                this.$admin.http
                    .put(`/${this.endpoint}/remove/${this.item.id}`, payload)
                    .then(({ data }) => {
                        if (this.listState) {
                            this.$store.dispatch(
                                "api/refresh",
                                this.$route.meta.resource
                            );
                        } else {
                            this.item.users = data.data.users;
                        }
                        resolve();
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loading = false;
                    });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.activator-button {
    z-index: 0;

    &:first-of-type {
        z-index: 1;
    }

    .remove-button {
        position: absolute;
        z-index: 1;
        display: none;
    }

    &:hover {
        z-index: 2;

        .v-avatar {
            width: 32px !important;
            height: 32px !important;
        }

        .remove-button {
            display: block;
        }
    }
}
</style>
