<template>
    <base-material-card class="mt-12">
        <template v-slot:heading>
            <div class="display-2">
                <div class="d-flex justify-space-between align-center">
                    Oferte
                </div>
            </div>
        </template>
        <v-card-text>
            <upload-quotation
                :attach-to-auction-batch="batch"
                @supplier-added-parent="loadSuppliers"
            />
            <quotation-list
                :suppliers="suppliers"
                :batch="batch"
                @quotation-deleted="loadSuppliers"
            />
        </v-card-text>
    </base-material-card>
</template>

<script>
import QuotationList from "@/components/resources/productQuotations/QuotationList";
import UploadQuotation from "@/components/resources/productQuotations/UploadQuotation";

export default {
    name: "BatchOffer",
    components: {
        UploadQuotation,
        QuotationList
    },
    props: {
        batch: {
            required: true
        }
    },
    data() {
        return {
            suppliers: []
        };
    },
    methods: {
        loadSuppliers() {
            this.$admin.http
                .get(
                    `/api/auction/${this.batch.auction_id}/batches/${this.batch.id}/quotations`
                )
                .then(({ data }) => {
                    this.suppliers = data.data;
                });
        }
    },
    mounted() {
        this.loadSuppliers();
    }
};
</script>
