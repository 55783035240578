<template>
    <v-row
        :style="
            `background-color: rgba(${color}, 0.07); border-color: rgb(${color})`
        "
        class="tasks-wrapper flex-column"
    >
        <div class="accordion-panel">
            <div
                :style="`background: rgb(${color})`"
                class="accordion-panel__header"
                @click="toggleState"
            >
                {{ accordionTitle }}
                <span v-show="currentState">
                    <v-icon class="white--text ml-3" small>
                        fal fa-chevron-up
                    </v-icon>
                </span>
                <span v-show="!currentState">
                    <v-icon class="white--text ml-3" small>
                        fal fa-chevron-down
                    </v-icon>
                </span>
            </div>
            <div
                class="accordion-panel__content"
                :class="currentState ? 'open' : ''"
            >
                <va-list
                    ref="taskDataTable"
                    :disable-pagination="!activatePagination"
                    :filter="filter"
                    :hide-header="!showHeader"
                    :include="[
                        'userCommentsCount',
                        'checklistItemsCount',
                        'completedChecklistItemsCount'
                    ]"
                    :resource="resource"
                    disable-create
                    disable-export
                    disable-query-string
                    :items-per-page="activatePagination ? 15 : -1"
                >
                    <va-data-table
                        v-if="currentState"
                        :disable-pagination="true"
                        :fields="fields"
                        disable-actions
                        disable-delete
                        disable-select
                        class="accordion-panel__content-table"
                    >
                        <template v-slot:[`field.is_complete`]="{ item }">
                            <task-status :item="item" />
                        </template>

                        <template v-slot:[`field.title`]="{ item }">
                            <task-view :item="item" />
                        </template>

                        <template v-slot:[`field.department`]="{ item }">
                            <task-department :item="item" />
                        </template>

                        <template v-slot:[`field.responsibles`]="{ item }">
                            <task-responsibles :item="item" />
                        </template>

                        <template v-slot:[`field.created_by`]="{ item }">
                            <span class="text-body-2">
                                {{ item.user.name }}
                            </span>
                        </template>

                        <template v-slot:[`field.watchers`]="{ item }">
                            <task-watchers :item="item" />
                        </template>

                        <template v-slot:[`field.deadline`]="{ item }">
                            <task-deadline :item="item" />
                        </template>

                        <template v-slot:[`field.tags`]="{ item }">
                            <task-tags :item="item" />
                        </template>

                        <template v-slot:[`field.priority`]="{ item }">
                            <task-priority :item="item" />
                        </template>

                        <template v-slot:[`field.created_at`]="{ item }">
                            <span class="text-body-2">
                                {{ item.created_at | moment("L, h:mm") }}
                            </span>
                        </template>

                        <template v-slot:[`field.remove`]="{ item }">
                            <task-private-remove :item="item" />
                        </template>
                    </va-data-table>

                    <add-new-task
                        :class="!currentState ? 'open' : ''"
                        ref="showTaskWrapper"
                        :resource="resource"
                        endpoint="resource"
                    />
                </va-list>
            </div>

            <div v-if="showAddTaskButton" class="new-task-wrapper__activator">
                <span @click="$refs.showTaskWrapper.showNewTaskRow = true">
                    + Adauga Task Nou
                </span>
            </div>
        </div>
    </v-row>
</template>

<script>
import TaskDepartment from "./taskDepartment";
import AddNewTask from "./addNewTask";
import TaskResponsibles from "./taskResponsibles";
import TaskWatchers from "./taskWatchers";
import TaskDeadline from "./taskDeadline";
import TaskView from "@/components/tasks/taskView";
import TaskStatus from "./taskStatus";
import TaskPrivateRemove from "./taskPrivateRemove";
import TaskPriority from "./taskPriority";
import TaskTags from "@/components/tasks/TaskTagSelector/taskTags";

export default {
    name: "tasksAccordionPanel",
    components: {
        TaskTags,
        TaskPriority,
        TaskStatus,
        TaskPrivateRemove,
        TaskView,
        TaskDeadline,
        TaskResponsibles,
        TaskWatchers,
        AddNewTask,
        TaskDepartment
    },
    props: {
        resource: {
            type: String,
            required: true
        },
        accordionTitle: {
            required: true,
            type: String
        },
        color: {
            type: String,
            default: "0, 168, 7"
        },
        showAddTaskButton: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Object,
            default() {
                return {
                    incomplete: true,
                    onlyUsers: { values: [] }
                };
            }
        },
        activatePagination: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentState: true,
            fields: [
                {
                    source: "is_complete",
                    label: " ",
                    width: "50"
                },
                { source: "title", sortable: true },
                { source: "department", label: "Departament" },
                { source: "responsibles", label: "Responsabil" },
                {
                    source: "deadline",
                    label: "Termen",
                    sortable: true
                },
                { source: "priority", type: "select", sortable: true },
                { source: "tags", label: "Legaturi", align: "center" },
                { source: "watchers", label: "Urmaritori" },
                { source: "created_by", label: "Creat de" },
                {
                    source: "created_at",
                    label: "Data creare",
                    sortable: true,
                    width: "120px"
                },
                {
                    source: "remove",
                    label: " "
                }
            ]
        };
    },

    computed: {
        resourceKey() {
            return this.resource.replace("/", "_");
        },
        currentExpandState() {
            return this.$store.getters["userSettings"] !== null &&
                this.$store.getters["userSettings"][this.resourceKey] !==
                    undefined
                ? this.$store.getters["userSettings"][this.resourceKey]
                : true;
        }
    },

    mounted() {
        this.currentState = this.currentExpandState;
    },

    methods: {
        toggleState() {
            return new Promise((resolve, reject) => {
                this.$admin.http
                    .put(`api/user/setting/${this.resourceKey}`, {
                        stateItems: !this.currentState
                    })
                    .then(() => {
                        this.$store.commit("setTaskPanelExpansion", {
                            key: this.resourceKey,
                            value: !this.currentState
                        });
                        this.currentState = !this.currentState;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        remove(item, auction) {
            new Promise(resolve => {
                this.$admin.http
                    .delete(`api/tasks/remove-tag/${item.id}/${auction.id}`)
                    .then(() => {
                        item.auctions.forEach(function(entry, index) {
                            if (entry.id === auction.id) {
                                item.auctions.splice(index, 1);
                            }
                        });
                        resolve();
                    });
            });
        }
    },

    watch: {
        filter: {
            handler: function() {
                this.$refs["taskDataTable"].listState.reload();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.tasks-wrapper {
    margin: 20px 0 0;
    border-radius: 5px;
    border-left: 4px solid;
    padding: 15px;

    .accordion-panel {
        font-size: 19px;

        &__header {
            cursor: pointer;
            display: inline-block;
            border-radius: 5px 5px 0 0;
            color: #fff;
            width: 265px;
            padding: 5px;
            text-align: center;
            font-size: 16px;
        }

        &__content {
            background: #fff;

            &.open {
                box-shadow: 0 2px 6px #00000008;
                border: 1px solid #d2d2d2;
                border-radius: 0 5px 5px 5px !important;
            }

            .v-data-table {
                &__wrapper {
                    thead {
                        background: #f7f7f7;

                        th {
                            padding: 0 20px !important;
                        }
                    }

                    tbody {
                        tr {
                            background-color: #fff !important;

                            td {
                                padding: 0 20px;

                                .title-column {
                                    font-weight: bold;
                                    color: #616161;
                                }
                            }
                        }
                    }
                }
            }
        }

        .new-task-wrapper {
            &.open {
                border: 1px solid #d2d2d2;
                border-top: 0;
                border-radius: 0 5px 5px 5px;
            }
            &__row {
                border-top: thin solid rgba(0, 0, 0, 0.12);
                padding: 12px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 50px;

                &-title {
                    flex: 1;

                    .v-text-field {
                        label {
                            font-size: 16px;
                            font-style: italic;
                            color: #d4d4d4;
                        }
                    }
                }
            }

            &__activator {
                padding: 20px 20px 0 20px;

                span {
                    font-size: 14px;
                    font-weight: bold;
                    color: #616161;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>
