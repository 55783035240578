<template>
    <va-edit-layout>
        <auction-interests-form
            :id="id"
            :item="item"
            :title="title"
        ></auction-interests-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
