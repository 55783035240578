<template>
    <add-note-dialog
        :auction-id="note.auction_id"
        :batch-id="note.batch_id"
        :note-id="note.id"
        @note-added="$emit('note-added')"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="white--text text-capitalize mr-2"
                color="#9A9A9A"
                small
                v-bind="attrs"
                v-on="on"
            >
                <v-icon dark left>
                    fa fa-reply
                </v-icon>
                Raspunde
            </v-btn>
        </template>

        <template v-slot:title>
            Raspunde notitei
        </template>
    </add-note-dialog>
</template>

<script>
import AddNoteDialog from "@/components/resources/auctions/Notes/AddNoteDialog";

export default {
    name: "ReplyToNoteButton",
    components: { AddNoteDialog },
    props: {
        note: {
            type: Object,
            required: true
        }
    }
};
</script>
