<template>
    <base-material-card :icon="resource.icon" :title="title">
        <upload-quotation class="mb-4" />
        <va-list :filters="filters" :include="['type']">
            <va-data-table :fields="fields"></va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import UploadQuotation from "@/components/resources/productQuotations/UploadQuotation";

export default {
    props: ["resource", "title"],
    components: {
        UploadQuotation
    },
    data() {
        return {
            filters: [],
            fields: [
                { source: "name", sortable: true },
                {
                    source: "type",
                    type: "reference",
                    attributes: {
                        reference: "supplier_types",
                        text: "name"
                    }
                }
            ]
        };
    }
};
</script>
