<template>
    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        :clipped-right="$vuetify.breakpoint.lgAndUp"
        :color="color"
        :dark="dark"
        :dense="dense"
        app
    >
        <!--
          Triggered on VAppBar icon click, use it for VaSidebar toggling or minimizing.
          @event toggle
        -->
        <v-app-bar-nav-icon @click.stop="$emit('toggle')" />
        <v-toolbar-title class="ml-0 pl-4" style="width: 200px">
            <span class="hidden-sm-and-down">{{ title || $admin.title }}</span>
        </v-toolbar-title>
        <v-row v-if="headerMenu.length && $vuetify.breakpoint.lgAndUp">
            <v-col
                v-for="(item, i) in headerMenu"
                :key="i"
                class="text-center mb-sm-0 mb-5"
                cols="auto"
            >
                <component
                    :is="item.href ? 'a' : 'router-link'"
                    :href="item.href"
                    :target="item.href ? '_blank' : '_self'"
                    :to="item.link"
                    class="px-3 white--text link"
                    v-text="item.text"
                ></component>
            </v-col>
            <v-col>
                <main-search />
            </v-col>
        </v-row>
        <v-spacer />
        <div>
            <template></template>
            <app-bar-notifications />
            <v-menu
                v-if="!disableCreate && createResourceLinks.length"
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        :title="$t('va.actions.create')"
                        icon
                        small
                        v-on="on"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>

                <v-list dense nav>
                    <v-list-item
                        v-for="(item, index) in createResourceLinks"
                        :key="index"
                        :to="item.link"
                        link
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn
                v-if="!disableReload"
                :loading="$store.state.api.loading"
                class="ml-5"
                icon
                small
                @click="refresh"
            >
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-menu v-if="$store.state.auth.user" offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn class="ml-5" icon small v-on="on">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </template>

                <v-list dense nav>
                    <template v-if="getName">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="title"
                                    >{{ getName }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="getEmail"
                                    >{{ getEmail }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                    </template>

                    <v-list-item
                        v-for="(item, index) in profileMenu"
                        :key="index"
                        :to="item.link"
                        class="mt-2"
                        link
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                >{{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                >{{ $t("va.logout") }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import MainSearch from "@/components/base/MainSearch";
import AppBarNotifications from "@/components/Notifications/AppBarNotifications";

/**
 * Default customizable admin VAppBar.
 * Contains main app title, header menus, direct resource creation links, global refresh action, profile menu.
 * Profile user dropdown will not appear on guest mode.
 */
export default {
    components: { AppBarNotifications, MainSearch },
    props: {
        /**
         * Replace default admin app title set on VuetifyAdmin constructor.
         */
        title: String,
        /**
         * Header links visible on left side.
         */
        headerMenu: {
            type: Array,
            default: () => []
        },
        /**
         * Profile related links, visible inside authenticated dropdown menu.
         */
        profileMenu: {
            type: Array,
            default: () => []
        },
        /**
         * Disable create menu.
         */
        disableCreate: Boolean,
        /**
         * Disable reload state button.
         */
        disableReload: Boolean,
        /**
         * Color for the VAppBar.
         */
        color: {
            type: String,
            default: "primary"
        },
        /**
         * Reduce height of VAppBar
         */
        dense: Boolean,
        /**
         * Apply dark theme variant for VAppBar
         */
        dark: Boolean
    },
    computed: {
        createResourceLinks() {
            return this.$admin.getResourceLinks(
                this.$admin.resources.map(r => {
                    return {
                        name: r.name,
                        action: "create"
                    };
                })
            );
        },
        getName() {
            return this.$store.getters["auth/getName"];
        },
        getEmail() {
            return this.$store.getters["auth/getEmail"];
        }
    },
    methods: {
        refresh() {
            this.$store.dispatch("api/refresh", this.$route.meta.resource);
        },
        logout() {
            this.$store.dispatch("auth/logout");
        }
    }
};
</script>

<style scoped>
.link {
    font-size: 0.825rem;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}
</style>
