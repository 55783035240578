<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            min-width="auto"
            offset-y
            transition="scale-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <div v-if="item.deadline">
                        <span style="color: #666666" class="text-body-2">
                            {{ item.deadline | moment("L") }}
                        </span>
                    </div>
                    <div v-else>
                        <v-icon color="#666666" size="20"
                            >mdi mdi-calendar</v-icon
                        >
                    </div>
                </div>
            </template>
            <v-date-picker v-model="date" scrollable>
                <v-btn color="dark" small text @click="removeDeadline">
                    Elimina termen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" small text @click="menu = false">
                    Anuleaza
                </v-btn>
                <v-btn color="primary" small text @click="addNewDeadline">
                    OK
                </v-btn>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "taskDeadline",
    inject: {
        listState: {
            default: undefined
        }
    },
    props: {
        item: {}
    },
    data() {
        return {
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            menu: false
        };
    },
    methods: {
        setDeadline(value) {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .patch(`/api/tasks/${this.item.id}`, {
                        deadline: value
                    })
                    .then(() => {
                        this.menu = false;
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        removeDeadline() {
            this.setDeadline(null);
        },
        addNewDeadline() {
            this.setDeadline(this.date);
        }
    }
};
</script>
