var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',_vm._l((_vm.products),function(product,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(product.name))]),_c('v-col',{staticClass:"pr-10"},[_c('apexchart',{attrs:{"type":"area","height":"50","options":_vm.chartOptions,"series":[
                                {
                                    data: product.quotations.map(function (item) { return ({
                                        x: item.created_at,
                                        created: item.created_at_verbose,
                                        y: item.price,
                                        currency: item.currency
                                    }); })
                                }
                            ]}})],1)],1)],1),_c('v-expansion-panel-content',_vm._l((product.quotations),function(quotation,indexQ){return _c('v-row',{key:indexQ,staticClass:"attachments-list__item grey lighten-3 d-flex flex-wrap justify-space-between align-center mb-3 mx-0 pa-3 rounded"},[_c('v-col',{staticClass:"attachments-list__item-title text--grey darken-2 pa-0 pa-md-3",attrs:{"cols":"12","md":"4","sm":"3"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(quotation.price))+" "+_vm._s(quotation.currency)+" ")]),_c('v-col',{staticClass:"attachments-list__item-price-evolution text--grey darken-2 pa-0 pa-md-3 d-flex items-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("moment")(quotation.created_at,"L"))+" ")]),_c('v-col',{staticClass:"attachments-list__item-actions",attrs:{"align":"right","cols":"12","md":"2","sm":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-3",attrs:{"href":quotation.full_file_path,"icon":"","large":"","target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-file-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descarca oferta")])])],1)],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }