var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.suppliers.length > 0)?_c('div',{staticClass:"attachments-list mt-8"},_vm._l((_vm.suppliers),function(supplier,index){return _c('div',{key:index,staticClass:"py-4 d-block"},[_c('v-row',{staticClass:"mb-1 mx-0 d-inline-block"},[_c('div',{staticClass:"grey lighten-3 rounded py-3 px-6 primary--text font-weight-bold text-h4"},[_vm._v(" "+_vm._s(supplier.name)+" ")])]),_vm._l((supplier.quotations),function(quotation,index){return _c('v-row',{key:index,staticClass:"attachments-list__item grey lighten-3 d-flex flex-wrap justify-space-between align-center mb-3 mx-0 pa-3 rounded"},[_c('v-col',{staticClass:"attachments-list__item-title text--grey darken-2 pa-0 pa-md-3",attrs:{"cols":"12","md":"4","sm":"3"}},[_vm._v(" "+_vm._s(quotation.product.name)+" ")]),_c('v-col',{staticClass:"attachments-list__item-price text--grey darken-2 pa-0 pa-md-3",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(quotation.price)+" "+_vm._s(quotation.currency)+" ")]),_c('v-col',{staticClass:"attachments-list__item-date text--grey darken-2 pa-0 pa-md-3",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("moment")(quotation.created_at,"L"))+" ")]),_c('v-col',{staticClass:"attachments-list__item-price-evolution text--grey darken-2 pa-0 pa-md-3 d-flex items-center",attrs:{"cols":"12","sm":"2"}},[_c('apexchart',{attrs:{"type":"area","height":"50","options":_vm.chartOptions,"series":[
                            {
                                data: quotation.product.quotations.map(
                                    function (item) { return ({
                                        x: item.created_at,
                                        created: item.created_at_verbose,
                                        y: item.price,
                                        currency: item.currency
                                    }); }
                                )
                            }
                        ]}})],1),_c('v-col',{staticClass:"attachments-list__item-actions",attrs:{"align":"right","cols":"12","md":"2","sm":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-3",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.sendOffer(quotation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-calculator")])],1)]}}],null,true)},[_c('span',[_vm._v("Analiza pret")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-3",attrs:{"icon":"","large":"","href":quotation.full_file_path}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Descarca oferta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-3",attrs:{"icon":"","large":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.removeQuotation(quotation.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" mdi-window-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Elimina oferta din licitatie. "),_c('br'),_c('strong',[_vm._v("Atentie:")]),_vm._v(" oferta va ramane in lista de oferte. ")])])],1)],1)})],2)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }