<template>
    <mb-layout>
        <mb-app-bar
            slot="app-bar"
            :header-menu="headerMenu"
            :profile-menu="profileMenu"
            dark
            dense
            @toggle="toggleNavState"
        ></mb-app-bar>
        <mb-sidebar
            slot="sidebar"
            v-model="drawer"
            :menu="sidebarMenu"
            :mini-variant="mini"
            @toggle="toggleListExpandState"
            @outsideClick="layoutClick"
        ></mb-sidebar>
        <template slot="header">
            <va-breadcrumbs></va-breadcrumbs>
            <impersonate-message></impersonate-message>
        </template>
        <va-aside slot="aside"></va-aside>
        <va-footer slot="footer" :menu="footerMenu">
            &copy; {{ currentYear }}: Multibrand Machinery, Aplicatie realizata
            de
            <a href="https://digital-solutions.agency/" target="_blank">DSA</a>
        </va-footer>
    </mb-layout>
</template>

<script>
import ImpersonateMessage from "@/components/ImpersonateMessage";
import nav from "../_nav";

export default {
    name: "App",
    components: {
        ImpersonateMessage
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        getUser() {
            return this.$store.getters["auth/getUser"];
        },
        sidebarState() {
            return this.$store.getters["userSettings"] !== null &&
                this.$store.getters["userSettings"].sidebar_state !== undefined
                ? this.$store.getters["userSettings"].sidebar_state
                : undefined;
        },
        listExpandState() {
            return this.$store.getters["userSettings"] !== null &&
                this.$store.getters["userSettings"].sidebar_list_expanded !==
                    undefined
                ? this.$store.getters["userSettings"].sidebar_list_expanded
                : undefined;
        }
    },
    mounted() {
        this.$vuetify.breakpoint.lgAndUp
            ? (this.mini = this.sidebarState)
            : (this.drawer = this.sidebarState);

        this.sidebarMenu[
            this.listExpandState !== undefined ? this.listExpandState : 0
        ].expanded = true;
    },
    data() {
        return {
            drawer: null,
            mini: false,
            headerMenu: [
                {
                    link: "/",
                    text: this.$t("menu.dashboard")
                }
            ],
            footerMenu: [
                {
                    href: "https://multibrandmachinery.ro/",
                    text: "Website Multibrand"
                },
                {
                    href: "https://crctm.ro/",
                    text: "Website CRC"
                }
            ],
            profileMenu: [
                {
                    icon: "mdi-account",
                    text: this.$t("menu.profile"),
                    link: "/profile"
                }
            ],
            sidebarMenu: nav(this.$i18n, this.$admin, this.$store)
        };
    },
    methods: {
        toggleListExpandState(item, index) {
            const expanded =
                this.$store.getters["userSettings"].sidebar_list_expanded !==
                index;
            return new Promise((resolve, reject) => {
                this.$admin.http
                    .put("api/user/setting/sidebar_list_expanded", {
                        stateItems: expanded ? index : 0
                    })
                    .then(() => {
                        this.$store.commit(
                            "setSidebarListExpansion",
                            expanded ? index : 0
                        );
                        this.sidebarMenu[index].expanded = expanded;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        toggleNavState() {
            return new Promise((resolve, reject) => {
                this.$admin.http
                    .put("api/user/setting/sidebar_state", {
                        stateItems: this.$vuetify.breakpoint.lgAndUp
                            ? !this.mini
                            : this.$vuetify.breakpoint.mdAndDown
                            ? !this.sidebarState
                            : !this.drawer
                    })
                    .then(() => {
                        this.$vuetify.breakpoint.lgAndUp
                            ? (this.mini = !this.mini)
                            : this.$vuetify.breakpoint.mdAndDown
                            ? this.mediumBreakStateSetter()
                            : (this.drawer = !this.drawer);
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        mediumBreakStateSetter() {
            this.$store.commit("setSidebarState", !this.sidebarState);
            this.drawer = this.sidebarState;
        },
        layoutClick() {
            if (this.sidebarState) {
                this.toggleNavState();
            }
        }
    }
};
</script>
