import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/apex";
import "./plugins/mb";
import "./sass/overrides.sass";
import numeral from "numeral";
import VueDragscroll from "vue-dragscroll";
import Nl2br from "vue-nl2br";
import VueTheMask from "vue-the-mask";
import "@fortawesome/fontawesome-pro/js/all";

const moment = require("moment");
require("moment/locale/ro");
Vue.use(require("vue-moment"), {
    moment
});

Vue.use(VueDragscroll);
Vue.use(VueTheMask);

Vue.component("nl2br", Nl2br);

Vue.config.productionTip = false;

Vue.filter("formatNumber", function(value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("capitalize", function(value) {
    if (!value) return "";
    value = value.toString().toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
new Vue({
    router,
    store,
    i18n,
    vuetify,
    admin,
    render: h => h(App)
}).$mount("#app");
