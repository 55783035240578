export default [
    {
        name: "users",
        label: "name",
        icon: "mdi-circle-small",
        actions: ["show", "list", "edit", "create"]
    },
    {
        name: "auction_statuses",
        icon: "mdi-circle-small",
        label: "name",
        routes: ["list"],
        actions: ["list"]
    },
    { name: "auction_category", icon: "mdi-circle-small", label: "name" },
    {
        name: "auction-new",
        icon: "fal fa-star-exclamation",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-for-dashboard",
        icon: "fal fa-star-exclamation",
        label: "title",
        routes: ["list"],
        actions: ["list"]
    },
    {
        name: "auction-interest",
        icon: "fal fa-sparkles",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-no-interest",
        icon: "fal fa-times",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-processing",
        icon: "fal fa-users-cog",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-in-progress",
        icon: "fal fa-play",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-won",
        icon: "fal fa-trophy-alt",
        label: "title",
        routes: ["list", "show", "edit"],
        actions: ["show", "list", "edit"]
    },
    {
        name: "auction-lost",
        icon: "fal fa-skull-crossbones",
        label: "title",
        routes: ["list", "show"],
        actions: ["show", "list"]
    },
    {
        name: "auction-canceled",
        icon: "fal fa-thumbs-down",
        label: "title",
        routes: ["list", "show"],
        actions: ["show", "list"]
    },
    {
        name: "auction-completed",
        icon: "fal fa-thumbs-up",
        label: "title",
        routes: ["list", "show"],
        actions: ["show", "list"]
    },
    {
        name: "auction_notes",
        icon: "fal fa-skull-crossbones",
        label: "title",
        routes: ["delete"],
        actions: ["delete"]
    },
    { name: "customers", icon: "fal fa-users", label: "name" },
    {
        name: "auction_interests",
        icon: "mdi-circle-small",
        label: "name",
        actions: ["show", "list", "edit", "create", "delete"]
    },
    {
        name: "deadlines_schema",
        icon: "mdi-circle-small",
        api: "deadlines-schema",
        label: "name",
        actions: undefined
    },
    {
        name: "suppliers",
        icon: "fal fa-industry-alt",
        label: "name",
        actions: ["show", "list", "edit", "create"]
    },
    {
        name: "departments",
        icon: "fal fa-industry-alt",
        label: "name",
        actions: []
    },
    {
        name: "roles",
        icon: "fal fa-industry-alt",
        label: "name",
        actions: []
    },
    {
        name: "product_type",
        api: "product-types",
        icon: "fal fa-truck-loading",
        label: "name",
        actions: ["list", "edit", "create"]
    },
    {
        name: "product",
        icon: "fal fa-container-storage",
        label: "name",
        actions: ["show", "list", "edit", "create"]
    },
    {
        name: "tasks",
        icon: "fal fa-check-square",
        api: "tasks/all",
        label: "title",
        actions: ["show", "list", "edit", "create"]
    },
    {
        name: "my-tasks",
        icon: "fal fa-check-square",
        api: "tasks/all",
        label: "title",
        actions: ["show", "list", "edit", "create"]
    },
    { name: "tasks/today", api: "tasks/today" },
    { name: "tasks/week", api: "tasks/week" },
    { name: "tasks/next-week", api: "tasks/next-week" },
    { name: "tasks/upcomming", api: "tasks/upcomming" },
    { name: "tasks/past", api: "tasks/past" },
    {
        name: "supplier_types",
        icon: "fal fa-object-group",
        label: "name",
        actions: undefined
    }
];
