<template>
    <va-edit-layout>
        <deadlines-schema-form
            :id="id"
            :item="item"
            :title="title"
        ></deadlines-schema-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
