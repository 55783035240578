<template>
    <div>
        <div v-if="userDepartments.includes('Licitatii Publice')">
            <all-auction-stats-by-status />

            <v-tabs v-model="openedTab">
                <v-tab>Calendar de termene</v-tab>
                <v-tab>Urmatoarele licitatii</v-tab>
            </v-tabs>
            <v-tabs-items v-model="openedTab" touchless>
                <v-tab-item>
                    <mb-horizontal-calendar />
                </v-tab-item>
                <v-tab-item>
                    <dashboard-quick-list />
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import AllAuctionStatsByStatus from "@/components/auctions/charts/AllAuctionStatsByStatus";
import DashboardQuickList from "@/components/auctions/DashboardQuickList";
import MbHorizontalCalendar from "@/components/auctions/MbHorizontalCalendar";

export default {
    components: {
        MbHorizontalCalendar,
        DashboardQuickList,
        AllAuctionStatsByStatus
    },
    data() {
        return {
            openedTab: ""
        };
    },
    computed: {
        userDepartments() {
            return this.$store.getters["userDepartments"].map(
                item => item.name
            );
        },
        userRole() {
            return this.$store.getters["userRole"];
        }
    }
};
</script>
