<template>
    <v-col cols="12" md="4" class="position-relative">
        <div
            :class="{
                'locked-wrapper': !batch.price_analysis.can_be_edited,
                'locked-wrapper special-height locked':
                    batch.price_analysis.has_sale_option_selected
            }"
        ></div>
        <v-container class="pt-5">
            <v-row align="center" class="mb-2">
                <v-col class="pa-0" cols="5" md="5" sm="6" xs="12">
                    <span class="font-weight-bold">Garantie:</span>
                </v-col>
                <v-col class="pa-0" cols="7" md="7" sm="6" xs="12">
                    <v-text-field
                        v-model="analysis.warranty"
                        class="price-analysis-table__input"
                        dense
                        hide-details
                        outlined
                        placeholder="Adauga garantie..."
                        @change="updateAnalysis"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="mb-2">
                <v-col class="pa-0" cols="5" md="5" sm="6" xs="12">
                    <span class="font-weight-bold">Termen de plata:</span>
                </v-col>
                <v-col class="pa-0" cols="7" md="7" sm="6" xs="12">
                    <v-text-field
                        v-model="analysis.payment_deadline"
                        class="price-analysis-table__input"
                        dense
                        hide-details
                        outlined
                        placeholder="Adauga termen de plata..."
                        @change="updateAnalysis"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="mb-2">
                <v-col class="pa-0" cols="5" md="5" sm="6" xs="12">
                    <span class="font-weight-bold">
                        Termen de livrare:
                    </span>
                </v-col>
                <v-col class="pa-0" cols="7" md="7" sm="6" xs="12">
                    <v-text-field
                        v-model="analysis.delivery_time"
                        class="price-analysis-table__input"
                        dense
                        hide-details
                        outlined
                        placeholder="Adauga termen de livrare..."
                        @change="updateAnalysis"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mt-2 penalties-container">
            <v-row>
                <v-col class="pb-0">
                    <div class="font-weight-bold text-uppercase">
                        penalitati
                    </div>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="pb-0" cols="5" md="5" sm="6" xs="12">
                    <span class="text-body-2">Procent penalitati</span>
                </v-col>
                <v-col class="pb-0" cols="7" md="7" sm="6" xs="12">
                    <v-text-field
                        v-model="penalty.percentage"
                        class="price-analysis-table__input"
                        dense
                        hide-details
                        outlined
                        type="number"
                        decimals="2"
                        placeholder="Adauga procent..."
                        @change="updatePenalty"
                    />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="pb-0" cols="5" md="5" sm="6" xs="12">
                    <span class="text-body-2">Zile estimate de penalitati</span>
                </v-col>
                <v-col class="pb-0" cols="7" md="7" sm="6" xs="12">
                    <v-text-field
                        v-model="penalty.total_days"
                        class="price-analysis-table__input"
                        dense
                        hide-details
                        type="number"
                        outlined
                        placeholder="Adauga zile..."
                        @change="updatePenalty"
                    />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="5" md="5" sm="6" xs="12">
                    <span class="text-body-2">Include penalitati</span>
                </v-col>
                <v-col cols="7" md="7" sm="6" xs="12">
                    <v-switch
                        v-model="penalty.active"
                        class="mt-0"
                        color="success"
                        hide-details
                        @change="updatePenalty"
                    ></v-switch>
                </v-col>
            </v-row>
        </v-container>
    </v-col>
</template>

<script>
import OfferAnalysis from "../../../events/OfferAnalysis";

export default {
    name: "PriceAnalysisTerms",

    props: {
        batch: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            penalty: {
                new: true,
                percentage: 0,
                total_days: 0,
                active: 0,
                updated_at: ""
            },
            analysis: {
                warranty: "",
                payment_deadline: "",
                delivery_time: ""
            }
        };
    },

    created() {
        this.loadAnalysis();
    },

    methods: {
        loadAnalysis() {
            this.$admin.http
                .get(`/api/price-analysis/${this.batch.id}`)
                .then(({ data }) => {
                    if (data.data) {
                        this.analysis = data.data;
                    }
                    if (data.data.penalty) {
                        this.penalty = data.data.penalty;
                    }
                });
        },

        updateAnalysis() {
            const payload = Object.fromEntries(
                Object.entries(this.analysis).filter(
                    element => element[1] !== ""
                )
            );

            this.$admin.http.put(
                `/api/price-analysis/update-analysis/${this.analysis.id}`,
                payload
            );
        },

        updatePenalty() {
            if (this.penalty.new) {
                this.$admin.http
                    .put(
                        `/api/price-analysis/add-penalty/${this.analysis.id}`,
                        this.penalty
                    )
                    .then(() => {
                        this.$store.dispatch(
                            "price_analysis/getPriceAnalysis",
                            {
                                vm: this,
                                batch_id: this.batch.id
                            }
                        );

                        OfferAnalysis.$emit("reloadOptions");
                    });
            } else {
                this.$admin.http
                    .put(
                        `/api/price-analysis/update-penalty/${this.analysis.id}/${this.penalty.id}`,
                        this.penalty
                    )
                    .then(() => {
                        this.$store.dispatch(
                            "price_analysis/getPriceAnalysis",
                            {
                                vm: this,
                                batch_id: this.batch.id
                            }
                        );

                        OfferAnalysis.$emit("reloadOptions");
                    });
            }
        }
    }
};
</script>

<style scoped>
.penalties-container {
    border: 1px solid #f97a7a;
    border-radius: 5px;
}
</style>
