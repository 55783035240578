<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" disable-export>
            <mb-data-table :fields="fields" disable-select>
                <template v-slot:[`field.color`]="{ item }">
                    <template v-if="item.color">
                        <div
                            :style="{ backgroundColor: item.color }"
                            style="width: 25px; height: 25px;display: inline-block"
                        ></div>
                        {{ item.color }}
                    </template>
                </template>
            </mb-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: ["name"],
            fields: [
                { source: "name", sortable: true },
                { source: "number_of_days", sortable: false },
                { source: "used_date_column", sortable: false },
                { source: "color", sortable: false }
            ]
        };
    }
};
</script>
