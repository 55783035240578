<template>
    <va-show-layout :title="itemTitle" hide-list-button hide-edit-button>
        <template slot="actions">
            <speed-dial-auction-new :item="item" />
        </template>

        <va-show v-if="item" :item="item">
            <v-row>
                <v-col class="my-5 mb-md-0" cols="12" md="4">
                    <task-dialog
                /></v-col>
            </v-row>

            <v-row class="mt-10" justify="center">
                <v-col class="mb-5 mb-md-0" cols="12" md="4">
                    <base-material-card class="my-0" height="100%">
                        <template v-slot:heading>
                            <div class="display-2">
                                Informatii
                            </div>
                        </template>
                        <v-card-text>
                            <InfoDetails :item="item" />
                        </v-card-text>
                    </base-material-card>
                </v-col>

                <v-col class="mb-5 mb-md-0" cols="12" md="4">
                    <base-material-card class="my-0" height="100%">
                        <template v-slot:heading>
                            <div class="display-2">
                                Organizator
                            </div>
                        </template>
                        <v-card-text v-if="item">
                            <OrganizerDetails :customer="item.customer" />
                        </v-card-text>
                    </base-material-card>
                </v-col>

                <v-col class="mb-5 mb-md-0" cols="12" md="4">
                    <base-material-card class="my-0" height="100%">
                        <template v-slot:heading>
                            <div class="display-2">
                                Istoric
                            </div>
                        </template>
                        <template v-slot:heading>
                            <div class="display-2">
                                Fisiere
                            </div>
                        </template>
                        <v-card-text>
                            <file-list :auction-id="item.id"></file-list>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
            <!--     START - Tabs - Notite + Oferte       -->
            <v-row class="mt-3">
                <v-col cols="12">
                    <batches :item="item" />
                </v-col>
            </v-row>
            <!--     END - Tabs - Notite + Oferte       -->
            <v-row>
                <v-col cols="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                Documente
                            </div>
                        </template>
                        <v-card-text>
                            <mb-file-manager :auction-id="item.id" />
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                Detalii anunt
                            </div>
                        </template>
                        <v-card-text>
                            <h3 class="font-italic font-weight-bold my-5">
                                {{ item.title }}
                            </h3>
                            <nl2br
                                tag="p"
                                :text="item.content"
                                class-name="foo bar"
                            />
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </va-show>
    </va-show-layout>
</template>

<script>
import FileList from "@/components/resources/auctions/FileList";
import OrganizerDetails from "@/components/resources/auctions/OrganizerDetails";
import InfoDetails from "@/components/resources/auctions/InfoDetails";
import MbFileManager from "@/components/inputs/MbFileManager";
import TaskDialog from "../../components/resources/auctions/TaskDialog";
import Batches from "../../components/resources/auctions/Batches";
import SpeedDialAuctionNew from "../../components/resources/auctions/ShowAuctionSpeedDial/SpeedDialAuctionNew";

export default {
    components: {
        SpeedDialAuctionNew,
        Batches,
        TaskDialog,
        MbFileManager,
        InfoDetails,
        FileList,
        OrganizerDetails
    },
    props: ["title", "item"],
    data() {
        return {
            tab: 0
        };
    },
    computed: {
        itemTitle() {
            return this.item ? this.item.title : this.title;
        }
    }
};
</script>
