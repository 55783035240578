<template>
    <div>
        <v-checkbox
            v-model="isComplete"
            :false-value="false"
            :true-value="true"
        ></v-checkbox>
    </div>
</template>

<script>
export default {
    name: "taskStatus",
    props: {
        item: {}
    },
    inject: ["listState"],
    computed: {
        isComplete: {
            get() {
                return this.item.is_complete;
            },
            set(value) {
                if (value) {
                    this.markAsCompleted();
                } else {
                    this.markAsUndone();
                }
            }
        }
    },
    methods: {
        markAsCompleted() {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .put(`/api/tasks/${this.item.id}/marked-as-closed`)
                    .then(() => {
                        this.listState.reload();
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },

        markAsUndone() {
            new Promise((resolve, reject) => {
                this.$admin.http
                    .put(`/api/tasks/${this.item.id}/marked-as-undone`)
                    .then(() => {
                        this.listState.reload();
                        this.menu = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });  
        }
    }
};
</script>
