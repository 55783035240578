<template>
    <div class="drawer-wrapper">
        <div class="pa-2 opener" @click="openDrawer">
            <div v-show="isVisible" style="height: 100%;">
                <v-icon class="opener-icon" color="white" large>
                    fas fa-chevron-right
                </v-icon>
            </div>
            <div v-show="!isVisible" style="height: 100%;">
                <v-icon class="opener-icon" color="white" large>
                    fas fa-chevron-left
                </v-icon>
            </div>
        </div>
        <div :class="isVisible ? 'drawerWidth' : ''" class="right-drawer">
            <div class="row pt-3" style="height: 34vh;">
                <v-list style="width: 100%" tile>
                    <v-list-item v-for="(item, i) in items" :key="i" dense>
                        <v-list-item-icon>
                            <v-chip
                                :color="item.color"
                                small
                                style="width: 80px"
                            >
                                {{ item.chipColor }}
                            </v-chip>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AuctionLegendDrawer",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [
                {
                    text: "Clarificări inițiale (de interes)",
                    chipColor: "Galben",
                    color: "#F5FAAEFF"
                },
                {
                    text: "Data limită de depunere (de interes)",
                    chipColor: "Portocaliu",
                    color: "#fb8c00"
                },
                {
                    text: "În procesare",
                    chipColor: "Magenta",
                    color: "#de0174"
                },
                {
                    text: "Clarificări ulterioare",
                    chipColor: "Albastru",
                    color: "#9FB9EEFF"
                },
                {
                    text: "Licitatii depuse",
                    chipColor: "Turcoaz",
                    color: "#42ded3"
                },
                {
                    text: "Nu este de interes",
                    chipColor: "Gri",
                    color: "#e0e0e0"
                },
                {
                    text: "Data limita de contestatie",
                    chipColor: "Rosu",
                    color: "#E57373"
                }
            ]
        };
    },
    computed: {
        visibility: {
            get() {
                return this.isVisible;
            },
            set(value) {
                this.$emit("open", value);
            }
        }
    },
    methods: {
        updateVisibility(value) {
            this.$emit("open", value);
        },
        openDrawer() {
            this.visibility = !this.isVisible;

            return new Promise((resolve, reject) => {
                this.$admin.http
                    .put("api/user/setting/auction_drawer_state", {
                        stateItems: !this.isVisible
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        }
    }
};
</script>

<style lang="scss" scoped>
/* DRAWER PROPERTIES */

.opener-icon {
    top: 50%;
    transform: translate(0, -50%);
}

.opener {
    background-color: #4278fc;
    transition: all 0.2s;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right-drawer {
    top: 15px;
    width: 0;
    overflow: hidden;
    background-color: #fff;
    transition: all 0.2s;
    overflow-y: scroll;
}

.drawer-wrapper {
    z-index: 0;
    display: flex;
}

.drawerWidth {
    width: 25vw !important;
}

.openerLenth {
    right: 25vw !important;
}

/* MEDIA QUERIES */

@media (min-width: 480px) {
    .drawerWidth {
        width: 87vw !important;
    }
    .openerLenth {
        right: 87vw !important;
    }
}

@media (min-width: 600px) {
    .drawerWidth {
        width: 89.5vw !important;
    }
    .openerLenth {
        right: 89.5vw !important;
    }
}

@media (min-width: 768px) {
    .drawerWidth {
        width: 55vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 55vw !important;
    }
}

@media (min-width: 1024px) {
    .drawerWidth {
        width: 47vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 47vw !important;
    }
}

@media (min-width: 1280px) {
    .drawerWidth {
        width: 35vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 35vw !important;
    }
}

@media (min-width: 1366px) {
    .drawerWidth {
        width: 31vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 31vw !important;
    }
}

@media (min-width: 1600px) {
    .drawerWidth {
        width: 26vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 26vw !important;
    }
}

@media (min-width: 1920px) {
    .drawerWidth {
        width: 20vw !important;
        padding-left: 10px !important;
    }
    .openerLenth {
        right: 20vw !important;
    }
}

@media (min-width: 2304px) {
    .drawerWidth {
        width: 18vw !important;
    }
    .openerLenth {
        right: 18vw !important;
    }
}
</style>
