import Vue from "vue";
import ShowLayout from "@/layouts/ShowLayout";
import SimpleTextInput from "@/components/inputs/SimpleTextInput";
import ColorPickerInput from "@/components/inputs/ColorPickerInput";
import SimpleSelectInput from "@/components/inputs/SimpleSelectInput";
import StatusInput from "@/components/inputs/StatusInput";
import MbDataTable from "@/components/list/MbDataTable";
import MbField from "@/components/wrappers/MbField";
import MbAppBar from "@/layouts/AppBar";
import MbLayout from "@/layouts/Layout";
import MbSidebar from "@/layouts/Sidebar";
import DatePickerInput from "@/components/inputs/DatePickerInput";

Vue.component("VaShowLayout", ShowLayout);
Vue.component("VaSimpleTextInput", SimpleTextInput);
Vue.component("VaColorPickerInput", ColorPickerInput);
Vue.component("VaSimpleSelectInput", SimpleSelectInput);
Vue.component("VaStatusInput", StatusInput);
Vue.component("VaDateInput", DatePickerInput);
Vue.component("MbDataTable", MbDataTable);
Vue.component("MbField", MbField);
Vue.component("MbAppBar", MbAppBar);
Vue.component("MbLayout", MbLayout);
Vue.component("MbSidebar", MbSidebar);
