<template>
    <va-edit-layout>
        <product-type-form
            :id="id"
            :title="title"
            :item="item"
        ></product-type-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"]
};
</script>
