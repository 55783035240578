<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <template v-slot:activator="{ on: dialogOn }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn
                        v-on="{ ...tooltipOn, ...dialogOn }"
                        small
                        text
                        color="red"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Sterge notita</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-toolbar color="primary" dark>
                Sterge notita
            </v-toolbar>

            <v-card-text>
                Esti sigur ca vrei sa stergi notita?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="disabled" text @click="dialog = false">
                    Nu
                </v-btn>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    text
                    @click="addNote"
                >
                    Da
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteNoteButton",
    props: {
        note: {}
    },
    data() {
        return {
            dialog: false,
            errors: {},
            loading: false
        };
    },
    methods: {
        addNote() {
            this.loading = true;
            this.errors = {};
            this.$admin.http
                .delete(`/api/auction_notes/${this.note.id}`)
                .then(() => {
                    this.dialog = false;
                    this.$emit("deleted");
                })
                .catch(error => {
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
