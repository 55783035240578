<template>
    <base-material-card>
        <template v-slot:heading>
            <div class="display-2">
                <div class="d-flex justify-space-between align-center">
                    Analiza Pret
                </div>
            </div>
        </template>

        <v-container class="px-0 px-md-3" fluid>
            <v-row>
                <v-col class="pb-1 pt-5" cols="12">
                    <div class="d-flex justify-space-between">
                        <div>
                            <button
                                :class="!showOfferGenerator ? 'active' : ''"
                                class="toggle-button rounded-l mr-1"
                                @click="showOfferGenerator = false"
                            >
                                Analiza pret
                            </button>
                            <button
                                :class="showOfferGenerator ? 'active' : ''"
                                class="toggle-button rounded-r"
                                :disabled="!canGenerate"
                                @click="showOfferGenerator = true"
                            >
                                Generare pret
                            </button>
                        </div>
                        <div v-if="showOfferGenerator">
                            <button
                                class="toggle-button rounded"
                                @click="$refs.offerGenerator.save()"
                            >
                                SALVEAZA PACHETE
                            </button>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="!showOfferGenerator" class="px-0 px-md-3" fluid>
            <v-row>
                <v-col class="pb-0" cols="12" md="8" sm="6">
                    <div class="d-flex justify-space-between align-end">
                        <div class="font-weight-bold text-uppercase">
                            Tabel produse
                        </div>
                        <v-menu max-height="600" max-width="350" offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="text-capitalize"
                                    color="success"
                                    small
                                    v-on="on"
                                    v-show="
                                        batch.price_analysis.can_be_edited ||
                                            !batch.price_analysis
                                                .has_sale_option_selected
                                    "
                                >
                                    <v-icon left>
                                        mdi-chevron-up
                                    </v-icon>
                                    Adauga linie noua
                                </v-btn>
                            </template>
                            <v-list class="new-product-button" dense nav>
                                <v-list-item
                                    v-for="type in supplierTypes"
                                    :key="type.id"
                                    class="text-body-2 mb-0"
                                    style="min-height: 30px"
                                    @click="addProduct(type)"
                                >
                                    {{ type.name }}
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="!showOfferGenerator" class="px-0 px-md-3 mb-4" fluid>
            <v-row>
                <!-- tabel produse -->
                <price-analysis-table
                    :batch="batch"
                    :new-product="selectedProduct"
                    :supplier-types="supplierTypes"
                    @clear-product="clearProduct"
                />
                <!-- termene si penalitati -->
                <price-analysis-terms :batch="batch" />
            </v-row>
            <v-row class="mt-7">
                <!-- achizitie curs bt -->
                <price-analysis-exchange-bank :batch="batch" :item="item" />
                <!-- achizitie curs data -->
                <price-analysis-exchange-date :item="item" :batch="batch" />
            </v-row>
            <v-row class="mt-6">
                <v-col>
                    <div class="font-weight-bold text-uppercase">
                        VARIANTE DE VANZARE
                    </div>
                </v-col>
            </v-row>
            <!-- variante de vanzare -->
            <price-analysis-sales-options :batch="batch" :item="item" />
        </v-container>

        <!-- Generare Oferta -->
        <v-container v-if="showOfferGenerator" class="px-0 px-md-3 mb-4" fluid>
            <price-analysis-offer-generator
                :batch="batch"
                ref="offerGenerator"
            />
        </v-container>
    </base-material-card>
</template>

<script>
import "@fortawesome/fontawesome-pro/js/all";
import PriceAnalysisTable from "./PriceAnalysisTable";
import PriceAnalysisTerms from "./PriceAnalysisTerms";
import PriceAnalysisExchangeBank from "./PriceAnalysisExchangeBank";
import PriceAnalysisExchangeDate from "./PriceAnalysisExchangeDate";
import PriceAnalysisSalesOptions from "./PriceAnalysisSalesOptions";
import PriceAnalysisOfferGenerator from "./PriceAnalysisOfferGenerator";
import { mapGetters } from "vuex";

export default {
    name: "BatchPriceAnalysis",

    components: {
        PriceAnalysisOfferGenerator,
        PriceAnalysisTable,
        PriceAnalysisTerms,
        PriceAnalysisExchangeBank,
        PriceAnalysisExchangeDate,
        PriceAnalysisSalesOptions
    },

    props: {
        batch: {
            type: Object,
            required: true,
            default: () => {}
        },
        item: {
            type: Object,
            required: true,
            default: () => {}
        }
    },

    data() {
        return {
            supplierTypes: [],
            selectedProduct: null,
            showOfferGenerator: false
        };
    },

    computed: {
        ...mapGetters({
            canGenerate: "price_analysis/can_generate"
        })
    },

    created() {
        this.lookupProductTypes();

        this.$store.dispatch("price_analysis/getPriceAnalysis", {
            vm: this,
            batch_id: this.batch.id
        });

        this.$store.dispatch("price_analysis/lookupLockedSaleOption", {
            vm: this,
            batch_id: this.batch.id
        });
    },

    methods: {
        addProduct(type) {
            this.selectedProduct = type;
        },

        clearProduct() {
            this.selectedProduct = null;
        },

        lookupProductTypes() {
            this.$admin.http
                .get(`/api/price-analysis/lookup-supplier-types`)
                .then(({ data }) => {
                    this.supplierTypes = data.data;
                });
        }
    },

    mounted() {
        if (this.batch.price_analysis.has_sale_option_selected) {
            this.showOfferGenerator = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.toggle-button {
    background-color: #efefef;
    color: #616161;
    text-transform: uppercase;
    font-size: 14px;
    padding: 7px 18px;

    &:not(:disabled) {
        &.active,
        &:hover {
            background-color: #4caf50;
            color: #fff;
        }
    }
    &:disabled {
        cursor: not-allowed;
    }
}
</style>

<style lang="scss">
.locked-wrapper {
    position: absolute;
    top: 0;
    width: calc(100% - 25px);
    height: calc(100%);
    z-index: 1;
    cursor: not-allowed;
    border-radius: 5px;

    &.locked {
        background: rgb(165 165 165 / 10%);

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("/lock-thin.svg") no-repeat center;
            background-size: 80px;
            opacity: 0.1;
        }
    }

    &.special-height {
        height: calc(100% - 25px);
        top: 12px;
    }
}
.position-relative {
    position: relative;
}
</style>
