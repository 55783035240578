<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :attrs="attrs" color="primary" dark icon small v-on="on">
                <v-icon>mdi-tag-multiple-outline</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-toolbar color="primary" dark>
                Adauga legatura noua
            </v-toolbar>

            <v-card-text>
                <v-tabs v-model="activeTab">
                    <v-tab v-for="(tabObj, index) in tabs" :key="index">
                        <span class="text-body-2">{{ tabObj.label }}</span>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item v-for="(tabObj, index) in tabs" :key="index">
                        <component
                            :is="tabObj.component"
                            :task="item"
                            class="mt-5"
                            @selected="selected"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import TaskTagSelectorAuctions from "@/components/tasks/TaskTagSelector/taskTagSelectorAuctions";

export default {
    components: { TaskTagSelectorAuctions },
    props: {
        item: {}
    },
    inject: {
        listState: { default: undefined }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            activeTab: 0,
            tabs: [
                {
                    label: "Loturi - Licitatii",
                    component: "task-tag-selector-auctions"
                }
            ]
        };
    },
    methods: {
        selected() {
            this.listState.reload();
            this.dialog = false;
        }
    }
};
</script>
