<template>
    <v-menu
        bottom
        min-width="200px"
        offset-y
        rounded
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip bottom class="pa-0">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                        v-on="{ ...onMenu, ...onTooltip }"
                        :disabled="loading"
                        :loading="loading"
                        class="ml-n1 order-1"
                        color="primary"
                        icon
                        outlined
                        small
                        @click="loadListOfPossibleUsersToAssign"
                    >
                        <v-avatar size="30">
                            <v-icon size="15">
                                mdi-account-plus-outline
                            </v-icon>
                        </v-avatar>
                    </v-btn>
                </template>
                <span class="text-caption">Asigneaza</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-list dense>
                <v-list-item-group multiple>
                    <template v-if="possibleUsers.length">
                        <v-list-item
                            v-for="(user, index) in possibleUsers"
                            :key="index"
                            @click="addUser(user)"
                        >
                            <v-list-item-avatar color="primary" size="30">
                                <span
                                    class="text-caption white--text text-uppercase"
                                >
                                    {{ user.name.match(/\b(\w)/g).join("") }}
                                </span>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="user.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-else-if="loading">
                        <v-list-item>
                            Se incarca.
                        </v-list-item>
                    </template>
                    <template v-else>
                        <v-list-item>
                            Nu exista utilizatori disponibili.
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    props: {
        item: {
            required: true
        },
        endpoint: {
            type: String,
            default: "api/user/responsibles"
        },
        payloadName: {
            type: String,
            default: "responsibles"
        }
    },
    inject: {
        listState: { default: undefined }
    },
    data() {
        return {
            loading: false,
            possibleUsers: []
        };
    },
    methods: {
        loadListOfPossibleUsersToAssign() {
            new Promise((resolve, reject) => {
                this.loading = true;
                this.$admin.http
                    .get(`/${this.endpoint}/${this.item.id}`)
                    .then(({ data }) => {
                        this.possibleUsers = data.data;
                        resolve();
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        addUser(user) {
            new Promise((resolve, reject) => {
                let payload = {}
                payload[this.payloadName] = [user]

                this.loading = true;
                this.$admin.http
                    .put(`/${this.endpoint}/${this.item.id}`, payload)
                    .then(({ data }) => {
                        this.loadListOfPossibleUsersToAssign();
                        if (this.listState) {
                            this.$store.dispatch(
                                "api/refresh",
                                this.$route.meta.resource
                            );
                        } else {
                            this.item.users = data.data.users;
                        }
                        resolve();
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loading = false;
                    });
            });
        }
    }
};
</script>
