<template>
    <v-card
        @drop.prevent="onDrop($event)"
        @dragover.prevent="startDragover"
        @dragenter.prevent="startDragover"
        @dragleave.prevent="stopDragover"
    >
        <v-card-text>
            <v-row>
                <v-col>
                    <div
                        :class="{ dragover: dragover }"
                        class="dropzone-container"
                    >
                        <div class="file-selector pa-3 text-center">
                            <v-icon x-large>mdi-cloud-upload-outline</v-icon>
                            <div class="mt-3">
                                Trage fișierele aici pentru a le atașa.
                                <!--                        sau-->
                                <!--                        <button-->
                                <!--                            class="text-decoration-underline primary&#45;&#45;text text-body-1"-->
                                <!--                            type="button"-->
                                <!--                        >-->
                                <!--                            click aici-->
                                <!--                        </button>-->
                                <!--                        pentru a le selecta din calculator.-->
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <select-quotation
                        :batch-id="batchId"
                        @supplier-added="$emit('supplier-added-parent')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <quotation-info
            :attachments="uploadedFiles"
            :batch-id="batchId"
            :dialog.sync="dialog"
            @supplier-added="$emit('supplier-added-parent')"
        />
    </v-card>
</template>

<script>
import QuotationInfo from "@/components/resources/productQuotations/QuotationInfo";
import SelectQuotation from "./SelectQuotation";

export default {
    name: "UploadQuotation",
    components: { SelectQuotation, QuotationInfo },
    props: {
        attachToAuctionBatch: {
            default: null
        }
    },
    data() {
        return {
            dragover: false,
            uploadedFiles: [],
            dialog: false
        };
    },
    computed: {
        batchId() {
            return this.attachToAuctionBatch
                ? this.attachToAuctionBatch.id
                : null;
        }
    },
    methods: {
        stopDragover() {
            this.dragover = false;
        },
        startDragover() {
            this.dragover = true;
        },
        onDrop(e) {
            this.stopDragover();
            this.clearFiles();

            e.dataTransfer.files.forEach(element =>
                this.uploadedFiles.push(element)
            );

            this.startUploadDialog();
        },
        clearFiles() {
            if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
        },
        startUploadDialog() {
            this.dialog = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.dropzone-container {
    background: #f6f6f6;
    border: 1px dashed #707070;
    border-radius: 5px;
    cursor: pointer;

    &.dragover {
        border-style: solid;
        background: #adadad;
    }
}
</style>
