<template>
    <div>
        <template v-if="loading">
            <div class="text-center">
                <v-progress-circular
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>
        </template>
        <template v-else>
            <template v-if="notes.length">
                <ul class="notes-list pl-0 tree overflow-x-auto">
                    <template v-for="(note, index) in notes">
                        <note
                            v-if="!note.task_id"
                            :key="index"
                            :note="note"
                            @note-saved="getNotes"
                        >
                            <note-color-identifier
                                :note="note"
                                @color-changed="getNotes"
                            />
                        </note>
                        <task-note v-else :task="note.task" :key="index" />
                    </template>
                </ul>
            </template>
            <template v-else>
                Nu au fost adaugate notite.
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Note from "@/components/resources/auctions/Notes/Note";
import NoteColorIdentifier from "@/components/resources/auctions/Notes/NoteColorIdentifier";
import TaskNote from "@/components/resources/auctions/Notes/TaskNote";

export default {
    name: "Notes",
    components: { TaskNote, NoteColorIdentifier, Note },
    props: {
        auctionId: {},
        batchId: {}
    },
    data() {
        return {
            notes: [],
            loading: false
        };
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            countByBatch: state => state.countByBatch
        })
    },
    methods: {
        deleteNote(note_id) {
            console.log(note_id);
        },
        getNotes() {
            this.loading = true;
            this.$admin.http
                .get(
                    `/api/auction_notes?auction_id=${this.auctionId}&batch_id=${this.batchId}`
                )
                .then(({ data }) => {
                    this.notes = data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.getNotes();
    }
};
</script>

<style lang="scss">
.notes-list {
    list-style-type: none;

    .notification-separator {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__separator-text {
            background: #c53232;
            border-radius: 15px;
            color: #fff;
            padding: 5px 0;
            bottom: -16px;
            position: relative;
            z-index: 1;

            span {
                padding: 0 25px;
            }
        }

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background: #c53232;
        }
    }
}

ul.tree {
    ul {
        margin-left: 20px;
        padding-left: 0;

        li {
            position: relative;

            .curve-path {
                svg {
                    width: 15px;
                    left: -20px;
                    position: absolute;
                    top: calc(50% - 6px);
                    z-index: 3;

                    path {
                        stroke: currentColor;
                    }
                }
            }
        }
    }

    li:last-child:before {
        border-left: 1px solid rgb(100, 100, 100);
    }

    .tree-item {
        position: relative;

        .root-node-line {
            display: inline-block;
            width: 12px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: transparent;
            border-left: 2px solid;
            border-radius: 4px;
            z-index: 1;
        }
    }

    > li {
        > div {
            svg {
                display: none;
            }
        }
    }
}
</style>
