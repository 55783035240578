var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Completează următoarele rubrici pentru a putea atașa oferta. ")]),_c('v-card-text',[_c('p',[_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(" Oferta "+_vm._s(_vm.activeAttachment + 1)+"/"+_vm._s(_vm.attachments.length)+" ")]),_vm._v(" ("+_vm._s(_vm.activeItemFileName)+") ")]),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"errors":_vm.errors.product,"api":"api/supplier_types","label":"Selectează tipul de furnizor:"},model:{value:(_vm.attachmentItemInfo.supplier_type_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "supplier_type_id", $$v)},expression:"attachmentItemInfo.supplier_type_id"}}),(_vm.errors)?_vm._l((_vm.errors.supplier_type_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"additional-fields-to-create":{
                                type_id: _vm.attachmentItemInfo.supplier_type_id
                            },"disabled":!_vm.attachmentItemInfo.supplier_type_id,"errors":_vm.errors.product,"api":"api/suppliers","label":"Selectează un furnizor:"},model:{value:(_vm.attachmentItemInfo.supplier_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "supplier_id", $$v)},expression:"attachmentItemInfo.supplier_id"}}),(_vm.errors)?_vm._l((_vm.errors.supplier_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"disabled":!(
                                    _vm.attachmentItemInfo.supplier_type_id &&
                                    _vm.attachmentItemInfo.supplier_id
                                ),"errors":_vm.errors.product,"api":"api/product-types","label":"Selecteaza tipul de produs:"},model:{value:(_vm.attachmentItemInfo.type_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "type_id", $$v)},expression:"attachmentItemInfo.type_id"}}),(_vm.errors)?_vm._l((_vm.errors.type_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"disabled":!(
                                    _vm.attachmentItemInfo.supplier_id &&
                                    _vm.attachmentItemInfo.type_id
                                ),"errors":_vm.errors.product,"api":"api/products","disabled-note":"Selectati un Furnizor si un Tip de produs","label":"Selectează un model:","additionalFieldsToCreate":{
                                supplier_id: _vm.attachmentItemInfo.supplier_id,
                                type_id: _vm.attachmentItemInfo.type_id
                            }},model:{value:(_vm.attachmentItemInfo.product_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "product_id", $$v)},expression:"attachmentItemInfo.product_id"}}),(_vm.errors)?_vm._l((_vm.errors.product_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":"Adaugă un preț:","outlined":""},model:{value:(_vm.attachmentItemInfo.price),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "price", $$v)},expression:"attachmentItemInfo.price"}}),(_vm.errors)?_vm._l((_vm.errors.price),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2),_c('v-col',{staticClass:"pa-2"},[_c('v-select',{attrs:{"items":_vm.currencyList,"dense":"","hide-details":"","item-text":"label","item-value":"value","label":"Moneda","outlined":""},model:{value:(_vm.attachmentItemInfo.currency),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "currency", $$v)},expression:"attachmentItemInfo.currency"}}),(_vm.errors)?_vm._l((_vm.errors.currency),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-row',[_c('v-col',{staticClass:"px-4"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"block":""},on:{"click":_vm.goNext}},[_vm._v(" Treci peste ")])],1),_c('v-col',{staticClass:"px-4"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"block":"","color":"success"},on:{"click":_vm.saveQuotation}},[(_vm.currentItemIsLast)?[_vm._v(" ATASEAZA OFERTA SI INCHIDE ")]:[_vm._v(" ATASEAZA OFERTA SI CONTINUA LA URMATOAREA ")]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }