<template>
    <div class="d-flex justify-center align-center">
        <div
            v-for="(responsible, index) in users"
            :key="index"
            class="responsible-list"
        >
            <responsible-item
                :item="item"
                :responsible="responsible"
                :endpoint="endpoint"
                :payloadName="payloadName"
            />
        </div>

        <add-new-responsible-to-auction 
            :item="item" 
            :endpoint="endpoint" 
            :payloadName="payloadName" 
        />
    </div>
</template>

<script>
import ResponsibleItem from "./ResponsibleItem";
import AddNewResponsibleToAuction from "./AddNewResponsibleToAuction";

export default {
    name: "responsibleList",
    components: {
        AddNewResponsibleToAuction,
        ResponsibleItem
    },
    props: {
        item: {
            required: true
        },
        users: {
            required: true
        },
        endpoint: {
            type: String,
            default: "api/user/responsibles"
        },
        payloadName: {
            type: String,
            default: "responsibles"
        }
    }
};
</script>
