<template>
    <va-create-layout>
        <auction-interests-form
            :item="item"
            :title="title"
        ></auction-interests-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"]
};
</script>
