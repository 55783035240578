<template>
    <v-btn
        :loading="loading"
        color="primary"
        dark
        icon
        @click="toggleFeatured"
    >
        <v-icon v-if="item.is_featured">mdi-star</v-icon>
        <v-icon v-else>mdi-star-outline</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        item: {
            required: true
        }
    },
    inject: {
        listState: {
            default: undefined
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        toggleFeatured() {
            this.loading = true;
            this.$admin.http
                .patch(`/api/auction/${this.item.id}/toggle-featured`)
                .then(() => {
                    if (this.listState) {
                        this.listState.reload();
                    } else {
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                    }
                    this.loading = false;
                });
        }
    }
}
</script>
