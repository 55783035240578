<template>
    <div>
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small text>
                    <v-icon left>
                        mdi-circle-edit-outline
                    </v-icon>
                    <span class="hidden-sm-and-down">Editeaza</span>
                </v-btn>
            </template>

            <v-card>
                <v-toolbar color="primary" dark>Adauga notita noua</v-toolbar>

                <v-card-text>
                    <v-textarea
                        v-model="body"
                        label="Notita"
                        name="input-7-4"
                        outlined
                    ></v-textarea>
                    <template v-if="errors">
                        <span
                            v-for="(error, index) in errors.body"
                            :key="index"
                            class="error pa-1 white--text rounded"
                            >{{ error }}
                        </span>
                    </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="disabled" text @click="dialog = false">
                        Renunta
                    </v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        color="primary"
                        text
                        @click="saveNote"
                    >
                        Salveaza
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "EditNote",
    props: {
        auctionId: {
            required: true
        },
        note: {
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            body: "",
            errors: {},
            loading: false
        };
    },
    methods: {
        saveNote() {
            this.loading = true;
            this.errors = {};
            this.$admin.http
                .patch(`/api/auction_notes/${this.note.id}`, {
                    body: this.body
                })
                .then(() => {
                    this.dialog = false;
                    this.$parent.$emit("note-saved");
                    this.body = "";
                })
                .catch(error => {
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        dialog(state) {
            if (state === true) {
                this.body = this.note.body;
            }
        }
    }
};
</script>

<style scoped></style>
