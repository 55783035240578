<template>
    <va-create-layout>
        <users-form :item="item" :title="title"></users-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"]
};
</script>
