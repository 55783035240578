export default {
    namespaced: true,
    state: {
        price_analysis: {},
        product_total: 0,
        currencies: {},
        product_types: [],
        ron_buget: 0,
        eur_buget: 0,
        bnr_auction_rate: 0,
        acquisition_rate: false,
        can_generate: false
    },
    getters: {
        price_analysis: state => {
            return state.price_analysis;
        },

        product_total: state => {
            return state.product_total;
        },

        currencies: state => {
            return state.currencies;
        },

        ron_buget: state => {
            return state.ron_buget;
        },

        eur_buget: state => {
            return state.eur_buget;
        },

        bnr_auction_rate: state => {
            return state.bnr_auction_rate;
        },

        acquisition_rate: state => {
            return state.acquisition_rate;
        },

        can_generate: state => {
            return state.can_generate;
        }
    },
    mutations: {
        updateProductTotal(state, payload) {
            state.product_total = payload;
        },

        updateCurrencies(state, payload) {
            state.currencies = payload;
        },

        updateProductTypes(state, payload) {
            state.product_types = payload;
        },

        updateRONBuget(state, payload) {
            state.ron_buget = payload;
        },

        updateEURBuget(state, payload) {
            state.eur_buget = payload;
        },

        updateAcquisitionRate(state, payload) {
            state.acquisition_rate = payload;
        },

        updatePriceAnalysis(state, payload) {
            state.price_analysis = payload;
        },

        updateBNRAuctionRate(state, payload) {
            state.bnr_auction_rate = payload;
        },

        calculateEURBuget(state, payload) {
            state.eur_buget = (payload.ron_buget / payload.bnr_auction_rate).toFixed(2)
        },

        updateCanGenerate(state, payload) {
            state.can_generate = payload
        }
    },
    actions: {
        getPriceAnalysis({ commit, dispatch }, { vm, batch_id }) {
            return vm.$admin.http
                .get(`/api/price-analysis/${batch_id}`)
                .then(({ data }) => {
                    commit("updatePriceAnalysis", data.data);
                    dispatch("getAcquisitionRate", { vm: vm });
                    dispatch("getAuctionBuget", { vm: vm });
                    dispatch("lookupCurrencies", { vm: vm });
                });
        },

        getAcquisitionRate({ commit, getters }, { vm }) {
            return vm.$admin.http
                .get(`/api/price-analysis/course-acquisition/${getters.price_analysis.id}`)
                .then(({ data }) => {
                    commit("updateAcquisitionRate", data.data);
                });
        },

        getAuctionBuget({ commit, getters }, { vm }) {
            return vm.$admin.http
                .get(`/api/price-analysis/auction-buget/${getters.price_analysis.id}`)
                .then(({ data }) => {
                    commit("updateBNRAuctionRate", data.data.bnr_auction_rate);
                    commit("updateRONBuget", data.data.ron_buget);
                    commit("calculateEURBuget", {
                        ron_buget: data.data.ron_buget,
                        bnr_auction_rate: data.data.bnr_auction_rate
                    });
                });
        },

        updateAuctionBuget({ commit, getters }, { vm, payload }) {
            return vm.$admin.http
                .put(`/api/price-analysis/auction-buget/${getters.price_analysis.id}`,{
                        bnr_auction_rate: payload.bnr_auction_rate,
                        ron_buget: payload.ron_buget
                })
                .then(({ data }) => {
                    commit("updateBNRAuctionRate", data.data.bnr_auction_rate);
                    commit("updateRONBuget", data.data.ron_buget);
                    commit("calculateEURBuget", {
                        ron_buget: data.data.ron_buget,
                        bnr_auction_rate: data.data.bnr_auction_rate
                    });
                });
        },

        updateProducts({ commit }, { vm, payload, package_product_id }) {
            return vm.$admin.http
                .put(`/api/price-analysis/update-products/${package_product_id}`, {
                        products: payload
                })
                .then(({ data }) => {
                    commit("updateProductTotal", data.data.total);
                });
        },

        lookupCurrencies({ commit }, { vm }) {
            return vm.$admin.http
                .get(`/api/price-analysis/lookup-currencies`)
                .then(({ data }) => {
                    commit("updateCurrencies", data.data);
                });
        },

        lookupProductTypes({ commit }, { vm }) {
            return vm.$admin.http
                .get(`/api/price-analysis/lookup-supplier-types`)
                .then(({ data }) => {
                    commit("updateProductTypes", data.data);
                });
        },

        lookupPenalty({ commit, getters }, { vm }) {
            return vm.$admin.http
                .get(`/api/price-analysis/lookup-penalty/${getters.price_analysis.id}`)
                .then(({ data }) => {
                    commit("updatePenalty", data.data);
                });
        },

        lookupLockedSaleOption({ commit }, { vm, batch_id }) {
            return vm.$admin.http
                .get(`/api/price-analysis/lookup-locked-sale-option/${batch_id}`)
                .then(({ data }) => {
                    commit("updateCanGenerate", data.data);
                });
        }
    }
}
