<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <va-text-input
                            required
                            source="name"
                            label="Nume"
                        ></va-text-input>
                        <va-number-input
                            label="Numar zile predefinite"
                            source="number_of_days"
                        ></va-number-input>
                        <va-select-input
                            :choices="is_fix_date_choices"
                            source="is_fix_date"
                            label="Metoda de calculare a termenului"
                        ></va-select-input>
                        <va-select-input
                            :choices="is_before_choices"
                            source="is_before"
                            label="Cum se calculeaza termenul in functie de data selectata?"
                        ></va-select-input>
                        <va-select-input
                            :choices="used_date_column_choices"
                            label="Ce coloana se foloseste la calculatie?"
                            required
                            source="used_date_column"
                        ></va-select-input>
                        <va-color-picker-input
                            required
                            label="Culoare"
                            source="color"
                        ></va-color-picker-input>
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
export default {
    props: ["id", "title", "item"],
    data() {
        return {
            used_date_column_choices: [
                { value: "original_created_at", text: "Data aparitiei" },
                { value: "deadline_at", text: "Data limita" }
            ],
            is_before_choices: [
                { value: true, text: "Inainte de data de mai jos" },
                { value: false, text: "Dupa data de mai jos" }
            ],
            is_fix_date_choices: [
                { value: true, text: "Termenul este o data fixa" },
                { value: false, text: "Termenul se calculeaza" }
            ]
        };
    }
};
</script>
