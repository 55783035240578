<template>
    <v-list-item>
        <template v-if="item.downloaded_at">
            <v-list-item-icon class="mr-2">
                <v-icon color="primary">
                    mdi-file-document-outline
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-btn
                    :href="item.internal_link"
                    class="justify-start"
                    plain
                    text
                    target="_blank"
                    >{{ item.name }}
                </v-btn>
            </v-list-item-content>
        </template>
        <template v-else>
            <v-list-item-icon class="mr-2">
                <template v-if="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </template>
                <template v-else>
                    <v-icon class="ma-0" color="primary">
                        mdi-file-download-outline
                    </v-icon>
                </template>
            </v-list-item-icon>
            <v-list-item-content class="py-0 justify-start">
                <v-btn class="justify-start" text @click="downloadFile">
                    {{ item.name }}
                </v-btn>
            </v-list-item-content>
        </template>
    </v-list-item>
</template>

<script>
export default {
    name: "FileItem",
    props: {
        item: {
            required: true
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        downloadFile() {
            this.loading = true;
            this.$admin.http
                .patch(`/api/auction_files/${this.item.id}/download-file`)
                .then(({ data }) => {
                    const win = window.open(data.internal_link, "_blank");
                    win.focus();
                    this.$emit("file-downloaded");
                })
                .catch(error => {
                    alert(error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped></style>
