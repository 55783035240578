<template>
    <div class="mt-1 flex">
        <span class="error pa-1 white--text rounded text-body-2 d-block">{{
            message
        }}</span>
    </div>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        message: {}
    }
};
</script>
