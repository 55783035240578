<template>
    <v-col cols="12" md="8" class="position-relative">
        <div
            :class="{
                'locked-wrapper': !batch.price_analysis.can_be_edited,
                'locked-wrapper special-height locked':
                    batch.price_analysis.has_sale_option_selected
            }"
        ></div>
        <v-data-table
            :headers="headers"
            :items="prices"
            hide-default-footer
            class="price-analysis-table"
        >
            <template v-slot:item.type="{ item }">
                <v-text-field
                    :value="supplierNameById(item)"
                    hide-details
                    outlined
                    dense
                    class="price-analysis-table__input"
                    disabled
                />
            </template>
            <template v-slot:item.price="{ item }">
                <v-text-field
                    placeholder="Adauga Pret"
                    v-model="item.price"
                    number
                    @input="productTotal(item)"
                    :disabled="item.supplier_type_id === 1"
                    outlined
                    dense
                    hide-details
                ></v-text-field>
            </template>

            <template v-slot:item.product_item="{ item }">
                <v-select
                    v-if="item !== undefined && item.supplier_type_id === 1"
                    v-model="item.product_id"
                    :items="products"
                    item-text="name"
                    item-value="product_id"
                    placeholder="Selecteaza Produs"
                    outlined
                    dense
                    hide-details
                    style="max-width: 335px"
                    @change="productSelected($event, item)"
                ></v-select>
                <v-text-field
                    v-else
                    v-model="item.product_name"
                    hide-details
                    outlined
                    dense
                    class="price-analysis-table__input"
                    @change="changeSelect($event, item)"
                />
            </template>
            <template v-slot:item.qty="{ item }">
                <v-text-field
                    placeholder="Adauga Cantitate"
                    v-model="item.qty"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @input="productTotal(item)"
                ></v-text-field>
            </template>
            <template v-slot:item.total="{ item }">
                <span class="price-input">
                    {{
                        Number(item.total).toLocaleString("ro-RO", {
                            maximumFractionDigits: 2
                        })
                    }}
                </span>
            </template>
            <template v-slot:item.supplier="{ item }">
                <v-select
                    v-model="item.supplier_id"
                    :items="suppliers"
                    item-text="name"
                    item-value="id"
                    placeholder="Selecteaza Furnizor"
                    outlined
                    dense
                    :disabled="item.supplier_type_id === 1"
                    @change="updateProducts"
                    hide-details
                ></v-select>
            </template>
            <template v-slot:item.actions="{ index }">
                <span>
                    <v-btn
                        v-if="index > 0"
                        class=""
                        icon
                        x-small
                        dark
                        @click="moveItemUp(index)"
                        color="#949494"
                    >
                        <v-icon>
                            fal fa-arrow-square-up
                        </v-icon>
                    </v-btn>
                </span>

                <span class="v-btn--icon v-size--x-small d-inline-block">
                    <v-btn
                        v-if="index + 1 !== Object.values(prices).length"
                        class=""
                        icon
                        x-small
                        dark
                        color="#949494"
                        @click="moveItemDown(index)"
                    >
                        <v-icon>
                            fal fa-arrow-square-down
                        </v-icon>
                    </v-btn>
                </span>
                <v-btn icon x-small color="red" dark @click="deleteItem(index)">
                    <v-icon>
                        fal fa-minus-circle
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-col>
</template>

<script>
import OfferAnalysis from "../../../events/OfferAnalysis";

export default {
    name: "PriceAnalysisTable",

    props: {
        batch: {
            type: Object,
            default: () => {}
        },
        supplierTypes: {
            type: Array,
            default: () => []
        },
        newProduct: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            products: [],
            suppliers: [],
            package: [],
            headers: [
                {
                    text: "Tip",
                    align: "start",
                    value: "type",
                    sortable: false,
                    width: "110"
                },
                {
                    text: "Produs",
                    value: "product_item",
                    sortable: false,
                    width: "335"
                },
                { text: "Pret", value: "price", sortable: false, width: "100" },
                {
                    text: "Furnizor",
                    value: "supplier",
                    sortable: false,
                    width: "200"
                },
                { text: "Cantitate", value: "qty", sortable: false },
                { text: "Total", value: "total", sortable: false, width: "95" },
                {
                    text: "",
                    value: "actions",
                    sortable: false,
                    width: "80",
                    align: "end"
                }
            ],
            prices: []
        };
    },

    mounted() {
        OfferAnalysis.$on("sendOfferToAnalysis", payload => {
            this.pushProduct(payload);
        });
        OfferAnalysis.$on("reloadLookupProducts", () => {
            this.loadLookupProducts();
        });
    },

    created() {
        this.loadLookupSuppliers();
        this.loadLookupProducts();
        this.loadProducts();
    },

    methods: {
        supplierNameById(item) {
            if (item !== undefined) {
                const supplierName = this.supplierTypes.find(
                    element => element.id === item.supplier_type_id
                );
                return supplierName ? supplierName.name : "";
            }
        },
        loadProducts() {
            this.$admin.http
                .get(`/api/price-analysis/product-table/${this.batch.id}`)
                .then(({ data }) => {
                    this.$store.commit(
                        "price_analysis/updateProductTotal",
                        data.data.total
                    );
                    this.prices = data.data.products;
                    this.package = data.data;
                });
        },

        loadLookupSuppliers() {
            this.$admin.http
                .get(`/api/price-analysis/lookup-suppliers`)
                .then(({ data }) => {
                    this.suppliers = data.data;
                });
        },

        loadLookupProducts() {
            this.$admin.http
                .get(`/api/price-analysis/lookup-products/${this.batch.id}`)
                .then(({ data }) => {
                    this.products = data.data;
                });
        },

        reorderProducts() {
            this.$admin.http.patch(
                `/api/price-analysis/reorder-products/${this.package.id}`,
                { products: this.prices }
            );
        },

        updateProducts() {
            this.$store.dispatch("price_analysis/updateProducts", {
                vm: this,
                payload: this.prices,
                package_product_id: this.package.id
            });
            // event for exchange and options
        },

        productSelected(selectedValue, item) {
            const product = this.products.find(
                element => element.product_id === selectedValue
            );
            item.price = product.price;
            item.total = product.price;
            item.supplier_id = product.supplier_id;

            this.updateProducts();
        },

        changeSelect(value, item) {
            this.$nextTick(() => {
                const existingElement = this.prices.find(
                    element => element.id === value
                );
                // console.log("needs debugging", existingElement, item);
                if (existingElement !== undefined) {
                    this.prices.splice(
                        this.prices.findIndex(
                            element =>
                                element.id === item.id &&
                                element.type.name === item.type.name
                        ),
                        1
                    );
                    existingElement.qty =
                        Number(existingElement.qty) + Number(item.qty);
                    this.productTotal(existingElement);
                } else {
                    this.updateProducts();
                }
            });
        },

        productTotal(item) {
            if (!isNaN(item.price) || !isNaN(item.qty)) {
                item.total = item.price * item.qty;
            }
            this.updateProducts();
        },

        pushProduct(product) {
            const supplier = Object.values(this.suppliers).find(
                element => element.id === product.supplier_id
            );

            const transportSupplier = Object.values(this.suppliers).find(
                element => element.type.name === "Transport"
            );

            this.prices.push(
                {
                    product_id: product.product.id,
                    supplier_id: supplier.id,
                    supplier_type_id: supplier.type_id,
                    price: product.price,
                    qty: 1,
                    total: product.price
                },
                {
                    product_name: "",
                    supplier_id: transportSupplier.id,
                    supplier_type_id: transportSupplier.type_id,
                    price: 1,
                    qty: 1,
                    total: 1
                }
            );
        },

        addProduct() {
            this.prices.push({
                supplier_type_id: this.newProduct.id,
                product_id: null,
                product_name: "",
                supplier_id: null,
                qty: 1,
                price: 1,
                total: 1
            });

            if (this.newProduct.name === "Produs") {
                this.$nextTick(() => {
                    this.prices.push({
                        supplier_type_id: 2,
                        product_id: null,
                        product_name: "",
                        supplier_id: null,
                        qty: 1,
                        price: 1,
                        total: 1
                    });
                });
            }
            this.updateProducts();
            this.$emit("clear-product");
        },

        moveItemUp(index) {
            this.moveItem(this.package.products, index, index - 1);
            this.$nextTick(() => {
                this.updateProducts();
            });
        },

        moveItemDown(index) {
            this.moveItem(this.package.products, index, index + 1);
            this.$nextTick(() => {
                this.updateProducts();
            });
        },

        deleteItem(index) {
            if (index > -1) {
                this.prices.splice(index, 1);
            }
            this.$nextTick(() => {
                this.updateProducts();
            });
        },

        moveItem(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length;
                while (k-- + 1) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        }
    },

    watch: {
        newProduct: function(newVal, oldVal) {
            if (newVal !== oldVal && newVal !== null) {
                this.addProduct();
            }
        }
    }
};
</script>

<style lang="scss">
.price-input {
    font-size: 14px;
    padding: 10px 12px;
    max-height: 40px;
    border-radius: 5px;
    color: #333333 !important;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    display: block;
}

.price-analysis-table {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    padding: 5px 10px;

    th {
        font-weight: normal;
        border-bottom: none !important;
        span {
            font-weight: normal;
        }
    }

    tr {
        background-color: #fff !important;

        td {
            border-bottom: none !important;
            padding: 7px !important;
        }
    }

    input {
        font-size: 14px;
        &::-webkit-input-placeholder {
            color: #949494;
            font-style: italic;
            font-size: 14px;
        }
        &::-moz-placeholder {
            color: #949494;
            font-style: italic;
            font-size: 14px;
        }
        &:-ms-input-placeholder {
            color: #949494;
            font-style: italic;
            font-size: 14px;
        }
        &:-moz-placeholder {
            color: #949494;
            font-style: italic;
            font-size: 14px;
        }
    }

    &__input {
        .v-input__slot {
            padding: 0 !important;
            margin: 0;

            fieldset {
                border: none;
            }
        }

        input {
            font-size: 14px;
            padding: 10px 12px;
            max-height: 40px;
            border-radius: 5px;
            color: #333333 !important;
            border: 1px solid rgba(0, 0, 0, 0.38);

            &:hover {
                border-color: rgba(0, 0, 0, 0.86);
            }

            &:focus {
                border: 2px solid #4278fc !important;
            }

            &:disabled {
                background-color: #f8f8f8;
                border-color: #f8f8f8;
            }

            &::-webkit-input-placeholder {
                color: #949494;
                font-style: italic;
                font-size: 14px;
            }
            &::-moz-placeholder {
                color: #949494;
                font-style: italic;
                font-size: 14px;
            }
            &:-ms-input-placeholder {
                color: #949494;
                font-style: italic;
                font-size: 14px;
            }
            &:-moz-placeholder {
                color: #949494;
                font-style: italic;
                font-size: 14px;
            }
        }

        &.special-height {
            input {
                min-height: 115px;

                @media (min-width: 1904px) {
                    min-height: 92px;
                }
            }
        }

        &.red-text {
            input {
                color: #ff0000 !important;
            }
        }

        &.green-text {
            input {
                color: #4caf50 !important;
            }
        }

        &.disabled-green {
            input {
                &:disabled {
                    background-color: #2ff9373d;
                }
            }
        }

        &.with-icon {
            input,
            input:focus {
                background-color: #f8f8f8;
                border-color: #f8f8f8 !important;
            }
            .v-input__append-inner {
                position: absolute;
                right: 5px;
            }
        }
    }

    .v-data-table__empty-wrapper {
        display: none;
    }

    .v-data-table__mobile-row {
        &__cell {
            width: 73%;
        }
    }
}
</style>
