<template>
    <div>
        <h4 class="mb-3">{{ customer.name }}</h4>
        <mb-field source="vat">
            {{ customer.vat }}
        </mb-field>
        <mb-field source="city">
            {{ customer.city }}
        </mb-field>
        <mb-field source="county">
            {{ customer.county }}
        </mb-field>
        <mb-field source="address">
            {{ customer.address }}
        </mb-field>
        <mb-field source="phone">
            {{ customer.phone }}
        </mb-field>
        <mb-field source="fax">
            {{ customer.fax }}
        </mb-field>
        <mb-field source="email">
            {{ customer.email }}
        </mb-field>
        <mb-field source="web">
            <template v-if="customer.web">
                <a :href="`//${customer.web}`" target="_blank">
                    {{ customer.web }}
                </a>
            </template>
        </mb-field>
    </div>
</template>

<script>
export default {
    name: "OrganizerDetails",
    props: {
        customer: {}
    }
};
</script>

<style scoped></style>
