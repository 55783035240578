var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"select-quotation-container"},'div',attrs,false),on),[_c('div',{staticClass:"text-center pa-3"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-cloud-download-outline")]),_c('div',{staticClass:"mt-3"},[_vm._v(" Sau alege din ofertele existente ")])],1)])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Completează următoarele rubrici pentru a putea atașa oferta. ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"errors":_vm.errors.product,"hideAddButton":true,"api":"api/supplier_types","label":"Selectează tipul de furnizor:"},model:{value:(_vm.attachmentItemInfo.supplier_type_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "supplier_type_id", $$v)},expression:"attachmentItemInfo.supplier_type_id"}}),(_vm.errors)?_vm._l((_vm.errors.supplier_type_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"api-additional":("&filter[type_id]=" + (_vm.attachmentItemInfo.supplier_type_id)),"disabled":!_vm.attachmentItemInfo.supplier_type_id,"errors":_vm.errors.product,"hideAddButton":true,"api":"api/suppliers","label":"Selectează un furnizor:"},model:{value:(_vm.attachmentItemInfo.supplier_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "supplier_id", $$v)},expression:"attachmentItemInfo.supplier_id"}}),(_vm.errors)?_vm._l((_vm.errors.supplier_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),(_vm.attachmentItemInfo.supplier_type_id === 1)?_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"disabled":!(
                                    _vm.attachmentItemInfo.supplier_type_id &&
                                    _vm.attachmentItemInfo.supplier_id
                                ),"errors":_vm.errors.product,"hideAddButton":true,"api":"api/product-types","label":"Selecteaza tipul de produs:"},model:{value:(_vm.attachmentItemInfo.type_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "type_id", $$v)},expression:"attachmentItemInfo.type_id"}}),(_vm.errors)?_vm._l((_vm.errors.type_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pa-2"},[_c('live-autocomplete',{attrs:{"additionalFieldsToCreate":{
                                supplier_id: _vm.attachmentItemInfo.supplier_id,
                                type_id: _vm.attachmentItemInfo.type_id
                            },"apiAdditional":_vm.productAutocompleteApiAdditional,"disabled":_vm.productAutocompleteDisabled,"errors":_vm.errors.product,"hideAddButton":true,"api":"api/products","disabled-note":"Selectati un Furnizor si un Tip de produs","label":"Selectează un model:","noResultMessage":"Nu exista oferta pt modelul ales"},on:{"changing":_vm.addQuotation},model:{value:(_vm.attachmentItemInfo.product_id),callback:function ($$v) {_vm.$set(_vm.attachmentItemInfo, "product_id", $$v)},expression:"attachmentItemInfo.product_id"}}),(_vm.errors)?_vm._l((_vm.errors.product_id),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1),(
                        Object.keys(_vm.attachmentItemInfo.quotation).length > 0
                    )?_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"value":_vm.attachmentItemInfo.quotation.price,"dense":"","disabled":"","hide-details":"","label":"Pret model","outlined":""}}),(_vm.errors)?_vm._l((_vm.errors.price),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2),_c('v-col',{staticClass:"pa-2"},[_c('v-select',{attrs:{"items":_vm.currencyList,"value":_vm.attachmentItemInfo.quotation.currency,"dense":"","disabled":"","hide-details":"","item-text":"label","item-value":"value","label":"Moneda","outlined":""}}),(_vm.errors)?_vm._l((_vm.errors.currency),function(error,index){return _c('ErrorMessage',{key:index,attrs:{"message":error}})}):_vm._e()],2)],1):_vm._e(),(
                        Object.keys(_vm.attachmentItemInfo.quotation).length > 0
                    )?_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('v-list',{attrs:{"rounded":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{staticClass:"v-list-item v-item--active v-list-item--active v-list-item--link theme--light",on:{"click":_vm.fileLink}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-clipboard-file-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Vezi oferta din "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate( _vm.attachmentItemInfo .quotation .created_at ))+" ")])])],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-row',[_c('v-col',{staticClass:"px-4"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.close}},[_vm._v(" Inchide ")])],1),_c('v-col',{staticClass:"px-4"},[_c('v-btn',{attrs:{"disabled":_vm.loading ||
                                    !(
                                        _vm.attachmentItemInfo.supplier_type_id &&
                                        _vm.attachmentItemInfo.supplier_id &&
                                        _vm.attachmentItemInfo.type_id &&
                                        _vm.attachmentItemInfo.product_id
                                    ),"loading":_vm.loading,"block":"","color":"success"},on:{"click":_vm.saveQuotation}},[_vm._v(" ATASEAZA ULTIMA OFERTA ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }