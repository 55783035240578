var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"horizontal-calendar"},[(_vm.dateList)?_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"action-col"},[_c('v-btn',{attrs:{"loading":_vm.loading},on:{"click":_vm.loadPrev}},[_c('v-icon',[_vm._v("fal fa-plus-square")])],1)],1),_vm._l((_vm.dateList),function(date,index){return [(date.date() === 1)?_c('div',{key:("month-" + index),staticClass:"day new-month"},[_c('span',[_vm._v(" "+_vm._s(date.format("MMMM"))+" ")])]):_vm._e(),_c('div',{key:("day-" + index + "}"),staticClass:"day",class:{
                    weekend: [6, 0].includes(date.day()),
                    today: _vm.isToday(date),
                    'first-week': _vm.isFirstWeek(date),
                    'second-week': _vm.isSecondWeek(date),
                    'third-week': _vm.isThirdWeek(date)
                }},[_c('div',{staticClass:"header"},[_c('div',[_vm._v(" "+_vm._s(date.format("dddd"))+" ")]),_c('div',[_vm._v(" "+_vm._s(date.date())+" ")])]),_c('div',{staticClass:"body"},_vm._l((_vm.groupedDeadlines[
                            date.format('DDMMYYYY')
                        ]),function(deadline,i){return _c('div',{key:i,staticClass:"my-2"},[_c('single-deadline-item',{attrs:{"deadline":deadline},on:{"reload":_vm.reload}})],1)}),0)])]}),_c('div',{staticClass:"action-col",staticStyle:{"margin-right":"4px"}},[_c('v-btn',{attrs:{"loading":_vm.loading},on:{"click":_vm.loadNext}},[_c('v-icon',[_vm._v("fal fa-plus-square")])],1)],1)],2):_vm._e(),_c('auction-legend-drawer',{attrs:{"isVisible":_vm.drawerVisible},on:{"open":_vm.drawerResponse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }