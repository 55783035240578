<template>
    <va-show-layout>
        <va-show :item="item">
            <v-row justify="center">
                <v-col>
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                {{ title }}
                            </div>
                        </template>
                        <v-card-text>
                            <va-field source="name"></va-field>

                            <va-field
                                reference="supplier_types"
                                source="type"
                                type="reference"
                            ></va-field>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                Oferte
                            </div>
                        </template>
                        <v-card-text>
                            <product-quotations-list :supplier="item" />
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </va-show>
    </va-show-layout>
</template>

<script>
import ProductQuotationsList from "@/components/resources/productQuotations/ProductQuotationsList";

export default {
    components: { ProductQuotationsList },
    props: ["title", "item"],
    data() {
        return {
            suppliers: []
        };
    }
};
</script>
