<template>
    <div>
        <v-dialog v-model="dialog" persistent scrollable width="500">
            <v-card>
                <v-toolbar color="primary" dark>
                    Completează următoarele rubrici pentru a putea atașa oferta.
                </v-toolbar>

                <v-card-text>
                    <p>
                        <span class="primary--text font-weight-bold">
                            Oferta {{ activeAttachment + 1 }}/{{
                                attachments.length
                            }}
                        </span>
                        ({{ activeItemFileName }})
                    </p>

                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.supplier_type_id"
                                :errors="errors.product"
                                api="api/supplier_types"
                                label="Selectează tipul de furnizor:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error,
                                    index) in errors.supplier_type_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.supplier_id"
                                :additional-fields-to-create="{
                                    type_id: attachmentItemInfo.supplier_type_id
                                }"
                                :disabled="!attachmentItemInfo.supplier_type_id"
                                :errors="errors.product"
                                api="api/suppliers"
                                label="Selectează un furnizor:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.supplier_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.type_id"
                                :disabled="
                                    !(
                                        attachmentItemInfo.supplier_type_id &&
                                        attachmentItemInfo.supplier_id
                                    )
                                "
                                :errors="errors.product"
                                api="api/product-types"
                                label="Selecteaza tipul de produs:"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.type_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2">
                            <live-autocomplete
                                v-model="attachmentItemInfo.product_id"
                                :disabled="
                                    !(
                                        attachmentItemInfo.supplier_id &&
                                        attachmentItemInfo.type_id
                                    )
                                "
                                :errors="errors.product"
                                api="api/products"
                                disabled-note="Selectati un Furnizor si un Tip de produs"
                                label="Selectează un model:"
                                :additionalFieldsToCreate="{
                                    supplier_id: attachmentItemInfo.supplier_id,
                                    type_id: attachmentItemInfo.type_id
                                }"
                            />
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.product_id"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-2" cols="9">
                            <v-text-field
                                v-model="attachmentItemInfo.price"
                                dense
                                hide-details
                                label="Adaugă un preț:"
                                outlined
                            >
                            </v-text-field>
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.price"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>

                        <v-col class="pa-2">
                            <v-select
                                v-model="attachmentItemInfo.currency"
                                :items="currencyList"
                                dense
                                hide-details
                                item-text="label"
                                item-value="value"
                                label="Moneda"
                                outlined
                            ></v-select>
                            <template v-if="errors">
                                <ErrorMessage
                                    v-for="(error, index) in errors.currency"
                                    :key="index"
                                    :message="error"
                                >
                                </ErrorMessage>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-row>
                        <v-col class="px-4">
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                block
                                @click="goNext"
                            >
                                Treci peste
                            </v-btn>
                        </v-col>
                        <v-col class="px-4">
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                block
                                color="success"
                                @click="saveQuotation"
                            >
                                <template v-if="currentItemIsLast">
                                    ATASEAZA OFERTA SI INCHIDE
                                </template>
                                <template v-else>
                                    ATASEAZA OFERTA SI CONTINUA LA URMATOAREA
                                </template>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import LiveAutocomplete from "@/components/inputs/LiveAutocomplete";
import ErrorMessage from "../../base/ErrorMessage";
import OfferAnalysis from "../../../events/OfferAnalysis";

export default {
    name: "QuotationInfo",
    components: { ErrorMessage, LiveAutocomplete },
    props: {
        dialog: {
            default: false
        },
        attachments: {},
        batchId: {
            default: null
        }
    },
    data() {
        return {
            batchName: "",
            errors: {},
            loading: false,
            activeAttachment: 0,
            attachmentItemInfo: {
                supplier_type_id: "",
                supplier_id: "",
                type_id: "",
                product_id: "",
                price: "",
                currency: "EUR"
            },
            currencyList: [
                {
                    label: "RON",
                    value: "RON"
                },
                {
                    label: "EUR",
                    value: "EUR"
                },
                {
                    label: "USD",
                    value: "USD"
                }
            ]
        };
    },
    computed: {
        activeItem() {
            return this.attachments[this.activeAttachment];
        },
        activeItemFileName() {
            return this.activeItem ? this.activeItem.name : "";
        },
        lastItemIndex() {
            return this.attachments.length - 1;
        },
        currentItemIsLast() {
            return this.activeAttachment === this.lastItemIndex;
        }
    },
    methods: {
        close() {
            this.activeAttachment = 0;
            this.resetFormData();
            this.$emit(`update:dialog`, false);
        },
        resetFormData() {
            this.attachmentItemInfo.supplier_type_id = "";
            this.attachmentItemInfo.supplier_id = "";
            this.attachmentItemInfo.type_id = "";
            this.attachmentItemInfo.product_id = "";
            this.attachmentItemInfo.price = "";
            this.attachmentItemInfo.currency = "";
        },
        saveQuotation() {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", this.activeItem);

            formData.append(
                "supplier_type_id",
                this.attachmentItemInfo.supplier_type_id
            );
            formData.append("supplier_id", this.attachmentItemInfo.supplier_id);
            formData.append("type_id", this.attachmentItemInfo.type_id);
            formData.append("product_id", this.attachmentItemInfo.product_id);
            formData.append("price", this.attachmentItemInfo.price);
            formData.append("currency", this.attachmentItemInfo.currency);

            if (this.batchId) {
                formData.append("batch_id", this.batchId);
            }

            this.$admin.http
                .post("/api/supplier-product-quotation", formData)
                .then(() => {
                    this.$emit("supplier-added");
                    this.goNext();
                    OfferAnalysis.$emit('reloadLookupProducts');
                })
                .catch(response => {
                    this.errors = response.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        goNext() {
            this.errors = {};
            if (this.currentItemIsLast) {
                this.close();
            } else {
                this.activeAttachment++;
                this.resetFormData();
                this.loading = false;
            }
        }
    }
};
</script>
