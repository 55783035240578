<template>
    <v-list-item class="px-2 checkbox-item">
        <v-list-item-action class="mr-2 my-2" @click="createTask">
            <v-icon>
                fal fa-plus
            </v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-textarea
                v-model="newTask"
                :auto-grow="true"
                class="text-body-2 checkbox-item__textarea"
                dense
                hide-details
                placeholder="Adauga subtask"
                rows="1"
                @keydown.enter.prevent="createTask"
            ></v-textarea>
        </v-list-item-content>
        <!--                <v-list-item-action class="d-flex flex-row">-->
        <!--                    <task-responsibles :item="item" />-->
        <!--                </v-list-item-action>-->
    </v-list-item>
</template>

<script>
export default {
    name: "Create",
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            newTask: ""
        };
    },
    methods: {
        createTask() {
            this.$admin.http
                .post(`/api/task-checklist-items`, {
                    task_content: this.newTask,
                    task_id: this.task.id
                })
                .then(() => {
                    this.newTask = "";
                    this.$emit("created");
                });
        }
    }
};
</script>
