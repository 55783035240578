import i18n from "@/i18n";

/**
 * Date format
 */
["ro", "en"].forEach(locale => {
    i18n.setDateTimeFormat(locale, {
        short: {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        },
        shortWithTime: {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long"
        }
    });
});

/**
 * Number format
 */
i18n.setNumberFormat("en", {
    currency: {
        style: "currency",
        currency: "EUR"
    }
});
i18n.setNumberFormat("ro", {
    currency: {
        style: "currency",
        currency: "EUR"
    }
});
