<template>
    <!--
      Toggle sidebar.
      @event input
    -->
    <v-navigation-drawer
        v-click-outside="onClickOutside"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :color="color"
        :dark="dark"
        :mini-variant="miniVariant"
        :value="value"
        app
        width="290"
        @input="v => $emit('input', v)"
    >
        <template v-slot:img="props">
            <!-- @slot Image background. -->
            <slot name="img" v-bind="props"></slot>
        </template>
        <v-list dense>
            <template v-for="(item, index) in menu.filter(l => l)">
                <v-subheader v-if="item.heading && !miniVariant" :key="index">
                    {{ item.heading }}
                </v-subheader>
                <v-divider v-else-if="item.divider" :key="index"></v-divider>
                <v-list-group
                    v-else-if="item.children && item.children.length"
                    :key="index"
                    v-model="item.expanded"
                    :prepend-icon="item.icon"
                    append-icon="mdi-chevron-up"
                    @click="$emit('toggle', item, index)"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}

                                <v-badge
                                    v-if="
                                        (item.id === 'archive' &&
                                            countArchive) ||
                                            (item.id === 'auctions' &&
                                                countAuctions)
                                    "
                                    class="ml-2"
                                    color="red"
                                    text-color="white"
                                >
                                    <template v-slot:badge>
                                        {{
                                            item.id === "auctions"
                                                ? countAuctions
                                                : countArchive
                                        }}
                                    </template>
                                </v-badge>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="(child, i) in item.children"
                        :key="i"
                        :to="child.link"
                        link
                    >
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                                <v-badge
                                    v-if="countStatus(child) !== undefined"
                                    class="ml-2"
                                    color="red"
                                    small
                                    text-color="white"
                                >
                                    <template v-slot:badge>
                                        {{ countStatus(child) }}
                                    </template>
                                </v-badge>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                    v-else-if="item.text"
                    :key="index"
                    :to="item.link"
                    link
                >
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import Notifications from "@/mixins/Notifications";
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";

/**
 * Default customizable admin VNavigationDrawer with full hierarchical menu and minimize variant.
 */
export default {
    mixins: [HasResourceSingleLink, Notifications],
    props: {
        /**
         * Main place for side menu, support hierarchical structure, MDI icons, heading and dividers.
         */
        menu: {
            type: Array,
            default: () => []
        },
        /**
         * Minimize the sidebar and show only icons.
         */
        miniVariant: Boolean,
        /**
         * Main color of VNavigationDrawer.
         */
        color: {
            type: String,
            default: "white"
        },
        /**
         * Apply dark theme variant for VNavigationDrawer
         */
        dark: Boolean,
        /**
         * Control visibility
         */
        value: null
    },

    created() {
        this.getNotificationTree();
    },

    methods: {
        onClickOutside() {
            if (!this.$vuetify.breakpoint.lgAndUp) {
                this.$emit("outsideClick");
            }
        },

        countStatus(child) {
            if (this.countByStatus !== null && child.link !== undefined) {
                return this.countByStatus[
                    this.getStatusIdByResource(child.link.name.split("_")[0])
                ];
            }
        }
    }
};
</script>
