<template>
    <div class="horizontal-calendar">
        <div v-if="dateList" class="inner-wrapper">
            <div class="action-col">
                <v-btn :loading="loading" @click="loadPrev">
                    <v-icon>fal fa-plus-square</v-icon>
                </v-btn>
            </div>
            <template v-for="(date, index) in dateList">
                <div
                    v-if="date.date() === 1"
                    :key="`month-${index}`"
                    class="day new-month"
                >
                    <span>
                        {{ date.format("MMMM") }}
                    </span>
                </div>
                <div
                    :key="`day-${index}}`"
                    :class="{
                        weekend: [6, 0].includes(date.day()),
                        today: isToday(date),
                        'first-week': isFirstWeek(date),
                        'second-week': isSecondWeek(date),
                        'third-week': isThirdWeek(date)
                    }"
                    class="day"
                >
                    <div class="header">
                        <div>
                            {{ date.format("dddd") }}
                        </div>
                        <div>
                            {{ date.date() }}
                        </div>
                    </div>
                    <div class="body">
                        <div
                            v-for="(deadline, i) in groupedDeadlines[
                                date.format('DDMMYYYY')
                            ]"
                            :key="i"
                            class="my-2"
                        >
                            <single-deadline-item
                                :deadline="deadline"
                                @reload="reload"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <div class="action-col" style="margin-right: 4px;">
                <v-btn :loading="loading" @click="loadNext">
                    <v-icon>fal fa-plus-square</v-icon>
                </v-btn>
            </div>
        </div>
        <auction-legend-drawer
            :isVisible="drawerVisible" @open="drawerResponse"
        />
    </div>
</template>
<script>
import moment from "moment";
import SingleDeadlineItem from "@/components/auctions/SingleDeadlineItem";
import AuctionLegendDrawer from "@/components/auctions/charts/AuctionLegendDrawer";
import _ from "lodash";

export default {
    name: "MbHorizontalCalendar",
    components: { 
        SingleDeadlineItem,
        AuctionLegendDrawer 
    },
    data() {
        return {
            dateList: [],
            deadlines: [],
            loading: false,
            first: null,
            last: null,
            drawerVisible: false
        };
    },
    methods: {
        createList() {
            let list = [];
            let date = this.first.clone();
            const totalColumns = this.last.diff(this.first, "days");
            for (let i = 0; i < totalColumns; i++) {
                list.push(date.clone().add(i, "days"));
            }

            this.dateList = list;
        },
        isFirstWeek(date) {
            return moment().isSame(date, "week");
        },
        isSecondWeek(date) {
            const thisMonday = this.firstDay.clone();

            const startingOfNextWeek = thisMonday
                .clone()
                .add("1", "week")
                .subtract(1, "day");
            const endingOfNextWeek = startingOfNextWeek
                .clone()
                .add("6", "days");

            return date.isBetween(startingOfNextWeek, endingOfNextWeek);
        },
        isThirdWeek(date) {
            const thisMonday = this.firstDay.clone();

            const startingOfWeekAfterNextWeek = thisMonday
                .clone()
                .add("2", "week")
                .subtract(1, "day");
            const endingOfWeekAfterNextWeek = startingOfWeekAfterNextWeek
                .clone()
                .add("6", "days");

            return date.isBetween(
                startingOfWeekAfterNextWeek,
                endingOfWeekAfterNextWeek
            );
        },
        isToday(date) {
            return moment().isSame(date, "day");
        },
        loadPrev() {
            const min = this.first.clone().subtract(7, "days");
            const max = this.first.clone().subtract(1, "day");
            this.loadItems(min, max);
            this.first = min.clone();
        },
        loadNext() {
            const min = this.last.clone().add(1, "day");
            const max = this.last.clone().add(7, "days");
            this.loadItems(min, max);
            this.last = max.clone();
        },
        loadItems(min, max, override = false) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$admin.http
                    .get(
                        `api/auction-deadlines?page_size=10000&filter[deadline_at]=${min.format(
                            "DD-MM-YYYY"
                        )}|${max.format(
                            "DD-MM-YYYY"
                        )}&include=auction,auction.interest,auction.category,auction.customer&filter[trashed]=with`
                    )
                    .then(({ data }) => {
                        if (override) {
                            this.deadlines = data.data;
                        } else {
                            this.deadlines = _.concat(
                                data.data,
                                this.deadlines
                            );
                        }
                        this.loading = false;
                        resolve();
                    })
                    .catch(e => reject(e));
            });
        },
        loadListItems() {
            this.loadItems(this.first, this.last, true).then(() => {
                this.createList();
            });
        },
        reload() {
            this.loadListItems();
        },
        drawerResponse(value) {
            this.drawerVisible = value
        }
    },
    computed: {
        groupedDeadlines() {
            return _.groupBy(this.deadlines, item => {
                return moment(item.deadline_at).format("DDMMYYYY");
            });
        },
        firstDay() {
            let dayNumber = moment().day();
            const dayToSubtract = dayNumber === 0 ? -6 : dayNumber - 1;
            return moment().subtract(dayToSubtract, "days");
        },
        lastDay() {
            return this.firstDay.clone().add(28, "days");
        },
        auctionDrawerState() {
            return  this.$store.getters["userSettings"] !== null
                    && this.$store.getters["userSettings"].auction_drawer_state !== undefined
                        ? this.$store.getters["userSettings"].auction_drawer_state
                        : undefined 
        }
    },
    watch: {
        deadlines: {
            deep: true,
            handler() {
                this.createList();
            }
        }
    },
    mounted() {
        this.drawerVisible = this.auctionDrawerState !== undefined ? this.auctionDrawerState : false
    },
    created() {
        this.first = this.firstDay.clone();
        this.last = this.lastDay.clone();
        this.reload();
    }
};
</script>

<style lang="scss" scoped>
.horizontal-calendar {
    margin-bottom: 15px;
    margin-top: 15px;
    display:flex;   

    .inner-wrapper {
        display: flex;
        overflow-x: scroll;

        .action-col {
            padding-top: 60px;
        }

        .day {
            min-width: 80px;
            min-height: 200px;
            border: 1px solid #888888;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;
            padding-left: 5px;
            text-align: center;
            padding-right: 5px;

            &.weekend {
                background: #cacaca;
            }

            &.today {
                background: #ffcdd2;
            }

            &.new-month {
                background: #4278fc;
                color: #ffffff;
                writing-mode: vertical-rl;
                text-orientation: upright;

                > span {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    transform: translateX(-15px);
                    height: 100%;
                    font-size: 24px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            &.first-week:not(.weekend) {
                .header {
                    background-color: #ffcdd2;
                }
            }

            &.second-week:not(.weekend) {
                .header {
                    background-color: #fff59d;
                }
            }

            &.third-week:not(.weekend) {
                .header {
                    background-color: #a5d6a7;
                }
            }

            .header {
                text-align: center;
                border-bottom: 1px solid #888888;
                margin-left: -5px;
                margin-right: -5px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
    }
}
</style>
