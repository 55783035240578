<template>
    <div>
        <v-dialog v-model="dialog" width="500" persistent>
            <v-card>
                <v-toolbar color="primary" dark>Schimbă denumirea</v-toolbar>

                <v-card-text>
                    <v-text-field
                        v-model="batchName"
                        outlined
                        name="input-7-4"
                        label="Titlu tab"
                    ></v-text-field>
                    <template v-if="errors">
                        <span
                            v-for="(error, index) in errors.batchName"
                            :key="index"
                            class="error pa-1 white--text rounded"
                            >{{ error }}
                        </span>
                    </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="disabled" text @click.native="close">
                        Renunta
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="saveBatchName"
                        :disabled="loading"
                        :loading="loading"
                    >
                        SCHIMBĂ DENUMIREA
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "RenameBatch",
    props: {
        batch: {
            required: true
        },
        auctionId: {
            required: true
        },
        dialog: {
            default: false
        }
    },
    data() {
        return {
            batchName: "",
            errors: {},
            loading: false
        };
    },
    computed: {},
    methods: {
        close() {
            this.$emit(`update:dialog`, false);
        },
        saveBatchName() {
            this.loading = true;

            this.$admin.http
                .patch(
                    `/api/auction/${this.auctionId}/batches/${this.batch.id}`,
                    {
                        name: this.batchName
                    }
                )
                .then(() => {
                    this.close();
                    this.$parent.$emit("batch-renamed");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.batchName = this.batch.name;
    }
};
</script>
