<template>
    <v-text-field
        v-bind="commonProps"
        :filled="filled"
        type="text"
        @change="change"
        @input="update"
    >
        <template v-slot:append>
            <div class="align-self-center flex mr-2">
                Alege culoarea:
            </div>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                nudge-bottom="105"
                nudge-left="16"
                top
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on" :style="swatchStyle" />
                </template>
                <v-card>
                    <v-card-text class="pa-0">
                        <v-color-picker flat mode="hexa" @input="updateColor" />
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Editable from "vuetify-admin/src/mixins/editable";

/**
 * Text editing for text value type via a basic text input.
 * Support textarea mode for long text via `multiline` prop.
 * Can be use for any date, datetime or time editing, use type set on `date`, `datetime-local` or `time`.
 * Render will depend of browser support.
 */
export default {
    mixins: [Input, Editable],
    data() {
        return {
            menu: false,
            mask: "!#XXXXXXXX"
        };
    },
    props: {
        /**
         * Text to be edited.
         * @model
         */
        value: {
            type: String,
            default: ""
        },
        /**
         * Use full filled background color style.
         */
        filled: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        swatchStyle() {
            return {
                backgroundColor: this.input ? this.input : "#ffffff",
                cursor: "pointer",
                height: "30px",
                width: "30px",
                borderRadius: this.menu ? "50%" : "4px",
                transition: "border-radius 200ms ease-in-out"
            };
        }
    },
    methods: {
        updateColor(value) {
            if (typeof value.hexa !== "undefined") {
                this.update(value.hexa);
            } else if (typeof value.hex !== "undefined") {
                this.update(value.hex);
            } else {
                this.update("");
            }
        }
    }
};
</script>
