var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{attrs:{"filters":_vm.filters,"include":[
            'category',
            'status',
            'interest',
            'customer',
            'deadlines',
            'users'
        ],"disable-export":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('deadline-type-select')]},proxy:true}])},[_c('mb-data-table',{staticClass:"overflow-auto",attrs:{"dense":false,"fields":_vm.fields,"item-class":_vm.itemRowBackground,"disable-select":""},scopedSlots:_vm._u([{key:"field.notifications",fn:function(ref){
        var item = ref.item;
return [(_vm.countByAuction[item.id] !== undefined)?_c('div',[_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.countByAuction[item.id])+" ")])],1):_vm._e()]}},{key:"field.estimated_value",fn:function(ref){
        var item = ref.item;
return [(item.currency === 'EUR')?[_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value))+" "+_vm._s(item.currency)+" ")])]:[_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value_euro))+" "+_vm._s(item.estimated_value_currency)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.estimated_value))+" "+_vm._s(item.currency)+" ")])]]}},{key:"field.deadlines",fn:function(ref){
        var item = ref.item;
return [(item.deadlines)?_c('div',[_c('deadline-list',{attrs:{"item":item}})],1):_vm._e()]}},{key:"field.users",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('responsible-list',{attrs:{"item":item,"users":item.users}})],1)]}},{key:"field.title",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mb-2"},[_c('router-link',{staticClass:"auction-title",attrs:{"to":item.id.toString()}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"mb-2"},[_vm._v(" Client: "),(item.customer)?[_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#555"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.customer.name))+" ")])]:[_vm._v("-")]],2),_c('div',[_c('a',{attrs:{"href":item.provider_link,"target":"_blank"}},[_c('span',{staticClass:"font-weight-bold pt-3 text-sm-body-2"},[_vm._v(" Cod licitatie: "+_vm._s(item.external_number)+" ")])])])]}},{key:"field.source",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"w",attrs:{"href":item.external_url,"link":!!item.external_url,"dark":"","target":"_blank"}},[_vm._v(" "+_vm._s(item.source)+" ")])]}},{key:"item.actions",fn:function(ref){
        var resource = ref.resource;
        var item = ref.item;
return [_c('deadline-select',{attrs:{"item":item}}),_c('toggle-featured-auction',{attrs:{"item":item}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }