<template>
    <responsible-list
        :users="item.watchers"
        :item="item"
        endpoint="api/user/watchers/task"
        payloadName="watchers"
    />
</template>

<script>
import ResponsibleList from "../resources/auctions/ResponsibleList";
export default {
    name: "taskWatchers",
    components: { ResponsibleList },
    props: {
        item: {
            required: true
        }
    }
};
</script>
