<template>
    <add-note-dialog
        :auction-id="auctionId"
        :batch-id="batchId"
        @note-added="$emit('note-added')"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" depressed v-bind="attrs" v-on="on">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Adauga notita
            </v-btn>
        </template>
    </add-note-dialog>
</template>

<script>
import AddNoteDialog from "@/components/resources/auctions/Notes/AddNoteDialog";

export default {
    name: "AddNoteButton",
    components: { AddNoteDialog },
    props: {
        auctionId: {},
        batchId: {}
    }
};
</script>
