<template>
    <div class="top_main">
        <div class="searchText">
            <v-text-field
                v-model="searchKey"
                v-click-outside="onClickOutside"
                :loading="loading"
                append-icon="mdi-menu-down"
                clearable
                color="white"
                dense
                height="37px"
                label="Cauta in toata aplicatia"
                outlined
                persistent-placeholder
                placeholder="Start typing to Search"
                solo-inverted
                @change="initialize"
                @input="initialize"
            />
        </div>

        <div v-if="items.length > 0" class="main_div_">
            <template solo-inverted>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    two-line
                    @click="changePage(item)"
                >
                    <v-list-item-avatar
                        class="text-white"
                        color="primary"
                        left
                        rounded
                        size="32"
                    >
                        <template v-if="item.internal_number">
                            {{ item.internal_number }}
                        </template>
                        <template v-else>
                            <v-icon color="#fff" size="sm">
                                fal fa-star-exclamation
                            </v-icon>
                        </template>
                    </v-list-item-avatar>
                    <v-list-item-content style="max-width: 500px">
                        <v-list-item-title style="color: #000!important;">
                            Licitatie
                            <strong>{{ item.status.name }}</strong> din
                            categoria <strong>{{ item.category.name }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle style="color: #999!important;">
                            <strong v-show="item.internal_number"> </strong>
                            {{ item.title }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template>
                <infinite-loading
                    spinner="spiral"
                    variant="primary"
                    @infinite="infiniteHandler"
                ></infinite-loading>
            </template>
        </div>
    </div>
</template>

<script>
import HasResourceSingleLink from "@/mixins/HasResourceSingleLink";
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: "MainSearch",
    components: {
        InfiniteLoading
    },
    mixins: [HasResourceSingleLink],
    data() {
        return {
            menuProps: {
                closeOnClick: true,
                closeOnContentClick: true
            },
            model: null,
            loading: false,
            items: [],
            search: null,
            page: 1,
            searchKey: ""
        };
    },
    methods: {
        onClickOutside() {
            this.items = [];
            this.searchKey = "";
            this.page = 1;
            this.loading = false;
        },
        changePage(item) {
            const route = this.linkObject(item);
            this.$router.replace(route, () => {
                this.$router.go();
            });
        },

        infiniteHandler(state) {
            this.$admin.http
                .get("api/general-search", {
                    params: {
                        query: this.searchKey,
                        page: this.page
                    }
                })
                .then(({ data }) => {
                    if (data.data.length) {
                        this.page += 1;
                        this.items = [...this.items, ...data.data];
                        state.loaded();
                    } else {
                        this.loading = false;
                        state.complete();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => (this.loading = false));
        },
        initialize(val) {
            // Items have already been requested
            if (this.loading) return;
            if (!val) {
                this.items = [];
                return;
            }

            this.loading = true;
            this.searchKey = val;

            // Lazily load input items
            this.$admin.http
                .get("api/general-search", {
                    params: {
                        query: val
                    }
                })
                .then(({ data }) => {
                    this.page = 1;
                    if (data.data.length) {
                        this.items = data.data;
                    } else {
                        this.loading = false;
                        this.items = [];
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => (this.loading = false));
        }
    },
    watch: {}
};
</script>

<style scoped>
.text-white {
    color: #fff !important;
}

.top_main {
    position: absolute;
    width: 40%;
    top: 4px;
}

.main_div_ {
    position: absolute;
    background: #fff;
    max-height: 380px;
    overflow-y: scroll;
    top: 40px;
    width: 100%;
    box-shadow: 0px 15px 15px 7px rgb(0 0 0 / 20%);
}

.searchText button {
    color: #000 !important;
}
</style>
