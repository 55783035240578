<template>
    <v-row class="mt-5">
        <v-col
            v-for="(option, index) in salesOptions"
            :key="option.id"
            cols="12"
            md="6"
        >
            <v-row
                :class="
                    option.locked ? 'price-analysis-sales-options-locked' : ''
                "
                class="price-analysis-sales-options mx-0 ml-0 pa-1 pb-4 mb-1"
            >
                <v-col class="pl-2" cols="12">
                    <v-row>
                        <v-col class="pb-0 pl-2">
                            <span class="font-weight-bold">
                                VARIANTA {{ index + 1 }}
                                <span class="primary--text"
                                    >{{ option.profit }}%</span
                                >
                                <div
                                    v-if="option.ron < productTotalRon"
                                    class="error--text font-weight-bold"
                                >
                                    Atenție! Prețul de vânzare este mai mic
                                    decât prețul de achiziție!
                                </div>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-xl-7">
                        <v-col class="pl-2" cols="12" xl="6">
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">RON</span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <template v-if="index === 0">
                                        <span class="price-input">
                                            {{
                                                Number(
                                                    option.ron
                                                ).toLocaleString("ro-RO", {
                                                    maximumFractionDigits: 2
                                                })
                                            }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        <v-text-field
                                            v-model="option.ron"
                                            :class="
                                                option.ron < productTotalRon
                                                    ? 'red-text'
                                                    : ''
                                            "
                                            :disabled="locked || index === 0"
                                            class="price-analysis-table__input"
                                            dense
                                            hide-details
                                            outlined
                                            placeholder="000.000,00"
                                            type="number"
                                            @change="
                                                updateOrCreateOption(option)
                                            "
                                        />
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">
                                        EURO BT la ZI
                                    </span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <span class="price-input">
                                        {{
                                            Number(
                                                option.euro_bt_day
                                            ).toLocaleString("ro-RO", {
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">
                                        EURO BT la data <br />{{ formatDate() }}
                                    </span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <span class="price-input">
                                        {{
                                            Number(
                                                option.euro_bt_date
                                            ).toLocaleString("ro-RO", {
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">
                                        EURO curs Licitatie
                                    </span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <span class="price-input">
                                        {{
                                            Number(
                                                option.estimated_value_euro
                                            ).toLocaleString("ro-RO", {
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-2" cols="12" xl="6">
                            <v-row align="center">
                                <v-col class="pb-0 pr-1" cols="12">
                                    <div
                                        class="text-body-2 font-weight-bold grey lighten-4 rounded"
                                        style="padding: 10px"
                                    >
                                        CASTIG
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">
                                        EURO BT la ZI
                                    </span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <v-row class="align-center">
                                        <v-col class="pr-1" cols="8">
                                            <span class="price-input">
                                                {{
                                                    Number(
                                                        option.euro_bt_day_winning
                                                    ).toLocaleString("ro-RO", {
                                                        maximumFractionDigits: 2
                                                    })
                                                }}
                                            </span>
                                        </v-col>
                                        <v-col class="pl-1">
                                            <span
                                                :class="
                                                    option.euro_bt_day_winning_percentage <=
                                                    0
                                                        ? 'error--text'
                                                        : 'success--text'
                                                "
                                                class="text-body-2"
                                            >
                                                {{
                                                    Number(
                                                        option.euro_bt_day_winning_percentage
                                                    ).toLocaleString("ro-RO", {
                                                        maximumFractionDigits: 2
                                                    })
                                                }}%
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6">
                                    <span class="text-body-2">
                                        EURO BT la data <br />{{ formatDate() }}
                                    </span>
                                </v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <v-row class="align-center">
                                        <v-col class="pr-1" cols="8">
                                            <span class="price-input">
                                                {{
                                                    Number(
                                                        option.euro_bt_date_winning
                                                    ).toLocaleString("ro-RO", {
                                                        maximumFractionDigits: 2
                                                    })
                                                }}
                                            </span>
                                        </v-col>
                                        <v-col class="pl-1">
                                            <span
                                                :class="
                                                    option.euro_bt_date_winning_percentage <=
                                                    0
                                                        ? 'error--text'
                                                        : 'success--text'
                                                "
                                                class="text-body-2"
                                            >
                                                {{
                                                    Number(
                                                        option.euro_bt_date_winning_percentage
                                                    ).toLocaleString("ro-RO", {
                                                        maximumFractionDigits: 2
                                                    })
                                                }}%
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col class="pb-0" cols="6"></v-col>
                                <v-col class="pr-1 pb-0" cols="6">
                                    <v-btn
                                        :disabled="
                                            locked ||
                                                option.ron < productTotalRon
                                        "
                                        color="success"
                                        style="width: 100%"
                                        @click="lockOptions(option)"
                                    >
                                        <v-icon dark left>
                                            fal fa-check-square
                                        </v-icon>
                                        <span
                                            class="text-body-2 text-capitalize"
                                            >Alege varianta</span
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="d-flex justify-center justify-md-start" cols="12" md="1">
            <v-btn
                :disabled="locked"
                color="success"
                dark
                icon
                style="height: 100%"
                x-large
                @click="addOption"
            >
                <v-icon style="width: 55px; height: 55px;">
                    fal fa-plus-square
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import dateFormat from "dateformat";
import OfferAnalysis from "../../../events/OfferAnalysis";
import { mapGetters } from "vuex";

export default {
    name: "PriceAnalysisSalesOptions",

    props: {
        batch: {
            type: Object,
            required: true,
            default: () => {}
        },
        item: {
            type: Object,
            required: true,
            default: () => {}
        }
    },

    data() {
        return {
            locked: false,
            salesOptions: []
        };
    },

    computed: {
        ...mapGetters({
            priceAnalysis: "price_analysis/price_analysis",
            acquisitionRate: "price_analysis/acquisition_rate",
            productTotal: "price_analysis/product_total",
            currencies: "price_analysis/currencies"
        }),
        totalPenaltyPercentage: {
            get() {
                return this.priceAnalysis.penalty &&
                    this.priceAnalysis.penalty.active
                    ? this.priceAnalysis.penalty.total_percentage
                    : 0;
            }
        },
        eurDateCurrency: {
            get() {
                return this.acquisitionRate ? this.acquisitionRate.rate : 0;
            }
        },
        eurBTDailyCurrency: {
            get() {
                return this.currencies.BT !== undefined
                    ? this.currencies.BT.rate
                    : 0;
            }
        },
        estimatedValue: {
            get() {
                return this.currencies.BT !== undefined
                    ? Number(
                          this.productTotal * this.currencies.BT.rate -
                              this.productTotal *
                                  this.currencies.BT.rate *
                                  (this.totalPenaltyPercentage / 100)
                      ).toFixed(2)
                    : 0;
            }
        },
        productTotalRon() {
            return this.productTotal * this.eurBTDailyCurrency;
        }
    },

    created() {
        this.loadOptions(this.batch.id);
    },

    mounted() {
        OfferAnalysis.$on("reloadOptions", () => {
            this.loadOptions(this.batch.id, true);
        });
    },

    methods: {
        loadOptions(id, reset = false) {
            this.$admin.http
                .get(`/api/price-analysis/sale-options/${id}`)
                .then(({ data }) => {
                    if (reset) {
                        this.salesOptions = [];
                    }

                    data.data.forEach(element =>
                        this.salesOptions.push(element)
                    );
                    data.data.forEach(
                        element => (this.locked = !!element.locked)
                    );
                });
        },

        updateOrCreateOption(item) {
            if (item.new) {
                this.$admin.http
                    .post(`/api/price-analysis/sale-options`, {
                        price_analysis_id: this.priceAnalysis.id,
                        ron: item.ron,
                        locked: item.locked
                    })
                    .then(({ data }) => {
                        item.id = data.data.id;
                        item.new = false;
                        this.calculateOption(item);
                    });
            } else {
                this.$admin.http
                    .patch(
                        `/api/price-analysis/sale-options/update/${item.id}`,
                        {
                            ron: item.ron
                        }
                    )
                    .then(() => {
                        this.calculateOption(item);
                    });
            }
        },

        calculateOption(item) {
            const penaltyRON = this.addPenalty(item.ron);

            item.euro_bt_date =
                this.eurDateCurrency !== 0
                    ? (item.ron / this.eurDateCurrency).toFixed(2)
                    : 0;

            item.euro_bt_day = (item.ron / this.eurBTDailyCurrency).toFixed(2);

            const currentValueEurBtToday = item.ron / this.eurBTDailyCurrency;
            const penaltyEur = penaltyRON / this.eurBTDailyCurrency;
            const achizitii = this.productTotal;
            item.euro_bt_day_winning = (
                currentValueEurBtToday -
                penaltyEur -
                achizitii
            ).toFixed(2);

            if (this.eurDateCurrency !== 0) {
                const currentValueEurBtOnDate = item.ron / this.eurDateCurrency;
                item.euro_bt_date_winning = (
                    currentValueEurBtOnDate -
                    penaltyEur -
                    achizitii
                ).toFixed(2);
            } else {
                item.euro_bt_date_winning = 0;
            }

            item.euro_bt_day_winning_percentage = this.dayWinPercentage(
                item,
                this.eurBTDailyCurrency
            );

            item.euro_bt_date_winning_percentage = this.dateWinPercentage(
                item,
                this.eurDateCurrency
            );

            item.estimated_value_euro = (
                item.ron / this.item.bnr_currency_value
            ).toFixed(2);

            item.profit = this.getProfit(item);
        },

        addPenalty(value) {
            return this.totalPenaltyPercentage > 0
                ? value - value * (this.totalPenaltyPercentage / 100)
                : 0;
        },

        getProfit(item) {
            let profit = 0;

            profit = (item.ron / this.item.estimated_value) * 100;

            return profit.toFixed(2);
        },

        dayWinPercentage(item, eurBTDailyCurrency) {
            const auctionBTEuroDaily = (
                this.item.estimated_value / eurBTDailyCurrency
            ).toFixed(2);

            return (
                (item.euro_bt_day_winning / auctionBTEuroDaily) *
                100
            ).toFixed(2);
        },

        dateWinPercentage(item, eurDateCurrency) {
            if (eurDateCurrency !== 0) {
                const auctionBTEuroDate = (
                    this.item.estimated_value / eurDateCurrency
                ).toFixed(2);

                return (
                    (item.euro_bt_date_winning / auctionBTEuroDate) *
                    100
                ).toFixed(2);
            } else {
                return 0;
            }
        },

        lockOptions(item) {
            this.$admin.http
                .patch(`/api/price-analysis/sale-options/lock/${item.id}`, {
                    locked: true,
                    price_analysis_id: this.priceAnalysis.id
                })
                .then(() => {
                    this.locked = true;
                    item.locked = true;

                    this.loadOptions(this.batch.id, true);

                    this.$store.dispatch(
                        "price_analysis/lookupLockedSaleOption",
                        { vm: this, batch_id: this.batch.id }
                    );
                });
        },

        formatDate() {
            return this.acquisitionRate
                ? dateFormat(this.acquisitionRate.created_at, "dd-mm-yyyy")
                : "";
        },

        addOption() {
            this.salesOptions.push({
                id: this.salesOptions.length + 1,
                ron: 0,
                euro_bt_day: 0,
                euro_bt_day_winning: 0,
                euro_bt_day_winning_percentage: 0,
                euro_bt_date: 0,
                euro_bt_date_winning: 0,
                euro_bt_date_winning_percentage: 0,
                estimated_value_euro: 0,
                profit: 0,
                locked: false,
                new: true
            });

            return true;
        }
    }
};
</script>

<style scoped>
.price-analysis-sales-options {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
}

.price-analysis-sales-options-locked {
    border: 3px solid #31c900;
    border-radius: 5px;
}
</style>
