<template>
    <v-dialog
        v-model="dialog"
        max-width="850"
        persistent
        transition="dialog-top-transition"
        scrollable
    >
        <template v-slot:activator="{ on, attrs }" v-if="showActivator">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :icon="!includeLabel"
                color="primary"
                dark
            >
                <v-icon>mdi-clock-outline</v-icon>
                <span v-if="includeLabel" class="ml-2">
                    Alege Termenele
                </span>
            </v-btn>
        </template>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-toolbar-title>Seteaza deadline</v-toolbar-title>
                <v-spacer />
                <v-btn dark icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row align="center">
                    <v-col class="d-flex" cols="12" sm="8">
                        <v-select
                            v-model="currentDeadline"
                            :items="allDeadlines"
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Alege deadline"
                            outlined
                            return-object
                        ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-btn
                            :disabled="!Object.keys(currentDeadline).length"
                            color="success"
                            depressed
                            width="100%"
                            @click="addDeadline"
                        >
                            Adauga
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-5" />

                <v-row
                    v-for="(deadline, index) in selectedDeadlines"
                    :key="index"
                >
                    <v-col>
                        {{ deadline.name }}
                    </v-col>
                    <v-col cols="4">
                        <template v-if="deadline.is_fix_date">
                            <VaDateInput
                                v-if="deadline.editable"
                                v-model="deadline.deadline_at"
                                :hide-details="true"
                            />
                        </template>
                        <template v-else>
                            <v-text-field
                                v-model="deadline.days"
                                :disabled="!deadline.editable"
                                dense
                                hide-details
                                outlined
                            />
                        </template>
                    </v-col>
                    <v-col cols="1">
                        <v-btn
                            v-if="deadline.deletable"
                            class="mx-2"
                            color="pink"
                            dark
                            icon
                            @click="removeDeadline(index)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />

            <v-card-actions class="justify-end">
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="success"
                    @click="saveDeadlines"
                    >Salveaza
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "deadlineSelect",
    data() {
        return {
            loading: false,
            dialog: false,
            allDeadlines: [],
            selectedDeadlines: [],
            currentDeadline: {}
        };
    },
    inject: {
        listState: { default: undefined }
    },
    props: {
        item: {
            required: true
        },
        includeLabel: {
            type: Boolean,
            default: false
        },
        showActivator: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        loadDeadlinesList() {
            this.$admin.http.get("/api/deadlines-schema").then(({ data }) => {
                this.allDeadlines = data.data;
            });
        },
        closeDialog() {
            this.dialog = false;
        },
        addDeadline() {
            this.selectedDeadlines.push({
                name: this.currentDeadline.name,
                id: this.currentDeadline.id,
                days: this.currentDeadline.number_of_days,
                is_fix_date: this.currentDeadline.is_fix_date,
                deadline_at: "",
                deletable: true,
                editable: true
            });
            this.currentDeadline = {};
        },
        removeDeadline(index) {
            this.selectedDeadlines.splice(index, 1);
        },
        saveDeadlines: function() {
            this.loading = true;
            this.$admin.http
                .patch(`/api/auction-deadlines/${this.item.id}`, {
                    deadlines: this.selectedDeadlines
                })
                .then(() => {
                    if (this.listState) {
                        this.listState.reload();
                    } else {
                        this.$store.dispatch(
                            "api/refresh",
                            this.$route.meta.resource
                        );
                    }
                    this.loading = false;
                    this.closeDialog();
                });
        }
    },
    created() {
        this.loadDeadlinesList();
        const itemDeadlines = this.item.deadlines.map(item => {
            return {
                name: item.name,
                id: item.source_schema_id,
                days: item.relative_number_of_days,
                is_fix_date: item.is_fix_date,
                deadline_at: item.deadline_at,
                deletable: false,
                editable: false
            };
        });
        this.selectedDeadlines.push(...itemDeadlines);
    }
};
</script>
