<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <span v-on:dblclick="renameBatch()" v-bind="attrs" v-on="on">{{
                    batch.name
                }}</span>
            </template>
            <span>Dublu click pentru a edita numele lotului</span>
        </v-tooltip>

        <rename-batch
            :dialog.sync="dialog"
            :batch="batch"
            :auction-id="auctionId"
        />
    </div>
</template>

<script>
import RenameBatch from "./RenameBatch";
export default {
    name: "BatchTabItem",
    components: { RenameBatch },
    props: {
        batch: {
            required: true
        },
        auctionId: {
            required: true
        }
    },
    data() {
        return {
            dialog: false
        };
    },
    methods: {
        renameBatch() {
            this.dialog = true;
        }
    }
};
</script>
